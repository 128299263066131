// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-importform {
  margin-top: auto;
  margin-bottom: auto;
}
.thesudokuapp-customsudoku {
  width: 100%;
  font-family: monospace;
}`, "",{"version":3,"sources":["webpack://./App/components/ImportSudoku.vue"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,mBAAA;AAAR;AAGI;EACI,WAAA;EACA,sBAAA;AAAR","sourcesContent":["\n    .thesudokuapp-importform {\n        margin-top: auto;\n        margin-bottom: auto;\n    }\n\n    .thesudokuapp-customsudoku {\n        width: 100%;\n        font-family: monospace;\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
