<template>
    <transition name="fade-in-right">
        <div v-show="uiStore.components.overflowmenu" class="thesudokuapp-overflowmenu" @click.stop="uiStore.switchUIState({type: 'overflowmenu', newState: false})">
            <button v-if="gameplaySettingsStore.showStopwatch" class="thesudokuapp-overflowmenu-button" :disabled="uiStore.gameover" @click="pauseStopwatch">{{ $t('action.pause') }}</button>
            <button class="thesudokuapp-overflowmenu-button" @click.stop="uiStore.switchUIState({type: 'newgamemenu', newState: true})">{{ $t('action.newpuzzle') }}</button>
            <button class="thesudokuapp-overflowmenu-button thesudokuapp-overflowmenu-button-gapY" @click="resetBoard">{{ $t('action.restart') }}</button>
            <button class="thesudokuapp-overflowmenu-button" :disabled="uiStore.gameover" @click="autofill">{{ $t('action.autofill') }}</button>
            <button class="thesudokuapp-overflowmenu-button" :disabled="uiStore.gameover" @click="smartHint">{{ $t('action.smarthint') }}</button>
            <button class="thesudokuapp-overflowmenu-button thesudokuapp-overflowmenu-button-gapY" :disabled="uiStore.gameover" @click="simpleHint">{{ $t('action.simplehint') }}</button>
            <button class="thesudokuapp-overflowmenu-button thesudokuapp-overflowmenu-button-numpad" @click="() => gameplaySettingsStore.toggleSecondNumpad({ overflow: true })">
                {{ $t('settings.showSecondNumpad') }}
                <span class="thesudokuapp-overflowmenu-button-indicator" :class="{ 'indicator-on': gameplaySettingsStore.showSecondNumpad }">{{ gameplaySettingsStore.showSecondNumpad ? $t('misc.on') : $t('misc.off') }}</span>
            </button>
            <button class="thesudokuapp-overflowmenu-button thesudokuapp-overflowmenu-button-numpad" @click="() => gameplaySettingsStore.toggleNumberFirst({ overflow: true })">
                {{ $t('settings.numberFirst') }}
                <span class="thesudokuapp-overflowmenu-button-indicator" :class="{ 'indicator-on': gameplaySettingsStore.numberFirst }">{{ gameplaySettingsStore.numberFirst ? $t('misc.on') : $t('misc.off') }}</span>
            </button>
            <button v-if="!uiStore.soundDisabled" class="thesudokuapp-overflowmenu-button" @click="() => gameplaySettingsStore.toggleSound({ overflow: true })">
                {{ $t('settings.sound') }}
                <span class="thesudokuapp-overflowmenu-button-indicator" :class="{ 'indicator-on': gameplaySettingsStore.sound }">{{ gameplaySettingsStore.sound ? $t('misc.on') : $t('misc.off') }}</span>
            </button>
            <button class="thesudokuapp-overflowmenu-button" @click="() => uiStore.switchTheme({ overflow: true })">
                {{ $t('settings.darkMode') }}
                <span class="thesudokuapp-overflowmenu-button-indicator" :class="{ 'indicator-on': uiStore.darkMode }">{{ uiStore.darkMode ? $t('misc.on') : $t('misc.off') }}</span>
            </button>
            <button class="thesudokuapp-overflowmenu-button" @click.stop="uiStore.switchUIState({type: 'settings', newState: true})">{{ $t('action.allsettings') }}</button>
        </div>
    </transition>
</template>

<script>
    import { stopwatchStore, gameplaySettingsStore, uiStore, snackbarStore, sudokuStore } from '@/App/stores';
    import { autofill, smartHint, simpleHint } from '@/services/thesudokuapp-hints';
    import { playSound } from '@/services/thesudokuapp-sound';
    import { utils } from '@/services/thesudokuapp-utils';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { logger } from '@/services/thesudokuapp-logger';

    export default {
        computed: {
            gameplaySettingsStore() {
                return gameplaySettingsStore;
            },
            uiStore() {
                return uiStore;
            },
            gridIsChanged() {
                const hasSelectedValue = Object.values(sudokuStore.selectedValues).some(Boolean);

                if (hasSelectedValue) {
                    return true;
                }

                return Object.values(sudokuStore.selectedHints).some(hints => hints.some(Boolean));
            }
        },
        methods: {
            $t,
            pauseStopwatch: stopwatchStore.pauseStopwatch,
            resetBoard() {
                if (uiStore.gameover || !this.gridIsChanged) {
                    sudokuStore.initSudoku({
                        grid: sudokuStore.grid.grid,
                        hashid: sudokuStore.grid.hashid,
                        difficulty: sudokuStore.grid.difficulty,
                        solution: sudokuStore.grid.solution
                    });

                    logger.trackEvent({ category: 'interaction', action: 'board reset ' + (uiStore.gameover ? 'after gameover' : 'for fun') });

                    if (uiStore.gameover) {
                        uiStore.gameover = false;

                        if (gameplaySettingsStore.sound) {
                            void playSound('newgame');
                        }

                        if (!this.sudokuboot) {
                            utils.updateLocationAndTitle({
                                gridid: sudokuStore.grid.hashid,
                                difficulty: sudokuStore.grid.difficulty
                            });
                        }
                    }
                } else {
                    snackbarStore.showSnackbar({
                        primaryMessage: $t('snackbar.restartwarning'),
                        actionCaption: $t('action.restart'),
                        dismissCaption: $t('snackbar.action.cancel'),
                        actionCallback: () => {
                            sudokuStore.initSudoku({
                                grid: sudokuStore.grid.grid,
                                hashid: sudokuStore.grid.hashid,
                                difficulty: sudokuStore.grid.difficulty,
                                solution: sudokuStore.grid.solution
                            });

                            logger.trackEvent({ category: 'interaction', action: 'board reset'});

                            if (gameplaySettingsStore.sound) {
                                void playSound('newgame');
                            }
                        }
                    });
                }
            },
            autofill() {
                snackbarStore.showSnackbar({
                    notificationId: 'autofill',
                    primaryMessage: $t('snackbar.autofill.warning'),
                    secondaryMessage: [
                        $t('snackbar.hint.hintwarning'),
                        $t('snackbar.autofill.overwritten') ],
                    actionCaption: $t('snackbar.autofill.action'),
                    actionCallback: autofill,
                    dismissCaption: $t('snackbar.action.cancel'),
                    showDontshow: true
                });
            },
            smartHint() {
                snackbarStore.showSnackbar({
                    notificationId: 'smarthint',
                    primaryMessage: $t('snackbar.hint.smarthintwarning'),
                    secondaryMessage: $t('snackbar.hint.hintwarning'),
                    actionCaption: $t('snackbar.hint.action'),
                    actionCallback: smartHint,
                    dismissCaption: $t('snackbar.action.cancel'),
                    showDontshow: true,
                    preventHideOnAction: true
                });
            },
            simpleHint() {
                snackbarStore.showSnackbar({
                    notificationId: 'simplehint',
                    primaryMessage: $t('snackbar.hint.simplehint.warning'),
                    secondaryMessage: $t('snackbar.hint.hintwarning'),
                    actionCaption: $t('snackbar.hint.action'),
                    actionCallback: simpleHint,
                    dismissCaption: $t('snackbar.action.cancel'),
                    showDontshow: true,
                    preventHideOnAction: true
                });
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-overflowmenu {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0.2rem;
        top: 0;
        z-index: 7;
        font-size: 0.495rem;
        will-change: opacity;

        background-color: var(--accent-color-transparent-dark);
        color: var(--dark-background-font-color);
        box-shadow: 1px 2px 7px 0 var(--accent-color-transparent-dark);
        border-radius: var(--border-radius);
        padding: 0.4rem 0.6rem;
        align-items: flex-start;
        cursor: initial;

        .thesudokuapp-overflowmenu-button {
            align-items: flex-start;
            margin-bottom: 0.4rem;
            white-space: nowrap;

            &::first-letter {
                text-transform: capitalize;
            }

            &:disabled {
                opacity: 0.5;
            }

            &:active:not(:disabled) {
                color: var(--accent-color-lighter);
            }

            &:last-child {
                margin-bottom: 0;
            }

            .thesudokuapp-overflowmenu-button-indicator {
                display: inline-block;
                vertical-align: top;
                font-size: 0.6em;
                min-width: 3em;
                padding-left: 0.6em;
                padding-right: 0.6em;
                border-radius: 1em;
                color: var(--accent-color-transparent-dark);
                background-color: var(--dark-background-font-color);
                text-align: center;
                font-weight: bold;

                &.indicator-on {
                    background-color: var(--success-color);
                    color: var(--dark-background-font-color);
                }
            }

            &:active:not(:disabled) .thesudokuapp-overflowmenu-button-indicator {
                background-color: var(--accent-color-lighter);
            }

            &.thesudokuapp-overflowmenu-button-gapY {
                margin-bottom: 1rem;
            }
        }

        /*.thesudokuapp-overflowmenu-button-autofill {
            margin-top: 0.6rem;
            margin-bottom: 1rem;
        }*/

        .thesudokuapp-overflowmenu-button-numpad {
            white-space: nowrap;
        }
    }
</style>
