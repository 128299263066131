<template>
    <svg class="svgbutton-svg" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${icons[icon].width} ${icons[icon].height}`">
        <path fill="currentColor" :d="icons[icon].path" />
    </svg>
</template>

<script>
    import { icons } from '@/services/thesudokuapp-icons';

    export default {
        props: {
            icon: {
                type: String,
                required: true
            }
        },
        computed: {
            icons() {
                return icons;
            }
        }
    };
</script>
