<template>
    <div class="thesudokuapp-toggle-container" :class="{'thesudokuapp-toggle-disabled': disabled }">
        <div class="thesudokuapp-toggle-label-container">
            <p class="thesudokuapp-toggle-label" @click.stop="toggle">
                <slot />
            </p>
        </div>
        <label class="thesudokuapp-toggle" @click.stop.prevent="toggle">
            <input class="thesudokuapp-toggle-checkbox" type="checkbox" :checked="value">
            <span class="thesudokuapp-toggle-theme" />
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            state: {
                type: Boolean,
                required: true
            },
            action: {
                type: Function,
                required: true
            }
        },
        computed: {
            value() {
                return this.state;
            }
        },
        methods: {
            toggle() {
                if (this.disabled) {
                    return;
                }

                this.action();
            }
        }
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-scssvars' as *;

    .thesudokuapp-toggle-container {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 0.46rem;
        user-select: none;

        &.thesudokuapp-toggle-disabled {
            filter: brightness(75%);
            opacity: 0.5;
            cursor: not-allowed;
        }

        .thesudokuapp-toggle-label-container {
            text-align: right;
            width: 57%;

            .thesudokuapp-toggle-label {
                display: inline-block;
                margin: 0;
                padding-right: 0.5rem;
                cursor: pointer;
            }
        }
    }

    .thesudokuapp-toggle {
        cursor: pointer;
        display: block;
        position: relative;
        height: var(--toggle-size);
        border-radius: 1rem;
        width: calc(3 * var(--toggle-size));
        overflow: hidden;

        .thesudokuapp-toggle-theme {
            &::before {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                content: '';
                background-color: var(--dark-background-font-color);
                transition: background-color 150ms ease-out;
            }

            &::after {
                display: block;
                left: 0;
                top: 0;
                content: '';
                position: absolute;
                height: var(--toggle-size);
                width: var(--toggle-size);
                background-color: var(--accent-color);
                border-radius: 100%;
                padding: var(--border-width);
                background-clip: content-box;
                transition: transform 150ms ease-out, background-color 150ms ease-out;
                box-shadow: 0 0 20px 0 rgba(15, 15, 15, 0.7);
            }
        }


        .thesudokuapp-toggle-checkbox:checked + .thesudokuapp-toggle-theme::before {
            background-color: var(--success-color);
        }

        .thesudokuapp-toggle-checkbox:checked + .thesudokuapp-toggle-theme::after {
            background-color: var(--dark-background-font-color);
            transform: translateX(200%);
        }
    }

    @media #{$thesudokuapp-portrait} {
        .thesudokuapp-toggle-container {
            font-size: 0.4rem;
        }
    }
</style>
