<template>
    <div class="thesudokuapp-ocr">
        <app-arrow-close-button label="Close OCR" @closed="close" />
        <!-- ебучий playsinline нужен только для ебучего safari ios -->
        <video id="thesudokuapp-ocr-video" ref="videoRef" src="" playsinline height="0" width="0" />
        <section id="thesudokuapp-ocr-canvas-container">
            <canvas id="thesudokuapp-ocr-canvas" ref="canvasRef" />
        </section>
        <transition name="snackbar">
            <section v-if="!snackbar && !(loadingOpenCV || waiting)" class="thesudokuapp-ocr-bottom-container">
                <button class="svgbutton" title="Shutter button" :disabled="!streamStarted" @click="capture">
                    <svg xmlns="http://www.w3.org/2000/svg" class="svgbutton-svg" viewBox="0 0 132 132">
                        <circle fill="rgb(255, 255, 255)" cx="66" cy="66" r="66" />
                        <circle fill="currentColor" cx="66" cy="66" r="54" />
                        <circle fill="rgb(255, 255, 255)" cx="66" cy="66" r="50" />
                    </svg>
                </button>
            </section>
        </transition>
        <transition name="snackbar">
            <section v-if="!snackbar && (loadingOpenCV || waiting)" class="thesudokuapp-ocr-bottom-container">
                <app-svg-spinner :reducedmessage="$t('svgspinnger.loading')" />
            </section>
        </transition>
    </div>
</template>

<script>
    import { playSound } from '@/services/thesudokuapp-sound';
    import ArrowCloseButton from '@/App/components/ArrowCloseButton.vue';
    import SvgSpinner from '@/App/components/SvgSpinner.vue';
    import { gameplaySettingsStore, uiStore, snackbarStore, miscStore, sudokuStore } from '@/App/stores';
    import { api } from '@/services/thesudokuapp-api';
    import { logger } from '@/services/thesudokuapp-logger';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { startOcrEngine, streamStarted, loadingOpenCV } from '@/services/thesudokuapp-ocr';

    let stopMediaStream, startMediaStream, clearMediaStream, transformPerspective, getOcrData;

    export default {
        components: {
            appArrowCloseButton: ArrowCloseButton,
            appSvgSpinner: SvgSpinner
        },
        data() {
            return {
                loadingOpenCV,
                streamStarted
            };
        },
        computed: {
            snackbar() { return uiStore.snackbar; },
            waiting() { return uiStore.waiting; }
        },
        async mounted() {
            const browserOk = await this.checkBrowser();

            if (!browserOk) {
                return;
            }

            if (gameplaySettingsStore.sound) {
                await playSound('camerashutter', true);
            }

            const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                notificationId: 'ocrhowto',
                primaryMessage: $t('snackbar.ocrhowto.primary'),
                secondaryMessage: $t('snackbar.ocrhowto.secondary'),
                showDontshow: true,
                dismissCaption: $t('snackbar.action.proceed')
            });

            if (snackbarResolved !== 'dismiss' && snackbarResolved !== 'action') {
                uiStore.switchUIState();
                return;
            }

            uiStore.toggleWaiting();

            const {
                streamOk,
                error,
                stopMediaStream: stopMediaStreamEngine,
                startMediaStream: startMediaStreamEngine,
                transformPerspective: transformPerspectiveEngine,
                clearMediaStream: clearMediaStreamEngine,
                getOcrData: getOcrDataEngine,
                streamStarted
            } = await startOcrEngine({
                video: this.$refs.videoRef,
                canvas: this.$refs.canvasRef,
                logger
            });

            this.streamStarted = streamStarted;
            uiStore.toggleWaiting();

            if (streamOk === false) {
                logger.trackEvent({category: 'exception:ocr', action: error?.toString()});

                const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                    primaryMessagex: $t('snackbar.ocrnoaccess.primary'),
                    secondaryMessage: $t('snackbar.ocrnoaccess.secondary'),
                    actionCaption: $t('action.feedback'),
                });

                if (snackbarResolved === 'action') {
                    miscStore.feedbackTemplate = $t('snackbar.ocrnoaccess.message');
                    uiStore.switchUIState({type: 'feedback', newState: true});
                } else {
                    uiStore.switchUIState();
                }

                return;
            }

            stopMediaStream = stopMediaStreamEngine;
            startMediaStream = startMediaStreamEngine;
            clearMediaStream = clearMediaStreamEngine;
            transformPerspective = transformPerspectiveEngine;
            getOcrData = getOcrDataEngine;
        },
        unmounted() {
            if (clearMediaStream) {
                clearMediaStream();
            }
        },
        methods: {
            $t,
            close() {
                uiStore.switchUIState({ type: 'ocr', newState: false });
            },
            async checkBrowser() {
                if (!navigator.mediaDevices || !HTMLVideoElement.prototype.requestVideoFrameCallback || !window.OffscreenCanvas) {
                    logger.trackEvent({ category: 'exception:ocr', action: 'unsupported browser'});

                    const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                        primaryMessage: $t('snackbar.ocrbrowser.primary'),
                        secondaryMessage: $t('snackbar.ocrbrowser.secondary'),
                        actionCaption: $t('action.feedback')
                    });

                    if (snackbarResolved === 'action') {
                        miscStore.feedbackTemplate = $t('snackbar.ocrbrowser.message');
                        uiStore.switchUIState({ type: 'feedback', newState: true });
                    } else {
                        uiStore.switchUIState();
                    }

                    return false;
                }

                return true;
            },
            async capture() {
                if (gameplaySettingsStore.sound) {
                    void playSound('camerashutter');
                }

                stopMediaStream();
                transformPerspective();

                const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                    primaryMessage: $t('snackbar.ocrcaptured.primary'),
                    actionCaption: $t('snackbar.action.submit'),
                    secondaryActionCaption: $t('snackbar.action.tryagain'),
                });

                if (snackbarResolved === 'action') {
                    const { gridParams, image, gridImage } = getOcrData();

                    void this.sendToServer({
                        gridParams,
                        gridImage,
                        image
                    });
                } else if (snackbarResolved === 'secondaryAction') {
                    void startMediaStream();
                } else {
                    uiStore.switchUIState();
                }
            },
            async sendToServer({ gridParams, gridImage, image }) {
                try {
                    uiStore.toggleWaiting();

                    const grid = await api.ocrgrid({
                        gridParams,
                        gridImage,
                        image
                    });

                    uiStore.toggleWaiting();

                    logger.trackEvent({
                        category: 'ocr',
                        action: 'importsudoku',
                        eventName: grid.HashID
                    });

                    sudokuStore.newGame({
                        grid: {
                            hashid: grid.HashID,
                            difficulty: grid.Difficulty,
                            grid: grid.Grid,
                            solution: grid.Solution
                        },
                        type: 'ocred'
                    });

                    uiStore.switchUIState();
                } catch (error) {
                    uiStore.toggleWaiting();

                    if (!error.json?.primary || !error.json?.secondary || (!error.json?.grid && !error.json?.message)) {
                        const snackbarResolved = await api.apiAsyncCatch({
                            error,
                            source: 'import_serverexception'
                        });

                        if (snackbarResolved === 'action') {
                            void this.sendToServer({ gridParams, gridImage, image });
                        } else {
                            uiStore.switchUIState();
                        }

                        return;
                    }

                    const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                        primaryMessage: error.json.primary,
                        secondaryMessage: error.json.secondary,
                        actionCaption: error.json?.grid ? $t('snackbar.action.playanyway') : $t('action.feedback'),
                        secondaryActionCaption: $t('snackbar.action.tryagain')
                    });

                    if (snackbarResolved === 'action') {
                        if (error.json?.grid) {
                            sudokuStore.newGame({
                                grid: {
                                    hashid: error.json.grid.HashID,
                                    difficulty: error.json.grid.Difficulty,
                                    grid: error.json.grid.Grid,
                                    solution: error.json.grid.Solution
                                },
                                type: 'ocred-anyway'
                            });

                            uiStore.switchUIState();
                        } else {
                            miscStore.feedbackTemplate = error.json.message;
                            uiStore.switchUIState({ type: 'feedback', newState: true });
                        }
                    } else if (snackbarResolved === 'secondaryAction') {
                        void startMediaStream();
                    } else {
                        uiStore.switchUIState();
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-container-inner-content.thesudokuapp-ocr {
        font-size: calc(var(--board-size)/128);
    }

    .thesudokuapp-ocr {
        overflow: hidden;

        .svgbutton {
            width: 2rem;
            height: 2rem;
        }

        .svgbutton-svg {
            width: 100%;
            height: 100%;
        }

        .thesudokuapp-svgspinner-container {
            width: 2rem;
            height: 2rem;

            .thesudokuappp-svgspinner {
                width: 100%;
                height: 100%;
            }
        }

        --squareSize: 2em;
        --squareColor: var(--accent-color-transparent-dark);

        background-image:
            linear-gradient(45deg, var(--squareColor) 25%, transparent 25%),
            linear-gradient(135deg, var(--squareColor) 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, var(--squareColor) 75%),
            linear-gradient(135deg, transparent 75%, var(--squareColor) 75%);
        background-size: calc(2 * var(--squareSize)) calc(2 * var(--squareSize));
        background-position: 0 0, var(--squareSize) 0, var(--squareSize) calc(-1 * var(--squareSize)), 0 calc(-1 * var(--squareSize));
    }

    #thesudokuapp-ocr-canvas {
        width: 100%;
        height: auto;
        padding-top: 1.1rem;
    }

    #thesudokuapp-ocr-canvas.portrait {
        height: 100%;
        width: auto;
        padding-top: unset;
    }

    #thesudokuapp-ocr-canvas-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .thesudokuapp-ocr-bottom-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--accent-color-transparent-dark);
        background-color: var(--accent-color-transparent-dark);

        &.snackbar-enter {
            opacity: 1;
            transform: translateY(4rem);
        }

        &.snackbar-enter-active {
            transition: transform 250ms ease-out, opacity 250ms ease-out;
        }

        &.snackbar-enter-to {
            opacity: 1;
            transform: translateY(0);
        }

        &.snackbar-leave-active {
            transition: transform 150ms ease-out, opacity 150ms ease-out;
        }

        &.snackbar-leave-to {
            opacity: 1;
            transform: translateY(4rem);
        }
    }
</style>
