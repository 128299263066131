// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stopwatch-container {
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: auto;
}
.stopwatch-container:disabled {
  opacity: 0.5;
}
.stopwatch-container .svgbutton-play {
  font-size: 0.6rem;
  margin-top: auto;
  margin-bottom: auto;
}
.stopwatch-container .svgbutton-pause {
  font-size: 0.68rem;
  margin-top: auto;
  margin-bottom: auto;
}
.stopwatch-container .stopwatch-svg {
  width: 1.1em;
  height: 1.1em;
  margin-right: 0.3em;
  margin-top: 0.1em;
}
.stopwatch-timer {
  display: flex;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./App/components/Stopwatch.vue"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAGQ;EACI,iBAAA;EAGA,gBAAA;EACA,mBAAA;AAHZ;AAMQ;EACI,kBAAA;EAGA,gBAAA;EACA,mBAAA;AANZ;AASQ;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AAPZ;AAaI;EACI,aAAA;EACA,kBAAA;AAVR","sourcesContent":["\n    .stopwatch-container {\n        display: flex;\n        align-items: center;\n        align-self: center;\n        margin-right: auto;\n\n        &:disabled {\n            opacity: 0.5;\n        }\n\n        .svgbutton-play {\n            font-size: 0.6rem;\n            // Safari 9 bug\n            // https://github.com/jgthms/bulma/issues/727\n            margin-top: auto;\n            margin-bottom: auto;\n        }\n\n        .svgbutton-pause {\n            font-size: 0.68rem;\n            // Safari 9 bug\n            // https://github.com/jgthms/bulma/issues/727\n            margin-top: auto;\n            margin-bottom: auto;\n        }\n\n        .stopwatch-svg {\n            width: 1.1em;\n            height: 1.1em;\n            margin-right: 0.3em;\n            margin-top: 0.1em;\n            //padding-top: 0.12em;\n            //margin-top: 0.11em;\n        }\n    }\n\n    .stopwatch-timer {\n        display: flex;\n        margin-right: auto;\n    }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
