import { miscStore, uiStore } from '@/App/stores';
import { storageSet } from '@/services/thesudokuapp-storage';
import { api } from '@/services/thesudokuapp-api';

class Logger {
    #offline = [];

    _matomo() {
        if (window.navigator.onLine === false) {
            return this.#offline;
        }

        if (this.#offline.length) {
            /* DoS */
            this.#offline.forEach((record/*, i*/) => {
                if (record[0] === 'trackEvent') {
                    record[1] += ' (offline)';
                }

                (window._paq || []).push(record);
                //setTimeout(() => (window._paq || []).push(record), (i + 1) * 50);
            });

            this.#offline = [];
        }

        return window._paq || [];
    }

    trackPageView({ type, theme, newvisit = false, visitor }) {
        const
            _matomo = this._matomo(),
            search = window.thesudokuapp.search || '';

        _matomo.push(['setDocumentTitle', (newvisit ? '(new visit) ' : '') + document.title]);
        _matomo.push(['setCustomUrl', window.location.href + search]);
        _matomo.push(['setCustomDimension', 2, type]);
        _matomo.push(['setCustomDimension', 3, theme]);
        if (window.thesudokuapp.gadSource) { _matomo.push(['setCustomDimension', 6, window.thesudokuapp.gclid || 'gadSource' ]); }
        if (visitor) { _matomo.push(['setCustomDimension', 9, visitor]); }

        _matomo.push(['trackPageView']);

        _matomo.push(['deleteCustomDimension', 2]);
        _matomo.push(['deleteCustomDimension', 3]);
        if (window.thesudokuapp.gclid) { _matomo.push(['deleteCustomDimension', 6]); }
        if (visitor) { _matomo.push(['deleteCustomDimension', 9, visitor]); }

        window.gtag('event', 'page_view', {
            theme,
            init: type,
            //appType: (window.thesudokuapp.gaID === 'UA-155211728-1') ? window.thesudokuapp.appType : null,
            // убрать
            //page_title: document.title,
            page_location: window.location.href + search
            //page_path: window.location.pathname
        });
    }

    trackNewGame(resolverType) {
        this.trackPageView({ type: 'newgame: ' + resolverType, theme: document.documentElement.dataset.theme });
    }

    trackEvent({ category, action, eventName, eventValue, nonInteraction = false}) {
        const
            _matomo = this._matomo(),
            search = window.thesudokuapp.search || '';

        _matomo.push(['trackEvent', category, action, eventName, eventValue]);

        const params = {
            'event_category': category,
            'event_label': eventName,
            'value': eventValue,
            //page_title: document.title,
            page_location: window.location.href + search
            //page_path: window.location.pathname,
        };

        if (nonInteraction) {
            params['non_interaction'] = true;
        }

        if (action.startsWith('solved')) {
            params['value'] = 1;
        }

        if (window.gtag) {
            window.gtag('event', action, params);
        }
    }

    getMatomoId = async timeout => {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => { reject(new Error('MatomoId retrieval timed out')); }, timeout);

            this._matomo().push([ function() {
                clearTimeout(timer);
                resolve(this.getVisitorId());
            } ]);
        });
    };

    setMaMatomoId = async (maMatomoId, timeout) => {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => { reject(new Error('MatomoId setting timed out')); }, timeout);

            this._matomo().push([ function() {
                clearTimeout(timer);
                this.setVisitorId(maMatomoId);
                resolve(true);
            } ]);
        });
    };

    async trackFirstPageView(initType) {
        const initTheme = uiStore.theme;

        let
            backendMatomoId = window.thesudokuapp.visitor,
            visitor = '',
            maMatomoId,
            persistedMatomoId = miscStore.matomoId;

        try {
            maMatomoId = await this.getMatomoId(1000);
        } catch (error) {
            api.telegram('getMatomoId: ' + error.toString());
        }

        if (backendMatomoId && (backendMatomoId !== persistedMatomoId)) {
            visitor = `bp-mismatch(${backendMatomoId}:${persistedMatomoId || 'empty'})`;
            miscStore.matomoId = backendMatomoId;
            void storageSet.matomoId(backendMatomoId);
            persistedMatomoId = miscStore.matomoId;
        }

        if (backendMatomoId && (backendMatomoId !== maMatomoId)) {
            visitor = (visitor ? visitor + ',' : '') + `bm-mismatch(${backendMatomoId}:${maMatomoId})`;

            try {
                await this.setMaMatomoId(backendMatomoId, 1000);
            } catch (error) {
                api.telegram('setMaMatomoId: ' + error.toString());
            }
        }

        if (!backendMatomoId && persistedMatomoId) {
            visitor = (visitor ? visitor + ',' : '') + `bp-mismatch(empty:${persistedMatomoId})`;

            backendMatomoId = persistedMatomoId;
            window.thesudokuapp.setCookie('thesudokuapp_v', persistedMatomoId);

            if (persistedMatomoId !== maMatomoId) {
                visitor = (visitor ? visitor + ',' : '') + `pm-mismatch(${persistedMatomoId}:${maMatomoId})`;

                try {
                    await this.setMaMatomoId(persistedMatomoId, 1000);
                } catch (error) {
                    api.telegram('setMaMatomoId: ' + error.toString());
                }
            }
        }

        if (!backendMatomoId) {
            visitor = `new(${maMatomoId})`;

            window.thesudokuapp.setCookie('thesudokuapp_v', maMatomoId);
            miscStore.matomoId = maMatomoId;
            void storageSet.matomoId(maMatomoId);
        }

        this.trackPageView({
            type: initType,
            theme: initTheme,
            newvisit: true,
            visitor
        });
    }
}

const logger = new Logger();
export { logger };
