<template>
    <transition name="thesudokuapp-slidein-transition">
        <div class="thesudokuapp-newgamemenu thesudokuapp-slidein-transition-top">
            <div class="sudoku-switchbuttons-group" :class="{'sudoku-switchbuttons-group-disabled': waiting}">
                <div class="sudoku-switchbuttons-content">
                    <a
                        v-for="desc in difficulties"
                        :key="desc"
                        :href="'/' + desc"
                        class="sudoku-switchbutton"
                        :class="{ 'sudoku-switchbutton-active': selectedDifficulty === desc }"
                        :title="$t(`articletitles.${desc}`)"
                        @click.stop.prevent="() => selectedDifficulty = desc"
                    >
                        {{ $t(`difficulty.${desc}`) }}
                    </a>
                    <div class="sudoku-switchbutton-active-background" />
                </div>
            </div>
            <button class="thesudokuapp-actionbutton" :disabled="waiting" @click="submit">
                <span v-show="!waiting">{{ $t('action.start') }}</span>
                <app-spinner v-show="waiting" />
            </button>
        </div>
    </transition>
</template>

<script>
    import Spinner from './Spinner';
    import { uiStore, snackbarStore, sudokuStore, miscStore } from '@/App/stores';
    import { storageGet, storageSet } from '@/services/thesudokuapp-storage';
    import { logger } from '@/services/thesudokuapp-logger';
    import { api } from '@/services/thesudokuapp-api';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appSpinner: Spinner
        },
        data() {
            return {
                selectedDifficulty: null,
                sudokuboot: false
            };
        },
        computed: {
            show() { return uiStore.components.newgamemenu || uiStore.gameover; },
            waiting() { return uiStore.waiting; },
            difficulties() { return ['easy', 'medium', 'hard', 'expert']; }
        },
        watch: {
            show(newState) {
                if (newState) {
                    if (miscStore.newSudokuDifficulty) {
                        this.selectedDifficulty = miscStore.newSudokuDifficulty;
                        miscStore.newSudokuDifficulty = '';
                        this.sudokuboot = true;

                        void this.getRandomSudokuFromServer(this.selectedDifficulty, true);
                    } else {
                        this.selectedDifficulty = sudokuStore.grid.difficulty;
                        this.sudokuboot = false;
                    }
                }
            }
        },
        methods: {
            $t,
            async submit() {
                if (this.selectedDifficulty === 'expert') {
                    // noinspection JSDeprecatedSymbols
                    const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                        notificationId: 'expert',
                        showDontshow: true,
                        primaryMessage: $t('snackbar.expert[0]'),
                        secondaryMessage: $t('snackbar.expert[1]', {
                            url: '<a onclick="event.stopPropagation()" href="https://www.sudokuwiki.org/Strategy_Families" target="_blank" class="thesudokuapp-link">sudokuwiki.org</a>'
                        }),
                        actionCaption: $t('snackbar.action.tryexpert'),
                        dismissCaption: $t('snackbar.action.cancel')
                    });

                    if (!snackbarResolved || snackbarResolved === 'secondaryAction') {
                        return;
                    }
                }

                logger.trackEvent({
                    category: 'interaction',
                    action: 'random ' + this.selectedDifficulty + ' puzzle'
                });

                return this.getRandomSudokuFromServer(this.selectedDifficulty, this.sudokuboot);
            },
            async getRandomSudokuFromServer(difficulty, sudokuboot) {
                const cachedGrid = await storageGet.randomGrid(this.selectedDifficulty);

                if (cachedGrid) {
                    uiStore.components.newgamemenu = false;
                    uiStore.gameover = false;

                    sudokuStore.newGame({
                        grid: cachedGrid,
                        type: 'new random from cache',
                        sudokuboot
                    });

                    return;
                }

                try {
                    uiStore.toggleWaiting();
                    const grids = await api.getRandom(difficulty);
                    await storageSet.addNewGrids(grids);
                    uiStore.toggleWaiting();

                    uiStore.gameover = false;
                    uiStore.components.newgamemenu = false;

                    sudokuStore.newGame({
                        grid: await storageGet.randomGrid(difficulty),
                        type: 'new random by xqr',
                        sudokuboot
                    });
                } catch (error) {
                    const
                        cachedDifficulties = await storageGet.cachedDifficulties(),
                        cachedDifficultiesI18n = cachedDifficulties.map(difficulty => $t(`difficulty.${difficulty}`)),
                        availableGrids = cachedDifficulties.length > 1 ? cachedDifficultiesI18n.slice(0, -1).join(', ') + ' & ' + cachedDifficultiesI18n.at(-1) : cachedDifficultiesI18n[0] || '';

                    uiStore.toggleWaiting();

                    const additionalSecondaryMessage =
                        $t('snackbar.unablenew[0]', { difficulty: $t(`difficulty.${difficulty}`) }) +
                        (availableGrids ? $t('snackbar.unablenew[1]', { availableGrids }) : '');

                    const snackBarResolved = await api.apiAsyncCatch({
                        error,
                        source: 'new random by xqr',
                        additionalSecondaryMessage
                    });

                    if (snackBarResolved === 'action') {
                        return this.getRandomSudokuFromServer(difficulty, sudokuboot);
                    }

                    if (uiStore.gameover || sudokuboot) {
                        let difficulty;

                        if (cachedDifficulties.includes('easy')) {
                            difficulty = 'easy';
                        } else if (cachedDifficulties.includes('medium')) {
                            difficulty = 'medium';
                        } else if (cachedDifficulties.includes('hard')) {
                            difficulty = 'hard';
                        } else if (cachedDifficulties.includes('expert')) {
                            difficulty = 'expert';
                        }

                        if (difficulty) {
                            this.selectedDifficulty = difficulty;
                            return this.getRandomSudokuFromServer(difficulty, sudokuboot);
                        } else {
                            api.telegram('getRandomSudokuFromServer: no cached grids');
                        }
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-link {
        color: hsl(32, 56%, 58%);
        text-decoration: underline;

    }

    .thesudokuapp-newgamemenu {
        background-color: var(--dark-background-transparent);
        color: var(--dark-background-font-color);
        font-size: 0.45rem;
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        z-index: 4;
        position: absolute;
        top: 0;
        box-shadow: 2px 0 7px 0 var(--accent-color-transparent-dark);

        .sudoku-switchbuttons-group {
            font-size: 0.45rem;

            &.sudoku-switchbuttons-group-disabled {
                opacity: 0.5;
                cursor: progress;

                .sudoku-switchbutton {
                    pointer-events: none;
                }
            }

            .sudoku-switchbuttons-content {
                margin-left: initial;
                border-color: var(--dark-background-font-color);
            }

            .sudoku-switchbutton {
                display: block;
                line-height: normal;
                text-decoration: none;
                color: inherit;
                text-align: center;
                transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                padding: 0.15rem;
            }

            .sudoku-switchbutton.sudoku-switchbutton-active {
                color: var(--dark-background-transparent);
            }

            .sudoku-switchbutton-active-background {
                background-color: var(--dark-background-font-color);
            }
        }

        .thesudokuapp-actionbutton {
            margin-left: 0.35rem;
            width: 3rem;
        }
    }

    #puzzleId {
        border-radius: 1rem;
        padding: 0.2rem 0.4rem;
        font-size: inherit;

        border: 2px solid transparent;
        width: 3.1rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        color: var(--dark-background-transparent);
        background-color: var(--light-background-transparent);

        &:disabled {
            opacity: 0.5;
            cursor: progress;
        }

        &:focus:not(:invalid) {
            border: 2px solid var(--success-color);
        }

        &:invalid {
            box-shadow: none;
            border: 2px solid var(--danger-color);
        }

        &::placeholder {
            color: var(--predefined-value-color);
            opacity: initial;
        }
    }

</style>
