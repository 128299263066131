<template>
    <div v-show="show || opened" class="thesudokuapp-container-with-scrim">
        <transition name="scrim" @before-enter="setOpened(true)" @after-leave="setOpened(false)">
            <div v-show="uiStore.showScrim" class="thesudokuapp-container-scrim" @click="close" />
        </transition>

        <transition name="pause">
            <div v-show="isPaused && !uiStore.gameover" class="thesudokuapp-container-pause" @click="resumeStopwatch">
                <button class="thesudokuapp-svgbutton-paused">
                    <svg-icon class="thesudokuapp-svgbutton-paused-svg" icon="play" />
                </button>
            </div>
        </transition>

        <app-navigation-drawer v-show="uiStore.components.appmenu" />

        <app-game-finish v-show="uiStore.gameover" />

        <app-find-puzzle v-show="uiStore.components.findpuzzlebyidmenu" />

        <app-new-game-menu v-show="uiStore.components.newgamemenu || uiStore.gameover" />

        <div v-show="uiStore.gameover" id="sense-one" class="thesudokupp-sense" />
    </div>
</template>

<script>
    import NavigationDrawer from '@/App/components/NavigationDrawer';
    import GameFinish from '@/App/components/GameFinish';
    import NewGameMenu from '@/App/components/NewGameMenu';
    import FindPuzzle from '@/App/components/FindPuzzle';
    import SvgIcon from '@/App/components/SvgIcon.vue';

    import { stopwatchStore, uiStore } from '@/App/stores';

    export default {
        components: {
            appNavigationDrawer: NavigationDrawer,
            appGameFinish: GameFinish,
            appNewGameMenu: NewGameMenu,
            appFindPuzzle: FindPuzzle,
            svgIcon: SvgIcon
        },
        data() {
            return {
                opened: false
            };
        },
        computed: {
            show() {
                return (uiStore.showScrim || this.isPaused);
            },
            isPaused() {
                return stopwatchStore.stopwatchOnAndPaused;
            },
            uiStore() {
                return uiStore;
            }
        },
        methods: {
            resumeStopwatch() {
                stopwatchStore.resumeStopwatch();
                uiStore.switchUIState();
            },
            close() {
                uiStore.switchUIState();
            },
            setOpened(state) {
                this.opened = state;
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-container-with-scrim {
        position: absolute;
        top: 0;
        min-width: inherit;
        min-height: var(--min-height);
        width: var(--board-size);
        height: var(--board-size);
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        //border: var(--border-width) solid transparent;
    }

    .thesudokuapp-container-scrim {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--accent-color-transparent);
        will-change: opacity;
        opacity: 0.4;
    }

    .thesudokuapp-container-pause {
        position: absolute;
        width: 100%;
        height: 100%;
        color: var(--dark-background-font-color);
        background-color: var(--accent-color-transparent);
        display: flex;
        align-items: center;
        justify-content: center;
        border: var(--border-width) solid var(--accent-color);
    }

    .thesudokuapp-svgbutton-paused-svg {
        height: 1em;
    }

    @supports (backdrop-filter: blur(5px)) {
        .thesudokuapp-container-pause {
            backdrop-filter: blur(5px);
            background-color: var(--accent-color-transparent);
        }
    }
</style>
