// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-bottompanel {
  display: flex;
  align-items: center;
  font-size: 0.45rem;
  margin-bottom: var(--bottompanel-margin);
  height: var(--bottompanel-height);
  width: var(--board-size);
}
.thesudokuapp-bottompanel .thesudokuapp-bottompanel-record {
  display: flex;
  align-items: center;
}
.thesudokuapp-bottompanel .thesudokuapp-bottompanel-record svg {
  display: inline-block;
  height: 1em;
  margin-right: 0.3em;
  margin-top: 0.2em;
}
.thesudokuapp-bottompanel-puzzleid {
  margin-left: auto;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.clipboard-disabled {
  -webkit-user-select: auto;
          user-select: auto;
  cursor: auto;
}
@media (max-aspect-ratio: 6/10) {
.thesudokuapp-bottompanel {
    font-size: 0.46rem;
}
}`, "",{"version":3,"sources":["webpack://./App/components/BottomPanel.vue"],"names":[],"mappings":"AAGI;EACI,aAAA;EAEA,mBAAA;EAGA,kBAAA;EACA,wCAAA;EACA,iCAAA;EACA,wBAAA;AALR;AAUQ;EACI,aAAA;EACA,mBAAA;AARZ;AAUY;EACI,qBAAA;EAEA,WAAA;EACA,mBAAA;EACA,iBAAA;AAThB;AAeI;EACI,iBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AAZR;AAeI;EACI,yBAAA;UAAA,iBAAA;EACA,YAAA;AAZR;AAeI;AACI;IACI,kBAAA;AAZV;AACF","sourcesContent":["\n    @use '~@/scss/thesudoku-scssvars' as *;\n\n    .thesudokuapp-bottompanel {\n        display: flex;\n        //justify-content: center;\n        align-items: center;\n        //user-select: none;\n\n        font-size: 0.45rem;\n        margin-bottom: var(--bottompanel-margin);\n        height: var(--bottompanel-height);\n        width: var(--board-size);\n        //border-radius: var(--border-radius);\n        //box-shadow: 0 0 2px 0 var(--accent-color-transparent-dark);\n        //background-color: var(--cell-background-color);\n\n        .thesudokuapp-bottompanel-record {\n            display: flex;\n            align-items: center;\n\n            svg {\n                display: inline-block;\n                //width: 1em;\n                height: 1em;\n                margin-right: 0.3em;\n                margin-top: 0.2em;\n\n            }\n        }\n    }\n\n    .thesudokuapp-bottompanel-puzzleid {\n        margin-left: auto;\n        user-select: none;\n        cursor: pointer;\n    }\n\n    .clipboard-disabled {\n        user-select: auto;\n        cursor: auto;\n    }\n\n    @media #{$thesudokuapp-portrait} {\n        .thesudokuapp-bottompanel {\n            font-size: 0.46rem;\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
