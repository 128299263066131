<template>
    <section class="thesudokuapp-svgspinner-container">
        <svg class="thesudokuappp-svgspinner" viewBox="0 0 512 512">
            <path
                fill="currentColor"
                d="M24,256c-0.99-30.37,3.98-61.14,14.86-89.94c10.84-28.81,27.52-55.61,48.84-78.35c21.32-22.73,47.27-41.39,76.05-54.39 C192.52,20.29,224.08,13.01,256,12c31.91-0.99,64.28,4.29,94.54,15.78c30.27,11.45,58.4,29.02,82.24,51.44 c23.84,22.42,43.39,49.7,56.99,79.94c13.64,30.22,21.23,63.38,22.24,96.84l0,0.14c0.4,13.26-10.03,24.33-23.28,24.73 s-24.33-10.03-24.73-23.28c-0.02-0.51-0.01-1.08,0-1.59c0.98-27.29-3.36-54.86-13.03-80.75c-9.63-25.89-24.53-50.04-43.65-70.57	c-19.12-20.53-42.42-37.4-68.28-49.19C313.2,43.67,284.84,37.01,256,36c-28.83-0.99-58,3.67-85.34,13.95 c-27.35,10.24-52.83,26.03-74.46,46.25c-21.63,20.22-39.4,44.85-51.79,72.16C31.98,195.66,25.01,225.62,24,256z"
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 256 256"
                    to="360 256 256"
                    dur="0.8s"
                    repeatCount="indefinite"
                />
            </path>
            <path
                fill="var(--success-color)"
                d="M450,256c-0.59,25.36-6.16,50.56-16.37,73.58c-10.17,23.04-24.89,43.91-42.93,61.12c-18.04,17.22-39.4,30.78-62.46,39.7 c-23.05,8.95-47.78,13.18-72.24,12.6c-24.47-0.59-48.72-5.98-70.9-15.83c-22.19-9.82-42.28-24.02-58.85-41.41 c-16.57-17.4-29.62-37.98-38.18-60.19c-8.59-22.2-12.64-46-12.07-69.56c0.59,23.57,5.81,46.89,15.3,68.22 c9.46,21.34,23.14,40.66,39.9,56.58c16.76,15.93,36.57,28.46,57.93,36.66c21.35,8.24,44.21,12.1,66.88,11.53 c22.67-0.59,45.06-5.63,65.54-14.76c20.48-9.11,39.03-22.27,54.32-38.38c15.28-16.11,27.29-35.15,35.15-55.66 c7.88-20.5,11.57-42.43,11-64.2c-0.2-7.73,5.9-14.16,13.63-14.37s14.16,5.9,14.37,13.63c0.01,0.22,0.01,0.43,0,0.64L450,256z"
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 256 256"
                    to="-360 256 256"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>

            <!-- FUCK IOS SAFARI
            <use xlink:href="#thesudokuappp-svgspinner-path1" />
            <use xlink:href="#thesudokuappp-svgspinner-path2" />
            -->
        </svg>
        <span class="thesudokuapp-reducedmotion-message">{{ reducedmessage }}...</span>
    </section>
</template>

<script>
export default {
    props: {
        reducedmessage: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss">
    .thesudokuapp-svgspinner-container {
        color: var(--accent-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--accent-color-transparent-dark);
        font-size: 0.4rem;
    }
</style>
