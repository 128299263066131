import { defineStore } from 'pinia';
import { $statsStorage, storageSet } from '@/services/thesudokuapp-storage';

/*
 * У нас разные поля: в сторэдж date + hashId (сейчас в GameFinish мы добавляем hashId и дальше date
 * добавляется в updatePuzzleStats), на сервере recordtimestamp + hashid
 *
 * Также у нас в сторедже могут быть записи с puzzleId, а не hashId
 */
const getDefaultDifficultyState = () => ({
    games: {
        flawless: 0,
        loses: 0,
        solved: 0,
        autofill: 0,
        smarthints: 0,
        simplehints: 0,
        aftermistakes: 0,
    },
    averagetime: {
        flawless: 0,
        autofill: 0,
        smarthints: 0,
        simplehints: 0,
        aftermistakes: 0
    },
    winstreak: {
        best: 0,
        current: 0
    },
    besttime: {
        besttime: 0,
        date: undefined,
        puzzleId: undefined
    }
});

const getDefaultCurrentPuzzleState = () => ({
    hashid: null,
    difficulty: null,
    synced: false,
    u: null,
    m: null,
    recordtimestamp: null,
    time: null,
    autofill: false,
    smarthints: false,
    simplehints: false,
    aftermistakes: false,
    mistakes: false,
    version: 2
});

const newAverageTime = ({
    oldAverageTime,
    games,
    time
}) => {
    return Math.round((oldAverageTime * games + time) / (games + 1));
};

const recalc = ({
    time,
    type,
    stats,
}) => {
    stats.averagetime[type] = newAverageTime({
        oldAverageTime: stats.averagetime[type],
        games: stats.games[type],
        time
    });

    stats.games[type] += 1;
};

export const useProfileStore = defineStore('profile', {
    state: () => ({
        emailSigningInData: {
            name: '',
            email: ''
        },
        lastsynced: 0,
        easy: getDefaultDifficultyState(),
        medium: getDefaultDifficultyState(),
        hard: getDefaultDifficultyState(),
        expert: getDefaultDifficultyState(),
        current: getDefaultCurrentPuzzleState()
    }),
    actions: {
        resetPuzzleState(hashid) {
            const current = getDefaultCurrentPuzzleState();
            current.hashid = hashid;
            this.current = current;
        },
        updateEmailSigningInData({ name, email } = {}) {
            this.emailSigningInData = (name && email) ? { name, email } : { name: '', email: '' };
            storageSet.emailSigningInData(this.emailSigningInData);
        },
        async updateDifficultyStats({
            difficulty,
            time,
            autofill = false,
            smarthints = false,
            simplehints = false,
            aftermistakes = false,
            mistakes = false,
            hashid = null,
            recordtimestamp
        }) {
            const oldBestTime = this[difficulty].besttime.besttime;
            const stats = this[difficulty];

            if (mistakes) {
                stats.games.loses += 1;
                stats.winstreak.current = 0;
            } else {
                stats.winstreak.current += 1;

                if (stats.winstreak.best < stats.winstreak.current) {
                    stats.winstreak.best += 1;
                }

                if (!autofill && !smarthints && !simplehints && !aftermistakes && !mistakes) {
                    recalc({
                        time,
                        type: 'flawless',
                        stats
                    });

                    if (!stats.besttime.besttime || (stats.besttime.besttime > time)) {
                        stats.besttime = {
                            besttime: time,
                            date: recordtimestamp,
                            puzzleId: hashid
                        };
                    }
                } else {
                    stats.games.solved += 1;
                }

                if (autofill) {
                    recalc({
                        time,
                        type: 'autofill',
                        stats
                    });
                }

                if (smarthints) {
                    recalc({
                        time,
                        type: 'smarthints',
                        stats
                    });
                }

                if (simplehints) {
                    recalc({
                        time,
                        type: 'simplehints',
                        stats
                    });
                }

                if (aftermistakes) {
                    recalc({
                        time,
                        type: 'aftermistakes',
                        stats
                    });
                }
            }

            const newBestTime = this[difficulty].besttime.besttime;

            await storageSet.difficultyStats({ difficulty, stats });

            return {
                old: oldBestTime,
                new: oldBestTime !== newBestTime ? newBestTime : null
            };
        },
        async updatePuzzleStats({
            hashid,
            difficulty = null,
            synced = false,
            u = null,
            m = null,
            time,
            recordtimestamp,
            autofill = false,
            smarthints = false,
            simplehints = false,
            aftermistakes = false,
            mistakes = false,
            version = 2
        }) {
            if (hashid !== this.current.hashid) {
                return;
            }

            const oldWithHelpers = this.current.autofill || this.current.smarthints || this.current.simplehints || this.current.aftermistakes;
            const oldMistakes = this.current.mistakes;
            const newWithHelpers = autofill || smarthints || simplehints || aftermistakes;

            if (!this.current.time
                || (oldMistakes && !mistakes)
                || (!newWithHelpers && oldWithHelpers)
                || (newWithHelpers && oldWithHelpers && (time < this.current.time))
                || (!newWithHelpers && !oldWithHelpers && (time < this.current.time))
            ) {
                const res = {
                    old: this.current.time,
                    new: time
                };

                this.current = {
                    hashid,
                    difficulty,
                    synced,
                    u,
                    m,
                    time: time || 0,
                    recordtimestamp,
                    autofill,
                    smarthints,
                    simplehints,
                    aftermistakes,
                    mistakes,
                    version
                };

                await storageSet.puzzleStats(this.current);

                return res;
            }
        },
        async clear() {
            await $statsStorage.clear();

            this.$state = {
                emailSigningInData: {
                    name: '',
                    email: ''
                },
                lastsynced: 0,
                easy: getDefaultDifficultyState(),
                medium: getDefaultDifficultyState(),
                hard: getDefaultDifficultyState(),
                expert: getDefaultDifficultyState(),
                current: getDefaultCurrentPuzzleState()
            };
        }
    }
});
