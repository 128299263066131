import { gameplaySettingsStore, miscStore, profileStore, snackbarStore } from '@/App/stores';
import { $storage, $statsStorage } from '@/services/thesudokuapp-storage-helper';
import { utils } from '@/services/thesudokuapp-utils';

const storageSet = {
    stopwatch(currentTimer) {
        return $storage.setItem('stopwatch', currentTimer);
    },
    gameplaySettings(gameplaySettings) {
        return $storage.setItem('gameplaySettingsv2', { ...gameplaySettings });
    },
    notifications(notifications) {
        return $storage.setItem('notificationsv2', { ...notifications });
    },
    emailSigningInData(data) {
        if (!data) {
            return $storage.removeItem('emailSignin');
        }

        return $storage.setItem('emailSignin', { ...data });
    },
    difficultyStats({ difficulty, stats }) {
        return $statsStorage.setItem(difficulty, { ...stats });
    },
    puzzleStats(data) {
        return $statsStorage.setItem(data.hashid, { ...data });
    },
    lastsynced(timestamp) {
        if (!timestamp) {
            return $storage.removeItem('lastsynced');
        }

        return $storage.setItem('lastsynced', timestamp);
    },
    feedback(data) {
        if (!data) {
            return $storage.removeItem('feedback');
        }

        return $storage.setItem('feedback', data);
    },
    /**
     *
     * @param {gridFromServer[]} grids
     * @returns {Promise<*|undefined|void|Awaited<null>|Awaited<unknown>>}
     */
    async addNewGrids(grids) {
        if (!utils.isArray(grids) || !grids[0].Difficulty) {
            return;
        }

        const
            difficulty = grids[0].Difficulty,
            storageGrids = await $storage.getItem('grids') || {};

        if (!storageGrids[difficulty]) {
            storageGrids[difficulty] = grids.map(grid => ({
                hashid: grid.HashID,
                difficulty: grid.Difficulty,
                grid: grid.Grid,
                solution: grid.Solution
            }));
        } else if (storageGrids[difficulty].length < 50) {
            const gridIDs = storageGrids[difficulty].map(g => g.HashID);
            const uniqueGrids = grids
                .filter(grid => !gridIDs.includes(grid.HashID))
                .map(grid => ({
                    hashid: grid.HashID,
                    difficulty: grid.Difficulty,
                    grid: grid.Grid,
                    solution: grid.Solution
            }));

            storageGrids[difficulty] = storageGrids[difficulty].concat(uniqueGrids.slice(0, 50-storageGrids[difficulty].length));
        }

        return $storage.setItem('grids', storageGrids);
    },
    // TODO:remove legacy properties
    grid(grid) {
        return $storage.setItem('currentgrid', {
            hashid: grid.hashid || grid.GridID,
            difficulty: grid.difficulty || grid.Difficulty,
            grid: grid.grid || grid.Grid,
            solution: grid.solution || grid.Solution,
            autofill: grid.autofill || grid.Autofill,
            afterlose: grid.afterlose || grid.AfterLose,
            wrongvaluesentered: grid.wrongvaluesentered || grid.WrongValuesEntered,
            simplehints: grid.simplehints || grid.SimpleHints,
            smarthints: grid.smarthints || grid.SmartHints
        });
    },
    async removeGrid({ hashid, difficulty }) {
        const storageGrids = await $storage.getItem('grids') || {};

        if (!storageGrids || !storageGrids[difficulty] || !storageGrids[difficulty].length) {
            return;
        }

        const index = storageGrids[difficulty].findIndex(g => g.hashid === hashid);
        if (index >= 0) {
            storageGrids[difficulty].splice(index, 1);
            await $storage.setItem('grids', storageGrids);
        }
    },
    values(selectedValues) {
        return $storage.setItem('valuesv2', Object.values(selectedValues).join(''));
    },
    hints(selectedHints) {
        return $storage.setItem(
            'hintsv2',
            Object.fromEntries(
                Object.entries(selectedHints)
                    .map(([k, v]) => [
                        k,
                        v.map((hint, index) => hint ? index : '').join('')
                    ])
                    .filter(([, v]) => v)
            )
        );
    },
    matomoId(matomoId) {
        return $storage.setItem('matomoId', matomoId);
    },
    adClickCount(count) {
        if (!count) {
            return $storage.removeItem('adClickCount');
        }

        return $storage.setItem('adClickCount', count);
    }
};

const storageGet = {
    /**
     * @param hashid
     * @returns {Promise<null|persistedGridStats>}
     */
    puzzleStats(hashid) {
        return $statsStorage.getItem(hashid);
    },
    /**
     * @param difficulty
     * @returns {Promise<gridFromStorage|null>}
     */
    async randomGrid(difficulty) {
        const grids = await $storage.getItem('grids');

        if (!grids || !grids[difficulty] || !grids[difficulty].length) {
            return null;
        }

        const
            index = utils.random(0, grids[difficulty].length - 1),
            grid = grids[difficulty][index];

        grids[difficulty].splice(index, 1);

        void $storage.setItem('grids', grids);

        // legacy to be removed
        if (grid.Grid && grid.HashID && grid.Difficulty && grid.Solution) {
            return {
                hashid: grid.HashID,
                grid: grid.Grid,
                solution: grid.Solution,
                difficulty: grid.Difficulty
            };
        }

        return grid;
    },
    async cachedDifficulties() {
        const grids = await $storage.getItem('grids');

        if (!grids) {
            return [];
        }

        return Object.keys(grids).reduce(
            (acc, difficulty) => {
                if (grids[difficulty].length) {
                    acc.push(difficulty);
                }
                return acc;
            }, []);
    },
    async adClickCount() {
        return await $storage.getItem('adClickCount') || 0;
    },
    feedback() {
        return $storage.getItem('feedback');
    }
};

/**
 * @returns {Promise<persistedGameState|null>}
 */
const storageGetGameState = () => {
    return Promise.all([
        $storage.getItem('stopwatch'),
        (async () => {
            /**
             *
             * @type {persistedLegacyGrid|null}
             */
            const legacyGrid = await $storage.getItem('gridv2');

            if (legacyGrid) {
                await $storage.removeItem('gridv2');

                return {
                    hashid: legacyGrid.GridID,
                    difficulty: legacyGrid.Difficulty,
                    grid: legacyGrid.Grid,
                    solution: legacyGrid.Solution,
                    autofill: legacyGrid.Autofill,
                    afterlose: legacyGrid.AfterLose,
                    wrongvaluesentered: legacyGrid.WrongValuesEntered,
                    simplehints: legacyGrid.SimpleHints,
                    smarthints: legacyGrid.SmartHints
                };
            }

            return await $storage.getItem('currentgrid');
        })(),
        $storage.getItem('hintsv2'),
        $storage.getItem('valuesv2')
    ]).then(res => (res[1] ? {
        ...res[1],
        persistedHints: res[2] && Object.keys(res[2]).length ? res[2] : null,
        persistedSelectedValues: res[3] && parseInt(res[3]) ? res[3] : null, // parseInt('0000..000') = 0
        stopwatch: res[0] || 0,
    } : null));
};

const storageGetAndPatchStore = {
    async gameplaySettings() {
        const settingsFromStorage = await $storage.getItem('gameplaySettingsv2');

        if (!settingsFromStorage) {
            return;
        }

        Object.keys(gameplaySettingsStore.$state).forEach(key => {
            if (key in settingsFromStorage) {
                gameplaySettingsStore[key] = settingsFromStorage[key];
            }
        });

        gameplaySettingsStore.fixGameSettingsInconsistencies();
    },
    async notifications() {
        const notificationsFromStorage = await $storage.getItem('notificationsv2');

        if (!notificationsFromStorage) {
            return;
        }

        Object.keys(snackbarStore.notifications).forEach(key => {
            if (key in notificationsFromStorage) {
                snackbarStore.notifications[key] = notificationsFromStorage[key];
            }
        });
    },
    async emailSigningInData() {
        const emailSigningInData = await $storage.getItem('emailSignin');

        if (!emailSigningInData || !emailSigningInData.email || !emailSigningInData.name) {
            return;
        }

        profileStore.emailSigningInData = {
            email: emailSigningInData.email,
            name: emailSigningInData.name
        };
    },
    async currentPuzzleStats(hashid) {
        const stats = await $statsStorage.getItem(hashid);

        if (stats) {
            if (stats.puzzleId) {
                stats.hashid = stats.puzzleId;
                delete stats.puzzleId;
            }

            if (stats.hashId) {
                stats.hashid = stats.hashId;
                delete stats.hashId;
            }

            profileStore.current = stats;
        } else {
            profileStore.resetPuzzleState(hashid);
        }
    },
    async difficultyStats(difficulty) {
        const stats = await $statsStorage.getItem(difficulty);

        if (stats) {
            profileStore[difficulty] = stats;
        }
    },
    async lastsynced() {
        profileStore.lastsynced = await $storage.getItem('lastsynced');
    },
    async matomoId() {
        miscStore.matomoId = await $storage.getItem('matomoId');
    }
};

const patchStoresFromStorage = () => {
    return Promise.all([
        storageGetAndPatchStore.gameplaySettings(),
        storageGetAndPatchStore.notifications(),
        storageGetAndPatchStore.emailSigningInData(),
        storageGetAndPatchStore.lastsynced(),
        storageGetAndPatchStore.difficultyStats('easy'),
        storageGetAndPatchStore.difficultyStats('medium'),
        storageGetAndPatchStore.difficultyStats('hard'),
        storageGetAndPatchStore.difficultyStats('expert'),
        storageGetAndPatchStore.matomoId()
    ]);
};

export { $storage, $statsStorage, storageSet, storageGet, storageGetAndPatchStore, patchStoresFromStorage, storageGetGameState };
