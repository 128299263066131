import localforage from 'localforage';
import { logger } from '@/services/thesudokuapp-logger';

const
    mainStorage = localforage.createInstance({ name: 'thesudokuapp' }),
    statsStorage = localforage.createInstance({ name: 'thesudokuappstats' });

let storageEnabled = true;

const exceptionHandler = e => {
    logger.trackEvent({
        category: 'exception:storage',
        action: e.toString()
    });

    storageEnabled = false;

    return Promise.resolve(null);
};

const storageFactory = storage => ({
    async getItem(item) {
        if (!storageEnabled) {
            return Promise.resolve(null);
        }

        try {
            return await storage.getItem(item);
        } catch (e) {
            return exceptionHandler(e);
        }
    },
    async setItem(item, value) {
        if (!storageEnabled) {
            return Promise.resolve(null);
        }

        try {
            //return await storage.setItem(item, value);
            return storage.setItem(item, JSON.parse(JSON.stringify(value)));
        } catch (e) {
            return exceptionHandler(e);
        }
    },
    async removeItem(item) {
        if (!storageEnabled) {
            return Promise.resolve(null);
        }

        try {
            return await storage.removeItem(item);
        } catch (e) {
            return  exceptionHandler(e);
        }
    },
    async iterate(callback) {
        if (!storageEnabled) {
            return Promise.resolve(null);
        }

        try {
            return await storage.iterate(callback);
        } catch (e) {
            return exceptionHandler(e);
        }
    },
    async clear() {
        if (!storageEnabled) {
            return Promise.resolve(null);
        }

        try {
            return await storage.clear();
        } catch (e) {
            return exceptionHandler(e);
        }
    }

});

const
    $storage = storageFactory(mainStorage),
    $statsStorage = storageFactory(statsStorage);

export { $storage, $statsStorage };
