<template>
    <transition name="thesudokuapp-slidein-transition">
        <div class="thesudokuapp-menu" :class="{ 'thesudokuapp-menu-with-pwabanner': pwaBanner }" @click="uiStore.switchUIState({type: 'appmenu', newState: false})">
            <button v-if="showStopwatch" class="svgbutton svgbutton-with-textontheright svgbutton-with-marginbottom" @click.stop="showStats()">
                <svg-icon icon="idcard" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.stats') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright" @click.stop="uiStore.switchUIState({type: 'newgamemenu', newState: true})">
                <svg-icon icon="newgame" class="svgbutton-svg" style="transform: rotate(90deg);" />
                <span class="svgbutton-text">{{ $t('action.newpuzzle') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright" @click.stop="uiStore.switchUIState({type: 'findpuzzlebyidmenu', newState: true})">
                <svg-icon icon="hashtag" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.findpuzzle') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright" @click.stop="uiStore.switchUIState({type: 'importsudoku', newState: true})">
                <svg-icon icon="circleup" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.importpuzzle') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright" @click.stop="uiStore.switchUIState({type: 'ocr', newState: true})">
                <svg-icon icon="ocr" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.ocr') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright svgbutton-with-marginbottom" @click.stop="uiStore.switchUIState({type: 'settings', newState: true})">
                <svg-icon icon="gear" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.settings') }}</span>
            </button>

            <button v-if="!uiStore.printDisabled" class="svgbutton svgbutton-with-textontheright thesudokuapp-print-button" @click.stop="print">
                <svg-icon icon="print" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.print') }}</span>
            </button>

            <button class="svgbutton svgbutton-with-textontheright" @click.stop="uiStore.switchUIState({type: 'feedback', newState: true})">
                <svg-icon icon="envelope" class="svgbutton-svg" />
                <span class="svgbutton-text">{{ $t('action.feedback') }}</span>
            </button>

            <section class="thesudokuapp-language-selector">
                <!--<button
                    v-for="(params, lang) in i18n_supported"
                    :key="lang"
                    class="sudoku-link-button"
                    :disabled="lang === $i18n.locale"
                    @click="changeLanguage(lang)"
                >
                    <span>{{ params.d }}</span>
                </button>-->
                <button class="sudoku-link-button" @click.stop="addTranslation"><span>{{ $t('language.addTranslation') }}</span></button>
                <button class="sudoku-link-button" @click.stop="fixTypo"><span>{{ $t('language.fixTypo') }}</span></button>
            </section>

            <button v-if="pwaBanner" class="thesudokuapp-theme-notification-button" @click="pwaAction">
                <img src="/assets/thesudokuapp192.png" alt="the sudoku app icon" class="thesudokuapp-theme-notification-img" loading="lazy" height="192" width="192">
                <span class="thesudokuapp-theme-notification-text" v-html="pwaInstallText" />
            </button>
        </div>
    </transition>
</template>

<script>
    import { utils } from '@/services/thesudokuapp-utils';
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { gameplaySettingsStore, uiStore, miscStore } from '@/App/stores';
    import { pwaAction } from '@/services/thesudokuapp-pwa';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { logger } from '@/services/thesudokuapp-logger';

    export default {
        components: {
            svgIcon: SvgIcon
        },
        computed: {
            pwaInstallText() {
                return $t('action.pwa.addto') + $t(`action.pwa.${utils.pwaType()}`);
            },
            /*i18n_supported() {
                return window.thesudokuapp.i18n.s;
            },*/
            pwaBanner() {
                return !!miscStore.beforeInstallPromptEvent;
            },
            showStopwatch() {
                return gameplaySettingsStore.showStopwatch;
            },
            uiStore() {
                return uiStore;
            }
        },
        methods: {
            $t,
            showStats() {
                logger.trackEvent({
                    category: 'interaction',
                    action: 'show profile'
                });

                uiStore.switchUIState({type: 'stats', newState: true});
            },
            print() {
                logger.trackEvent({
                    category: 'interaction',
                    action: 'print'
                });

                window.print();
            },
            pwaAction() {
                pwaAction();
            },
            /*changeLanguage(lang) {
                this.$api.lang(lang)
                    .then(response => {
                        this.$logger.trackEvent({
                            category: 'settings',
                            action: 'lang',
                            eventName: this.$i18n.locale + '->' + lang
                        });

                        this.$i18n.setLocaleMessage(lang, response['frontend']);
                        this.$i18n.locale = lang;
                        window.thesudokuapp.i18n.c = lang;

                        document.getElementsByTagName('html')[0].setAttribute('lang', lang);
                    })
                    .catch(error => {
                        this.$api.apiCatch({
                            error,
                            retryCallback: () => {
                                this.changeLanguage();
                            },
                            source: 'changelang'
                        });
                    });
            },*/
            addTranslation() {
                miscStore.feedbackTemplate = $t('language.addTranslationSubject');
                uiStore.switchUIState({type: 'feedback', newState: true});
            },
            fixTypo() {
                miscStore.feedbackTemplate = $t('language.fixTypoSubject');
                uiStore.switchUIState({type: 'feedback', newState: true});
            }
        }
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-scssvars' as *;

    .thesudokuapp-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.4rem;
        position: absolute;
        top: 0;
        left: 0;
        height: var(--board-size);
        min-height: var(--min-height);
        width: 42%;
        background-color: var(--accent-color-transparent-dark);
        box-shadow: 2px 0 7px 0 var(--accent-color-transparent-dark);
        color: var(--dark-background-font-color);
        z-index: 6;
        will-change: transform;
        overflow: hidden auto;

        .sudoku-link-button {
            text-align: left;
        }

        .svgbutton {
            font-size: 1.1rem;
            margin-bottom: 0.3rem;

            &.svgbutton-with-marginbottom {
                margin-bottom: 1rem;
            }

            &.svgbutton-with-textontheright {
                width: 100%;
                justify-content: flex-start;
            }

            &.svgbutton-with-textontheright .svgbutton-text {
                padding-left: 0.2rem;
                padding-bottom: 0.1rem;
                text-align: left;
                width: 100%;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .svgbutton-svg {
                width: 0.7em;
                height: auto;
                margin: initial;
            }
        }

        &.thesudokuapp-menu-with-pwabanner {
            .svgbutton {
                margin-bottom: 0.15rem;

                &.svgbutton-with-marginbottom {
                    margin-bottom: auto;
                }
            }
        }

        .thesudokuapp-menu-section-header {
            font-size: 0.35rem;
            text-transform: uppercase;
            font-weight: bold;
            opacity: 0.35;
            margin-bottom: 0.7rem;
            display: inline-block;
            margin-right: auto;
        }
    }

    .thesudokuapp-theme-notification-button {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.4rem;
        line-height: 1;
        display: flex;
        align-items: center;

        &:active {
            color: var(--accent-color-lighter);
        }
    }

    .thesudokuapp-theme-notification-img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.2rem;
    }

    .thesudokuapp-theme-notification-text {
        text-align: left;
    }

    .thesudokuapp-language-selector {
        margin-top: auto;
        padding-left: 0.7em;
        margin-left: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.1rem;
    }

    @media #{$thesudokuapp-portrait} {
        .thesudokuapp-menu {
            width: 46%;

            .svgbutton {
                font-size: 1.1rem;

                &.svgbutton-with-textontheright .svgbutton-text {
                    padding-bottom: initial;
                }
            }
        }
    }

    @media #{$thesudokuapp-portrait-tablet}, #{$thesudokuapp-portrait-tablet-ipad} {
        .thesudokuapp-menu {
            .svgbutton {
                font-size: 1.4rem;
            }
        }
    }

</style>
