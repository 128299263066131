<template>
    <div class="thesudokuapp-container">
        <div class="thesudokuapp-container-inner">
            <app-settings v-show="uiStore.components.settings" class="thesudokuapp-container-inner-content" />

            <transition name="thesudokuapp-slidein-transition" @after-enter="() => { $refs.feedback.focus(); }">
                <app-feedback v-if="uiStore.components.feedback" ref="feedback" class="thesudokuapp-container-inner-content thesudokuapp-slidein-transition-right" />
            </transition>

            <transition name="thesudokuapp-slidein-transition" @after-enter="() => { $refs.import.focus(); }">
                <app-import-sudoku v-if="uiStore.components.importsudoku" ref="import" class="thesudokuapp-container-inner-content thesudokuapp-slidein-transition-right" />
            </transition>

            <transition name="thesudokuapp-slidein-transition">
                <app-ocr v-if="uiStore.components.ocr" class="thesudokuapp-container-inner-content thesudokuapp-slidein-transition-right" />
            </transition>

            <transition name="thesudokuapp-slidein-transition">
                <app-profile v-if="uiStore.components.stats" class="thesudokuapp-container-inner-content thesudokuapp-slidein-transition-right" />
            </transition>

            <app-board />
            <app-container-with-scrim />
            <app-bottom-panel v-show="grid" />
        </div>

        <app-controls v-if="grid" />
    </div>
</template>

<script>
    import Board from '@/App/components/Board';
    import Settings from './components/Settings';
    import Feedback from './components/Feedback';
    import ImportSudoku from './components/ImportSudoku';
    import Ocr from './components/Ocr.vue';
    import Profile from './components/Profile';
    import ContainerWithScrim from './components/ContainerWithScrim';
    import BottomPanel from './components/BottomPanel';
    import Controls from './components/Controls';
    import { keyboardMixin } from '@/services/thesudokuapp-keyboard';
    import {miscStore, sudokuStore, uiStore} from '@/App/stores';
    import { patchStoresFromStorage } from '@/services/thesudokuapp-storage';
    import { patchStoresFromBrowserMode } from '@/services/thesudokuapp-browsermode';
    import { sudokuBoot } from '@/services/thesudokuapp-sudokuboot';
    import { initPwa } from '@/services/thesudokuapp-pwa';
    import { logger } from '@/services/thesudokuapp-logger';
    import { initExternalButtons } from '@/services/thesudokuapp-externalbuttons';

    export default {
        components: {
            appBoard: Board,
            appSettings: Settings,
            appFeedback: Feedback,
            appImportSudoku: ImportSudoku,
            appOcr: Ocr,
            appProfile: Profile,
            appBottomPanel: BottomPanel,
            appControls: Controls,
            appContainerWithScrim: ContainerWithScrim
        },
        mixins: [ keyboardMixin ],
        computed: {
            uiStore() { return uiStore; },
            grid() { return sudokuStore.grid.grid; },
            initfinished() { return miscStore.initfinished; },
        },
        watch: {
            initfinished(newState) {
                if (newState) {
                    initExternalButtons();
                }
            }
        },
        async mounted() {
            initPwa();

            await patchStoresFromBrowserMode();
            await patchStoresFromStorage();

            const initType = await sudokuBoot();

            if (window.thesudokuapp.vroute === 'sudoku-ocr') {
                uiStore.switchUIState({ type: 'ocr', newState: true });
            }

            if (window.thesudokuapp.vroute === 'profile') {
                uiStore.switchUIState({ type: 'stats', newState: true });
            }

            if (window.thesudokuapp.articleDifficulty && !window.thesudokuapp.hideArticle) {
                void logger.trackFirstPageView(initType);
            } else {
                window.thesudokuapp.initTrackers(
                    () => logger.trackFirstPageView(initType)
                );
            }
        }
    };
</script>

<style lang="scss">
    @use 'sass:meta';

    .thesudokuapp-container {
        min-width: var(--min-width);
        width: var(--board-size);
        //position: relative;
    }

    .thesudokuapp-container-inner {
        position: relative;
        width: var(--board-size);
        //padding-right: 2.3rem;
        overflow: hidden;
        min-width: inherit;
    }

    .thesudokuapp-container-inner-content {
        position: absolute;
        min-width: inherit;
        min-height: var(--min-height);
        width: var(--board-size);
        height: var(--board-size);
        color: var(--dark-background-font-color);
        background-color: var(--accent-color-transparent);
        background-clip: padding-box;
        display: flex;
        border: var(--border-width) solid var(--accent-color);
        z-index: 5;
        flex-direction: column;
        padding: 0.5rem;
        font-size: 0.4rem;
    }

    @include meta.load-css('~@/scss/thesudoku-i18n');
    @include meta.load-css('~@/scss/thesudoku-portrait');
    @include meta.load-css('~@/scss/thesudoku-print');
</style>
