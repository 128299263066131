import { storageGet, storageSet } from '@/services/thesudokuapp-storage';
import { logger } from '@/services/thesudokuapp-logger';
import { miscStore, snackbarStore } from '@/App/stores';
import { pwaAction } from '@/services/thesudokuapp-pwa';
import { $t } from '@/services/thesudokuapp-i18n';
import { utils } from '@/services/thesudokuapp-utils';

const processAdClicks = async () => {
    let adClickCount = await storageGet.adClickCount();

     if (window.thesudokuapp.gadSource) {
         void storageSet.adClickCount(++adClickCount);

         if (adClickCount >= 3 && !!miscStore.beforeInstallPromptEvent) {
             const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                 showLogo: true,
                 primaryMessage: $t('snackbar.pwa.primary', {'action': $t(`action.pwa.${utils.pwaType()}`)}),
                 secondaryMessage: $t('snackbar.pwa.secondary'),
                 actionCaption: $t('snackbar.pwa.action'),
                 dismissCaption: $t('snackbar.pwa.notnow'),
             });

             snackbarStore.hideSnackbar();

             if (snackbarResolved === 'action') {
                 void pwaAction();
             } else {
                 logger.trackEvent({
                     category: 'notification:dismissed',
                     action: 'pwaappinstall_forced'
                 });
             }
         }
     } else if (adClickCount) {
         void storageSet.adClickCount();
         logger.trackEvent({
             category: 'non-interaction',
             action: 'adClickCount reset',
             nonInteraction: true
         });
     }
};

export { processAdClicks };
