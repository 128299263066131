import { sudokuStore, uiStore } from '@/App/stores';
import { utils } from '@/services/thesudokuapp-utils';
import { logger } from '@/services/thesudokuapp-logger';
import { markRaw } from 'vue';

const initExternalButtons = () => {
    const externalButtons = [ 'thesudokuapp-hamburger', 'thesudokuapp-sharebutton', 'thesudokuapp-fullscreenbutton', 'thesudokuapp-overflowmenubutton' ];

    for (const buttonId of externalButtons) {
        const button = document.getElementById(buttonId);

        if (!button) {
            return;
        }

        if (buttonId === 'thesudokuapp-hamburger') {
            button.addEventListener('click', () => {
                uiStore.switchUIState({ type: 'appmenu' });
            });
            // noinspection JSValidateTypes
            uiStore.hamburgerEl = markRaw(button);
            // noinspection JSValidateTypes
            uiStore.hamburgerSvgEl = markRaw(document.getElementById('thesudokuapp-hamburger-svg'));
            button.removeAttribute('disabled');
        }

        if (buttonId === 'thesudokuapp-overflowmenubutton') {
            button.addEventListener('click', () => {
                uiStore.switchUIState({type: 'overflowmenu'});
            });
            uiStore.overflowEl = markRaw(button);
            button.removeAttribute('disabled');
        }

        if (buttonId === 'thesudokuapp-sharebutton' && !uiStore.shareDisabled) {
            button.addEventListener('click', () => {
                void utils.share(sudokuStore.grid);
            });
            button.removeAttribute('disabled');
        }

        if (buttonId === 'thesudokuapp-fullscreenbutton' && !uiStore.fullscreenDisabled) {
            button.removeAttribute('disabled');

            button.addEventListener('click', () => {
                //event.stopPropagation();
                if (uiStore.webkitFullscreenDisabled === false) {
                    if (document.webkitFullscreenElement) {
                        void document.webkitExitFullscreen();
                    } else {
                        void document.documentElement.webkitRequestFullscreen();
                    }
                } else {
                    if (document.fullscreenElement) {
                        void document.exitFullscreen();
                    } else {
                        void document.documentElement.requestFullscreen();
                    }
                }
            });

            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    logger.trackEvent({ category: 'interaction', action: 'fullscreen on'});
                } else {
                    logger.trackEvent({ category: 'interaction', action: 'fullscreen off'});
                }
            });
        }
    }
};

export { initExternalButtons };
