import { cells, unitlist, units } from '@/services/thesudokuapp-peers.mjs';
import { getHint, parseRaw, solveSudoku } from '@/services/thesudokuapp-solver.mjs';
import { storageSet } from '@/services/thesudokuapp-storage';
import { logger } from '@/services/thesudokuapp-logger';
import { gameplaySettingsStore, snackbarStore, sudokuStore, uiStore } from '@/App/stores';
import { $t } from '@/services/thesudokuapp-i18n';
import { animateSmartHint } from '@/services/thesudokuapp-animation';
import { markRaw } from 'vue';
import { utils } from '@/services/thesudokuapp-utils';

const getGridToParse = () => {
    return cells.map(cellId => {
        if (sudokuStore.selectedValues[cellId] && sudokuStore.selectedValues[cellId] !== sudokuStore.solutionValues[cellId]) {
            sudokuStore.valuesCount[sudokuStore.selectedValues[cellId]]--;
            sudokuStore.grid.afterlose = true;
            return '0';
        }

        return (sudokuStore.predefinedValues[cellId] || sudokuStore.selectedValues[cellId]).toString();
    }).join('');
};

const applyParsedGrid = ({ parsedGrid, parseLevel, solve = false }) => {
    let alreadyMade = false;

    for (const cellId of cells) {
        if (!sudokuStore.predefinedValues[cellId]) {
            const cellHints = parsedGrid.get(cellId);

            if (cellHints.size > 1 || parseLevel === 0) {
                const transformedCellHints = new Array(10).fill(false);
                for (const hintId of [...cellHints]) {
                    transformedCellHints[hintId] = true;
                }

                if (!(sudokuStore.selectedValues[cellId] && sudokuStore.selectedValues[cellId] === sudokuStore.solutionValues[cellId])) {
                    sudokuStore.selectedValues[cellId] = 0;
                    sudokuStore.selectedHints[cellId] = transformedCellHints;
                }
            } else {
                if (!(sudokuStore.selectedValues[cellId] && sudokuStore.selectedValues[cellId] === sudokuStore.solutionValues[cellId])) {
                    sudokuStore.selectedHints[cellId] = new Array(10).fill(false);
                    const hintId = parseInt([...cellHints][0]);
                    sudokuStore.selectedValues[cellId] = hintId;
                    sudokuStore.valuesCount[hintId] += 1;

                    if (solve && !alreadyMade) {
                        sudokuStore.selectedValues[cellId] = 0;
                        sudokuStore.valuesCount[hintId] -= 1;
                        alreadyMade = true;
                    }
                }
            }
        }
    }

    void storageSet.values(sudokuStore.selectedValues);
    void storageSet.hints(sudokuStore.selectedHints);
};

const autofill = () => {
    parse(0);

    sudokuStore.grid.autofill = true;
    void storageSet.grid(sudokuStore.grid);
    sudokuStore.resetHistory();
    logger.trackEvent({ category: 'interaction', action: 'autofill'});
};

const parse = parseLevel => {
    const parsedGrid = parseRaw(getGridToParse(), parseLevel);
    applyParsedGrid({ parsedGrid, parseLevel });
};

const solve = () => {
    const parsedGrid = solveSudoku(getGridToParse());
    applyParsedGrid({ parsedGrid, solve: true });
};

const getLook = (unitIndex, unit) => {
    if (unitIndex < 9) {
        return $t('snackbar.hint.lookat.box', { 'box': unitIndex+1 });
    } else if (unitIndex < 18) {
        return $t('snackbar.hint.lookat.row', { 'row': [...unit][0][0] });
    } else {
        return $t('snackbar.hint.lookat.column', { 'column': [...unit][0][1] });
    }
};

const hints = () => {
    sudokuStore.hoveredCellId = '';
    sudokuStore.selectedCellId = '';

    const cellsWithWrongValues = sudokuStore.cellsWithWrongValues;

    if (cellsWithWrongValues.length) {
        const cellId = cellsWithWrongValues[utils.random(0, cellsWithWrongValues.length-1)];
        const hintId = sudokuStore.selectedValues[cellId];

        const cellsToAnimate = new Set();

        units[cellId].forEach(unit =>
            unit.filter(unitCellId => cellId !== unitCellId).forEach(cellsToAnimate.add, cellsToAnimate)
        );

        void showHintOnBoard({ cellId, hintId, cellsToAnimate, wrong: true });

        return showHintSnackbar({
            primaryMessage: $t('snackbar.hint.wrongvalue', { cell: cellId}),
            applyCallback: () => sudokuStore.updateCellValue({ cellId, hintId: 0 })
        });
    }

    const simpleNakedSingles = unitlist.flatMap((unit, unitIndex) => {
        const res = unit.filter(cellId => !(sudokuStore.selectedValues[cellId] || sudokuStore.predefinedValues[cellId]));

        return res.length === 1 ? [{
            cellId: res[0],
            hintId: sudokuStore.solutionValues[res[0]],
            unit,
            unitIndex
        }] : [];
    });

    if (simpleNakedSingles.length) {
        const {
            cellId,
            hintId,
            unit,
            unitIndex
        } = simpleNakedSingles.length === 1 ? simpleNakedSingles[0] : simpleNakedSingles[utils.random(0, simpleNakedSingles.length - 1)];

        void showHintOnBoard({
            cellId,
            hintId,
            cellsToAnimate: new Set(unit.filter(cellIdFiltered => cellIdFiltered !== cellId)),
            mark: !sudokuStore.selectedHints[cellId][hintId],
            highlightedCells: sudokuStore.selectedHints[cellId][hintId] ? [ cellId ] : []
        });

        return showHintSnackbar({
            primaryMessage: getLook(unitIndex, unit) + $t('snackbar.hint.nakedsingle.primary', {
                hint: hintId,
                cell: cellId
            }),
            secondaryMessage: $t('snackbar.hint.nakedsingle.nakedsingle'),
            applyCallback: () => sudokuStore.updateCellValue({ cellId, hintId })
        });
    }

    const { nakedSingles, hiddenSingles, hintsUnfilled, wrongHint, missingHint, nakedPair, nakedTriple, rowsPointingPairs, columnsPointingPairs, boxLineReductionRow, boxLineReductionColumn, nakedQuad, hiddenPair, hiddenTriple, hiddenQuad } = getHint({
        selectedValues: sudokuStore.selectedValues,
        selectedHints: sudokuStore.selectedHints,
        predefinedValues: sudokuStore.predefinedValues,
        solutionValues: sudokuStore.solutionValues
    });

    if (nakedSingles) {
        const keys = Array.from(nakedSingles.keys());
        let key = keys.length === 1 ? keys[0] : keys[utils.random(0, keys.length-1)];

        const
            cellId = key,
            {
                hintId,
                unitIndex,
                unit
            } = nakedSingles.get(key);

        sudokuStore.lastSelectedHintId = undefined;

        void showHintOnBoard({
            cellId,
            hintId: parseInt(hintId),
            cellsToAnimate: new Set(unit),
            mark: !sudokuStore.selectedHints[cellId][parseInt(hintId)],
            highlightedCells: sudokuStore.selectedHints[cellId][parseInt(hintId)] ? [ cellId ] : []
        });

        return showHintSnackbar({
            primaryMessage: getLook(unitIndex, unit) + $t('snackbar.hint.nakedsingle.primary', {
                hint: hintId,
                cell: cellId
            }),
            secondaryMessage: $t('snackbar.hint.nakedsingle.nakedsingle'),
            applyCallback: () => sudokuStore.updateCellValue({ cellId, hintId: parseInt(hintId) })
        });
    }

    if (hiddenSingles) {
        const
            keys = Array.from(hiddenSingles.keys()),
            key = keys.length === 1 ? keys[0] : keys[utils.random(0, keys.length-1)];

        const
            cellId = key,
            {
                hintId,
                unit,
                unitIndex
            } = hiddenSingles.get(key);

        void showHintOnBoard({
            cellId,
            hintId: parseInt(hintId),
            cellsToAnimate: new Set(unit),
            mark: !sudokuStore.selectedHints[cellId][parseInt(hintId)],
            highlightedCells: sudokuStore.selectedHints[cellId][parseInt(hintId)] ? [ cellId ] : []
        });

        return showHintSnackbar({
            primaryMessage: getLook(unitIndex, unit) + $t('snackbar.hint.hiddensingle.primary', {
                hint: hintId,
                cell: cellId
            }),
            secondaryMessage: $t('snackbar.hint.hiddensingle.hiddensingle'),
            applyCallback: () => sudokuStore.updateCellValue({ cellId, hintId: parseInt(hintId) })
        });
    }

    if (hintsUnfilled) {
        return showHintSnackbar({
            primaryMessage: $t('snackbar.hint.fillnotes.primary'),
            secondaryMessage: [
                $t('snackbar.hint.fillnotes.secondary[0]'),
                $t('snackbar.hint.fillnotes.secondary[1]')
            ],
            applyCaption: $t('snackbar.autofill.action'),
            applyCallback: autofill,
            noSettings: true
        });
    }

    if (wrongHint) {
        const {
            hintCellId,
            valueCellId,
            hint,
            unitIndex,
            unit
        } = wrongHint;

        void showHintOnBoard({ cellId: hintCellId, hintId: hint, cellsToAnimate: new Set(unit), highlightedCells: [ hintCellId ] });

        return showHintSnackbar({
            primaryMessage: getLook(unitIndex, unit) + $t('snackbar.hint.wrongnote.primary', {
                hint,
                valueCell: valueCellId,
                hintCell: hintCellId
            }),
            secondaryMessage: $t('snackbar.hint.wrongnote.wrongnote'),
            applyCallback: () => sudokuStore.updateCellHint({ cellId: hintCellId, hintId: hint })
        });
    }

    if (missingHint) {
        const {
            hintCellId,
            hint
        } = missingHint;

        void showHintOnBoard({ cellId: hintCellId, hintId: hint, highlightedCells: [ hintCellId ], cellsToAnimate: new Set(units[hintCellId].flat()) });

        return showHintSnackbar({
            primaryMessage: $t('snackbar.hint.missingnote.primary', {
                hintCell: hintCellId,
                hint
            }),
            secondaryMessage: $t('snackbar.hint.missingnote.missingnote'),
            applyCallback: () => sudokuStore.updateCellHint({ cellId: hintCellId, hintId: hint })
        });
    }

    // vd59 - nakedQuad
    if (nakedPair || nakedTriple || nakedQuad) {
        const
            naked = nakedPair || nakedTriple || nakedQuad,
            cellsWithNaked = [ ...naked.cells ];

        void showHintOnBoard({
            hintId: naked.hintId,
            cellsToAnimate: new Set([...naked.unit]),
            highlightedCells: cellsWithNaked
        });

        return showHintSnackbar({
            primaryMessage: getLook(naked.unitIndex, naked.unit) + $t('snackbar.hint.naked.primary', {
                naked: $t('snackbar.hint.naked.' + (nakedPair ? 'pair' : nakedTriple ? 'triple' : 'quad')),
                cells: cellsWithNaked.sort().join(' & ')
            }),
            secondaryMessage: $t('snackbar.hint.naked.secondary'),
            applyCallback: () => {
                naked.unit.filter(cellId => !sudokuStore.predefinedValues[cellId] && !sudokuStore.selectedValues[cellId] && !cellsWithNaked.includes(cellId)).forEach(cellId => {
                    sudokuStore.selectedHints[cellId].map((isHintSelected, index) => {
                        if (isHintSelected && naked.hints.has(index.toString())) {
                            sudokuStore.updateCellHint({ cellId, hintId: index});
                        }
                    });
                });
            }
        });
    }

    if (rowsPointingPairs || columnsPointingPairs) {
        const
            pointingPair = rowsPointingPairs || columnsPointingPairs,
            cellsWithPointingPair = [ ...pointingPair.cellsWithHints ];

        void showHintOnBoard({
            hintId: parseInt(pointingPair.pointingHint),
            highlightedCells: cellsWithPointingPair,
            cellsToAnimate: new Set([...pointingPair.pointingUnit, ...pointingPair.unitWithHintsToRemove]),
        });

        return showHintSnackbar({
            primaryMessage: $t('snackbar.hint.pointing.primary', { box: pointingPair.pointingUnitIndex+1, hint: pointingPair.pointingHint, cells: [...pointingPair.cellsWithHints].sort().join(' & '), cell: pointingPair.cellToEliminate}),
            secondaryMessage: $t('snackbar.hint.pointing.' + (rowsPointingPairs ? 'row' : 'column')),
            applyCallback: () => {
                pointingPair.unitWithHintsToRemove.filter(cellId => !sudokuStore.predefinedValues[cellId] && !sudokuStore.selectedValues[cellId] && !cellsWithPointingPair.includes(cellId)).forEach(cellId => {
                    sudokuStore.selectedHints[cellId].map((isHintSelected, index) => {
                        if (isHintSelected && parseInt(pointingPair.pointingHint) === index) {
                            sudokuStore.updateCellHint({ cellId, hintId: index});
                        }
                    });
                });
            }
        });
    }

    // SELECT * FROM grids WHERE strategy->>'level' = '7' LIMIT 1;
    // gnek6 - boxLineReductionRow
    // bd37 - boxLineReductionColumn
    if (boxLineReductionRow || boxLineReductionColumn) {
        const {
            pointingHint,
            pointingCells,
            pointingUnit,
            boxIndex,
            box,
            cellToEliminate
        } = (boxLineReductionRow || boxLineReductionColumn);

        const
            unitType = boxLineReductionRow ? 'row' : 'column',
            unitTypeIndex = boxLineReductionRow ? [...pointingCells][0][0] : [...pointingCells][0][1],
            cellsWithPointing = [...pointingCells];

        void showHintOnBoard({
            hintId: parseInt(pointingHint),
            highlightedCells: cellsWithPointing,
            cellsToAnimate: new Set([...pointingUnit, ...box])
        });

        return showHintSnackbar({
            primaryMessage: $t('snackbar.hint.lookat.' + unitType, {
                row: unitTypeIndex,
                column: unitTypeIndex
            }) + $t('snackbar.hint.boxline.primary', {
                hint: pointingHint,
                cells: [...pointingCells].join(' & '),
                box: boxIndex + 1,
                cell: cellToEliminate
            }),
            secondaryMessage: $t('snackbar.hint.boxline.' + unitType),
            applyCallback: () => {
                box.filter(cellId => !sudokuStore.predefinedValues[cellId] && !sudokuStore.selectedValues[cellId] && !cellsWithPointing.includes(cellId)).forEach(cellId => {
                    sudokuStore.selectedHints[cellId].map((isHintSelected, index) => {
                        if (isHintSelected && parseInt(pointingHint) === index) {
                            sudokuStore.updateCellHint({ cellId, hintId: index});
                        }
                    });
                });
            }
        });
    }

    // 6vk2 - hiddenPair (total 193)
    // #dr78d - hiddenTriple (total 1)
    if (hiddenPair || hiddenTriple || hiddenQuad) {
        const
            hidden = hiddenPair || hiddenTriple || hiddenQuad,
            cellsWithHidden = [...hidden.cells];

        void showHintOnBoard({
            cellsToAnimate: new Set([...hidden.unit]),
            highlightedCells: cellsWithHidden
        });

        return showHintSnackbar({
            primaryMessage: getLook(hidden.unitIndex, hidden.unit) + $t('snackbar.hint.hidden.primary', {
                hints: [...hidden.hints].sort().join(' & '),
                cells: [...hidden.cells].sort().join(' & ')
            }),
            secondaryMessage: [
                $t('snackbar.hint.hidden.secondary'),
                $t('snackbar.hint.hidden.' + (hiddenPair ? 'pair' : hiddenTriple ? 'triple' : 'quad'))
            ],
            applyCallback: () => {
                cellsWithHidden.forEach(cellId => {
                    sudokuStore.selectedHints[cellId].map((isHintSelected, index) => {
                        if (isHintSelected && !hidden.hints.has(index.toString())) {
                            sudokuStore.updateCellHint({ cellId, hintId: index});
                        }
                    });
                });
            }
        });

    }

    return showHintSnackbar({
        primaryMessage: $t('snackbar.hint.nomorehints'),
        secondaryMessage: [ $t('snackbar.expert[1]', {
            url: '<a onclick="event.stopPropagation()" href="https://www.sudokuwiki.org/Strategy_Families" target="_blank" class="thesudokuapp-link">sudokuwiki.org</a>'
        }) ],
        noSettings: true
    });
};

const smartHint = () => {
    hints();

    sudokuStore.grid.smarthints++;
    void storageSet.grid(sudokuStore.grid);
    logger.trackEvent({ category: 'interaction', action: 'smarthint'});
};

const simpleHint = () => {
    if (gameplaySettingsStore.numberFirst) {
        return snackbarStore.showSnackbar({
            primaryMessage: $t('snackbar.hint.simplehint.numbersfirstoff', { mode: $t('settings.numberFirst') }),
            timeout: 1750,
            noSettings: true
        });
    }

    if (!sudokuStore.selectedCellId || sudokuStore.predefinedValues[sudokuStore.selectedCellId]) {
        return snackbarStore.showSnackbar({
            primaryMessage: $t('snackbar.hint.simplehint.' + (!sudokuStore.selectedCellId ? 'selectcell' : 'nopredefinedcell')),
            timeout: 1750,
            noSettings: true
        });
    }

    if (sudokuStore.selectedValues[sudokuStore.selectedCellId] === sudokuStore.solutionValues[sudokuStore.selectedCellId]) {
        return snackbarStore.showSnackbar({
            primaryMessage: $t('snackbar.hint.simplehint.alreadyhas', {
                cell: sudokuStore.selectedCellId,
                hint: sudokuStore.solutionValues[sudokuStore.selectedCellId]
            }),
            timeout: 1750,
            noSettings: true
        });

    }

    const hintId = sudokuStore.solutionValues[sudokuStore.selectedCellId];
    const cellId = sudokuStore.selectedCellId;

    const cellsToAnimate = new Set();

    units[cellId].forEach((unit) => {
        const filteredUnit = unit.filter(unitCellId => cellId !== unitCellId && (sudokuStore.solutionValues[unitCellId] || sudokuStore.predefinedValues[unitCellId]));

        if (filteredUnit) {
            filteredUnit.forEach(cellsToAnimate.add, cellsToAnimate);
        }
    });

    void showHintOnBoard({ cellId, hintId, cellsToAnimate, mark: true });

    showHintSnackbar({
        primaryMessage: $t('snackbar.hint.simplehint.primary', {
            cell: cellId,
            hint: hintId
        }),
        applyCallback: () => sudokuStore.updateCellValue({ cellId, hintId })
    });

    sudokuStore.grid.simplehints++;
    void storageSet.grid(sudokuStore.grid);
    logger.trackEvent({ category: 'interaction', action: 'simplehint'});
};

const resetHint = async () => {
    if (!sudokuStore.smartHint.on) {
        return;
    }

    if (sudokuStore.smartHint.animeCallback) {
        const cleanupElementStyles = await sudokuStore.smartHint.animeCallback;
        cleanupElementStyles();
    }

    sudokuStore.smartHint = {
        on: false,
        cellId: null,
        hintId: null,
        animeCallback: null,
        wrong: false,
        mark: false,
        highlightedCells: []
    };

};

const showHintSnackbar = ({ primaryMessage, secondaryMessage, applyCaption, applyCallback, noSettings = false }) => {
    let secondaryActionCaption, secondaryActionCallback, actionCaption, actionCallback;

    if (!noSettings && !gameplaySettingsStore.showLabels) {
        if (!utils.isArray(secondaryMessage)) {
            secondaryMessage = [ secondaryMessage ];
        }

        secondaryMessage.push($t('snackbar.hint.gridlabelswarning'));
        secondaryActionCaption = $t('action.settings');
        secondaryActionCallback = () => uiStore.switchUIState({ type: 'settings', newState: true });
    }

    if (applyCallback) {
        actionCaption = applyCaption || $t('snackbar.action.apply');
        actionCallback = () => {
            applyCallback();
            void resetHint();
        };
    }

    snackbarStore.showSnackbar({
        primaryMessage,
        secondaryMessage,
        actionCaption,
        actionCallback,
        secondaryActionCaption,
        secondaryActionCallback
    });
};

const showHintOnBoard = async ({ hintId, cellId, cellsToAnimate, wrong = false, mark = false, highlightedCells = [] } = {}) => {
    await resetHint();

    sudokuStore.lastSelectedHintId = hintId;

    if (cellsToAnimate) {
        const animeCallback = animateSmartHint({
            cellId,
            highlight: window.thesudokuapp.themes[uiStore.theme]['cssvariables']['smarthint-background'],
            cellsToAnimate
        });

        sudokuStore.smartHint = {
            on: true,
            cellId,
            hintId,
            wrong,
            mark,
            highlightedCells,
            animeCallback: markRaw(animeCallback)
        };
    }
};

export { autofill, parse, solve, smartHint, hints, simpleHint, resetHint };
