import { defineStore } from 'pinia';
import { logger } from '@/services/thesudokuapp-logger';
import { uiStore} from '@/App/stores/index';
import { $t } from '@/services/thesudokuapp-i18n';
import { markRaw } from 'vue';
import { storageSet } from '@/services/thesudokuapp-storage';

let timeoutId = null;

const getEmptySnackbar = () => ({
    showLogo: false,
    primaryMessage: null,
    secondaryMessage: [],
    actionCaption: null,
    actionCallback: null,
    secondaryActionCaption: null,
    secondaryActionCallback: null,
    dismissCaption: $t('snackbar.action.dismiss'), // Ocr-Captuire "Try again" to restart stream
    dismissCallback: null,
    isWarning: false, // apiAsyncCatch
    notificationId: null,
    showDontshow: false,
    dontshowCaption: $t('snackbar.action.dontshowagain'),
    preventHideOnAction: false, // Нам нужен если в actionCallback или secondaryActionCallback будет показан snackbar
    currentDontShowStatus: false,
    id: null,
    timeout: null,
    /**
     * @see thesudokuapp-sudokuboot.js rootInit()
     */
    showDismiss: true
});

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        snackbar: getEmptySnackbar(),
        notifications: {
            pwaappinstall: false,
            autofill: false,
            smarthint: false,
            simplehint: false,
            expert: false,
            ocrhowto: false
        }
    }),
    actions: {
        /**
         * @param {
         *     {
         *         primaryMessage: string,
         *         secondaryMessage?: string | string[]
         *         actionCaption?: string,
         *         secondaryActionCaption?: string,
         *         dismissCaption?: string,
         *         showDismiss?: boolean,
         *         isWarning?: boolean
         *     }
         * } options
         * @returns {Promise<'action'|'secondaryAction'|'dismiss'|false>}
         */
        showAsyncSnackbar(options) {
            const newSnackbarData = {
                ...getEmptySnackbar(),
                ...options
            };

            if (uiStore.snackbar) {
                this.hideSnackbar();
            }

            if (newSnackbarData.showDontshow && newSnackbarData.notificationId && this.notifications[newSnackbarData.notificationId] === true) {
                return Promise.resolve('action');
            }

            if (newSnackbarData.secondaryMessage && !Array.isArray(newSnackbarData.secondaryMessage)) {
                newSnackbarData.secondaryMessage = [ newSnackbarData.secondaryMessage ];
            }

            newSnackbarData.id = Date.now();

            return new Promise(resolve => {
                if (newSnackbarData.actionCaption) {
                    newSnackbarData.actionCallback = markRaw(() => {
                        resolve('action');
                    });
                }

                if (newSnackbarData.secondaryActionCaption) {
                    newSnackbarData.secondaryActionCallback = markRaw(() => {
                        resolve('secondaryAction');
                    });
                }

                newSnackbarData.dismissCallback = markRaw(() => {
                    resolve('dismiss');
                });

                newSnackbarData.hideSnackbarCallback = markRaw(() => {
                    resolve(false);
                });

                this.snackbar = newSnackbarData;
                uiStore.switchUIState({ type: 'snackbar', newState: true });
            });
        },
        /**
         * @param options
         * @returns {void}
         */
        showSnackbar(options = {}) {
            const newSnackbarData = {
                ...getEmptySnackbar(),
                ...options,
                actionCallback: options.actionCallback ? markRaw(options.actionCallback) : null,
                secondaryActionCallback: options.secondaryActionCallback ? markRaw(options.secondaryActionCallback) : null
            };

            if (uiStore.snackbar) {
                this.hideSnackbar();
            }

            if (newSnackbarData.showDontshow && newSnackbarData.notificationId && this.notifications[newSnackbarData.notificationId] === true) {
                if (newSnackbarData.actionCallback) {
                    newSnackbarData.actionCallback();
                }

                return;
            }

            if (newSnackbarData.secondaryMessage && !Array.isArray(newSnackbarData.secondaryMessage)) {
                newSnackbarData.secondaryMessage = [ newSnackbarData.secondaryMessage ];
            }

            if (newSnackbarData.timeout) {
                timeoutId = setTimeout(() => this.hideSnackbar(), newSnackbarData.timeout);
            }

            newSnackbarData.id = Date.now();

            this.snackbar = newSnackbarData;
            uiStore.switchUIState({ type: 'snackbar', newState: true });
        },
        hideSnackbar() {
            if (!uiStore.snackbar) {
                return;
            }

            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }

            if (this.snackbar.showDontshow && this.snackbar.notificationId) {
                logger.trackEvent({
                    category: 'notifications',
                    action: 'shown: ' + this.snackbar.notificationId
                });

                if (this.snackbar.currentDontShowStatus) {
                    this.notifications[this.snackbar.notificationId] = true;
                    void storageSet.notifications(this.notifications);
                    logger.trackEvent({
                        category: 'notifications',
                        action: 'dontshow: ' + this.snackbar.notificationId
                    });
                }
            }

            uiStore.switchUIState({ type: 'snackbar', newState: false });

            // Called only for async snackbars and resolved with false
            if (this.snackbar.hideSnackbarCallback) {
                this.snackbar.hideSnackbarCallback();
            }
        }
    }
});
