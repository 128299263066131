// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sudoku-finished-message {
  background-color: var(--dark-background-transparent);
  border-radius: 1vmin;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 0.02rem 0.08rem 0.7vmin 0 rgba(0, 0, 0, 0.7);
  color: var(--accent-color-tetrad3);
  font-size: 0.75rem;
  line-height: 0.8rem;
}
.sudoku-finished-message .thesudokuapp-actionbutton {
  margin-top: 0.5rem;
  font-size: 0.5rem;
  text-transform: none;
  padding: 0.16rem 0.3rem;
}
.sudoku-finished-message .sudoku-finished-description {
  text-align: left;
}
.sudoku-finished-message .sudoku-finished-description-secondary {
  text-align: left;
  font-size: 0.5em;
  line-height: 1.2em;
}
.sudoku-finished-message .sudoku-finished-description-secondary:nth-of-type(2) {
  margin-top: 1em;
}
.sudoku-finished-message .svgbutton-trophy {
  height: 1em;
  margin-right: 0.5rem;
  font-size: 2rem;
  color: var(--accent-color-tetrad3);
}
.sudoku-finished-message .sudoku-finished-buttons {
  display: flex;
  justify-content: center;
}
.sudoku-finished-message .sudoku-finished-buttons .sudoku-text-button:not(:last-child) {
  margin-right: 2vmin;
}`, "",{"version":3,"sources":["webpack://./App/components/GameFinish.vue"],"names":[],"mappings":"AACI;EACI,oDAAA;EACA,oBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;EACA,wDAAA;EACA,kCAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAEQ;EACI,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,uBAAA;AAAZ;AAGQ;EACI,gBAAA;AADZ;AAIQ;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;AAFZ;AAIY;EACI,eAAA;AAFhB;AAMQ;EAEI,WAAA;EACA,oBAAA;EACA,eAAA;EACA,kCAAA;AALZ;AAQQ;EACI,aAAA;EACA,uBAAA;AANZ;AAQY;EACI,mBAAA;AANhB","sourcesContent":["\n    .sudoku-finished-message {\n        background-color: var(--dark-background-transparent);\n        border-radius: 1vmin;\n        padding: 0.7rem;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        z-index: 4;\n        user-select: none;\n        box-shadow: 0.02rem 0.08rem 0.7vmin 0 rgba(0, 0, 0, 0.7);\n        color: var(--accent-color-tetrad3);\n        font-size: 0.75rem;\n        line-height: 0.8rem;\n\n        .thesudokuapp-actionbutton {\n            margin-top: 0.5rem;\n            font-size: 0.5rem;\n            text-transform: none;\n            padding: 0.16rem 0.3rem;\n        }\n\n        .sudoku-finished-description {\n            text-align: left;\n        }\n\n        .sudoku-finished-description-secondary {\n            text-align: left;\n            font-size: 0.5em;\n            line-height: 1.2em;\n\n            &:nth-of-type(2) {\n                margin-top: 1em;\n            }\n        }\n\n        .svgbutton-trophy {\n            //width: 1em;\n            height: 1em;\n            margin-right: 0.5rem;\n            font-size: 2rem;\n            color: var(--accent-color-tetrad3);\n        }\n\n        .sudoku-finished-buttons {\n            display: flex;\n            justify-content: center;\n\n            .sudoku-text-button:not(:last-child) {\n                margin-right: 2vmin;\n            }\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
