import { gameplaySettingsStore, uiStore } from '@/App/stores';
import { playSound } from '@/services/thesudokuapp-sound';

const patchStoresFromBrowserMode = async () => {
    uiStore.theme = document.documentElement.dataset.theme;
    //user_pref("ui.prefersReducedMotion",1);
    uiStore.reduceMotion = document.documentElement.dataset.reducedmotion;

    // dom.webaudio.enabled in about:config
    // user_pref("dom.webaudio.enabled", false); in user.js
    const soundOn = await playSound('selectcell', true);

    if (soundOn) {
        await playSound('deselectcell', true);
    } else {
        gameplaySettingsStore.sound = false;
        uiStore.soundDisabled = true;
    }

    //window.print = undefined; теоретически в GreaseMonkey так можно сделать, так что оставим проверку
    if (!window.print) {
        uiStore.printDisabled = true;
    }

    const finePointer = matchMedia('(hover: hover) and (pointer: fine)').matches;
    if (!finePointer) {
        uiStore.touchMode = true;
        gameplaySettingsStore.showNumpad = true;
        gameplaySettingsStore.showInCellValuePicker = false;
    }

    if (!navigator.clipboard) {
        uiStore.clipboardDisabled = true;
    }

    if (!document.documentElement.dataset.shareallowed) {
        uiStore.shareDisabled = true;
    }

    if (document.documentElement.dataset.webkitfullscreenallowed) {
        uiStore.fullscreenDisabled = true;
        uiStore.webkitFullscreenDisabled = false;
    }

    if (!document.documentElement.dataset.fullscreenallowed) {
        uiStore.fullscreenDisabled = true;
    }

    if (window.screen.orientation && window.screen.orientation.lock && window.screen.orientation.unlock) {
        try {
            await window.screen.orientation.lock('any');
        } catch (e) {
            //api.telegram(e.toString());
            if (/fullscreen/i.test(e.toString())){
                uiStore.orientationLockDisabled = false;
            }
        }
    }
};

export { patchStoresFromBrowserMode };
