// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html:fullscreen #thesudokuapp-rotatelock-container {
  display: block;
}
#rotation-lock-button {
  width: 0.76rem;
  height: 0.76rem;
  margin-right: 0.25rem;
  line-height: 0;
}
#rotation-lock-button svg {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./App/components/RotationLockButton.vue"],"names":[],"mappings":"AAEQ;EACI,cAAA;AADZ;AAKI;EACI,cAAA;EACA,eAAA;EACA,qBAAA;EACA,cAAA;AAFR;AAIQ;EACI,WAAA;EACA,YAAA;AAFZ","sourcesContent":["\n    html:fullscreen {\n        #thesudokuapp-rotatelock-container {\n            display: block;\n        }\n    }\n\n    #rotation-lock-button {\n        width: 0.76rem;\n        height: 0.76rem;\n        margin-right: 0.25rem;\n        line-height: 0;\n\n        svg {\n            width: 100%;\n            height: 100%;\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
