<template>
    <button class="stopwatch-container" :disabled="gameControlsDisabled && !stopwatchStore.stopwatchOnAndPaused" aria-label="toggle stopwatch" @click.stop="toggleStopwatch">
        <svg-icon icon="clock" class="stopwatch-svg" />
        <span v-if="stopwatchStore.stopWatchState.days">{{ stopwatchStore.stopWatchState.days }}d&nbsp;</span>
        <span v-if="stopwatchStore.stopWatchState.hours">{{ stopwatchStore.stopWatchState.hours }}h&nbsp;</span>
        <span class="stopwatch-timer">
            <span>{{ stopwatchStore.stopWatchState.minutes ? stopwatchStore.stopWatchState.minutes.toString().padStart(2, '0') : '00' }}:</span>
            <span>{{ stopwatchStore.stopWatchState.seconds.toString().padStart(2, '0') }}</span>
        </span>
    </button>
</template>

<script>
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { gameplaySettingsStore, stopwatchStore, uiStore } from '@/App/stores';

    export default {
        components: {
            svgIcon: SvgIcon
        },
        computed: {
            gameControlsDisabled() {
                return uiStore.gameControlsDisabled;
            },
            stopwatchStore() {
                return stopwatchStore;
            }
        },
        mounted() {
            document.addEventListener('visibilitychange', this.onVisibilityChange);
            window.addEventListener('blur', this.onBlur);
            window.addEventListener('focus', this.onFocus);
        },
        unmounted() {
            document.removeEventListener('visibilitychange', this.onVisibilityChange);
            window.removeEventListener('blur', this.onBlur);
            window.removeEventListener('focus', this.onFocus);
        },
        methods: {
            toggleStopwatch: stopwatchStore.toggleStopwatch,
            onVisibilityChange() {
                if (document.visibilityState === 'hidden' && !(gameplaySettingsStore.showStopwatch && !gameplaySettingsStore.autopause)) {
                    this.stopwatchStore.pauseStopwatch();
                } else {
                    /**
                     * @see uiStore.switchUIState()
                     */
                    if (!gameplaySettingsStore.showStopwatch) {
                        this.stopwatchStore.resumeStopwatch();
                    }
                }
            },
            onBlur() {
                if (!(gameplaySettingsStore.showStopwatch && !gameplaySettingsStore.autopause)) {
                    this.stopwatchStore.pauseStopwatch();
                }
            },
            onFocus() {
                if (!gameplaySettingsStore.showStopwatch) {
                    this.stopwatchStore.resumeStopwatch();
                }
            }
        }
    };
</script>

<style lang="scss">
    .stopwatch-container {
        display: flex;
        align-items: center;
        align-self: center;
        margin-right: auto;

        &:disabled {
            opacity: 0.5;
        }

        .svgbutton-play {
            font-size: 0.6rem;
            // Safari 9 bug
            // https://github.com/jgthms/bulma/issues/727
            margin-top: auto;
            margin-bottom: auto;
        }

        .svgbutton-pause {
            font-size: 0.68rem;
            // Safari 9 bug
            // https://github.com/jgthms/bulma/issues/727
            margin-top: auto;
            margin-bottom: auto;
        }

        .stopwatch-svg {
            width: 1.1em;
            height: 1.1em;
            margin-right: 0.3em;
            margin-top: 0.1em;
            //padding-top: 0.12em;
            //margin-top: 0.11em;
        }
    }

    .stopwatch-timer {
        display: flex;
        margin-right: auto;
    }

</style>
