'use strict';

const sound = {
    selectcell: {
        url: '/assets/sound/selectcell.mp3',
        buffer: null,
        source: null
    },
    deselectcell: {
        url: '/assets/sound/deselectcell.mp3',
        buffer: null,
        source: null
    },
    newvalue: {
        url: '/assets/sound/newvalue.mp3',
        buffer: null,
        source: null
    },
    newgame: {
        url: '/assets/sound/newgame.mp3',
        buffer: null,
        source: null
    },
    unitfilled: {
        url: '/assets/sound/unitfilled.mp3',
        buffer: null,
        source: null
    },
    solved: {
        url: '/assets/sound/solved.mp3',
        buffer: null,
        source: null
    },
    mistakes: {
        url: '/assets/sound/mistakes.mp3',
        buffer: null,
        source: null
    },
    camerashutter: {
        url: '/assets/sound/camerashutter.mp3',
        buffer: null,
        source: null
    }
};

let
    audioContext = null,
    soundOk = true;

/*
const initSound = errorCallback => {
    if (audioContext) {
        return;
    }

    if ('AudioContext' in window) {
        audioContext = new window.AudioContext();
    } else if ('webkitAudioContext' in window) {
        audioContext = new window.webkitAudioContext();
    }

    if (audioContext) {
        Promise.all(
            Object.keys(sound).map(soundType => {
                return fetch(sound[soundType].url)
                    .then(res => res.arrayBuffer())
                    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer, buffer => sound[soundType].buffer = buffer));
                    // Fucking IOS doesn't understand decodeAudioData promise
                    //.then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
                    //.then(res => sound[soundType].buffer = res);
            })
        ).catch(() => errorCallback());
    } else {
        errorCallback();
    }
};


const playSound = soundType => {
    if (!audioContext || !(soundType in sound) || !(sound[soundType].buffer)) {
        return;
    }

    const source = audioContext.createBufferSource();
    source.buffer = sound[soundType].buffer;
    source.connect(audioContext.destination);

    //audioContext.resume().then(() => source.start());
    source.start();
};*/

const fetchSound = async soundType => {
    return fetch(sound[soundType].url)
        .then(res => res.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer, buffer => sound[soundType].buffer = buffer))
        .catch(() => soundOk = false);
};

const playSound = async (soundType, prefetch) => {
    if (!soundOk) {
        return false;
    }

    //console.log('soundOk', soundOk);
    if (!sound[soundType] || !soundOk) {
        return false;
    }

    if (!audioContext) {
        if ('AudioContext' in window) {
            audioContext = new window.AudioContext();
        } else if ('webkitAudioContext' in window) {
            audioContext = new window.webkitAudioContext();
        }
    }

    if (!audioContext) {
        return false;
    }

    if (!sound[soundType].buffer) {
        await fetchSound(soundType);
    }

    /*
    if (!sound[soundType].source) {
        sound[soundType].source = audioContext.createBufferSource();
        sound[soundType].source.buffer = sound[soundType].buffer;
    }*/

    if (prefetch) {
        return true;
    }

    await audioContext.resume();

    //sound[soundType].source.connect(audioContext.destination);
    //sound[soundType].source.start(0);

    const source = audioContext.createBufferSource();
    source.buffer = sound[soundType].buffer;
    source.connect(audioContext.destination);
    source.start(0);

    return true;
};

export { playSound };
