<template>
    <div
        class="thesudokuapp-numpad"
        :class="{ 'numpad-with-counter': showValuesCounterOnNumpad, 'thesudokuapp-second-numpad': secondNumpad }"
    >
        <!--suppress JSUnresolvedReference -->
        <button
            v-for="i in 9"
            :key="i"
            class="thesudokuapp-numpad-button"
            :class="{ 'active': hintModeOn && selectedCellId && (!showSecondNumpad || secondNumpad) && activeHints.includes(i) }"
            :disabled="gameControlsDisabled || (checkNewValues && highlightSameValues && valuesCount[i] === 9)"
            :aria-label="'Numpad button ' + i"
            @click.stop="updateCell(i)"
        >
            <span class="sudoku-button-safari-bug">{{ i }}</span>
            <svg v-if="selectedFirstNumber === i" class="number-first-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path id="thesudokuapp-call-made" fill="currentColor" d="M10,0v4h13.2L0,27.2L2.8,30L26,6.8V20h4V0H10z" />
            </svg>
            <span
                v-if="showValuesCounterOnNumpad && (!showSecondNumpad || ((showSecondNumpad && !secondNumpad)))"
                class="sudoku-button-counter"
                :class="{ 'counter-ok': valuesCount[i] === 9, 'over-filled': valuesCount[i] > 9 }"
                v-html="valuesCount[i] === 9 ? '<svg class=\'svg-done\' xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 48 48\'><path d=\'M18 32.34L9.66 24l-2.83 2.83L18 38l24-24-2.83-2.83z\'/></svg>' : valuesCount[i]"
            />
        </button>
    </div>
</template>

<script>
    import { uiStore, sudokuStore, gameplaySettingsStore } from '@/App/stores';

    // noinspection JSUnusedGlobalSymbols
    export default {
        props: {
            secondNumpad: {
                type: Boolean,
                required: true
            },
            showSecondNumpad: {
                type: Boolean,
                required: true
            },
            showValuesCounterOnNumpad: {
                type: Boolean,
                required: true
            },
            numberFirst: {
                type: Boolean,
                required: true
            },
            highlightSameValues: {
                type: Boolean,
                required: true
            },
            checkNewValues: {
                type: Boolean,
                required: true
            },
            gameControlsDisabled: {
                type: Boolean,
                required: true
            },
            hintModeOn: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            selectedCellId() {
                return sudokuStore.selectedCellId;
            },
            valuesCount() {
                return sudokuStore.valuesCount;
            },
            selectedFirstNumber() {
                if (!this.numberFirst || this.gameControlsDisabled) {
                    return false;
                }

                if (uiStore.selectedNumberFirst.hint) {
                    if (this.secondNumpad) {
                        return uiStore.selectedNumberFirst.hint;
                    }

                    if (!this.showSecondNumpad && this.hintModeOn) {
                        return uiStore.selectedNumberFirst.hint;
                    }
                }

                if (uiStore.selectedNumberFirst.value) {
                    if (!this.secondNumpad) {
                        return uiStore.selectedNumberFirst.value;
                    }

                    if (!this.showSecondNumpad && !this.hintModeOn) {
                        return uiStore.selectedNumberFirst.value;
                    }
                }

                return false;
            },
            /**
             * @returns {number[]}
             */
            activeHints() {
                if (!sudokuStore.selectedHints[sudokuStore.selectedCellId]) {
                    return [];
                }

                return sudokuStore.selectedHints[sudokuStore.selectedCellId].map((v, i) => v ? i : 0).filter(Boolean);
            }
        },
        methods: {
            updateCell(hintId) {
                if (this.gameControlsDisabled) {
                    return;
                }

                uiStore.switchUIState();

                sudokuStore.hoveredCellId = '';

                if (this.numberFirst) {
                    uiStore.selectNumberFirst({
                        hint: (
                            (this.showSecondNumpad && this.secondNumpad)
                            || (!this.showSecondNumpad && this.hintModeOn)
                        ) ? hintId : 0,
                        value: (
                            (this.showSecondNumpad && !this.secondNumpad)
                            || (!this.showSecondNumpad && !this.hintModeOn)
                        ) ? hintId : 0,
                    });

                    return;
                }

                if (!sudokuStore.selectedCellId
                    || sudokuStore.predefinedValues[sudokuStore.selectedCellId]
                    || (gameplaySettingsStore.checkNewValues && sudokuStore.selectedValues[sudokuStore.selectedCellId])
                ) {
                    sudokuStore.lastSelectedHintId = (sudokuStore.lastSelectedHintId === hintId) ? 0 : hintId;
                    return;
                }

                if (
                    (this.showSecondNumpad && !this.secondNumpad)
                    || (!this.showSecondNumpad && !this.hintModeOn)
                ) {
                    sudokuStore.updateCellValue({ cellId: sudokuStore.selectedCellId, hintId });
                } else {
                    sudokuStore.updateCellHint({ cellId: sudokuStore.selectedCellId, hintId });
                }
            }
        }
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-scssvars' as *;

    .thesudokuapp-numpad {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        font-size: 0.8rem;
        user-select: none;
        position: relative;
        width: var(--board-size);
    }

    .thesudokuapp-numpad-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--cell-background-color);
        width: calc(100% / 10);
        height: 1.9rem;
        border: 1px solid var(--thin-grid-line-color);
        position: relative;
        font-size: 1.2rem;
        border-radius: 0.36rem 0.1rem;

        //box-shadow: 0 0 0.3rem -0.1rem var(--thin-grid-line-color);
        //font-weight: bold;
        //transition: all 60ms ease-out;

        overflow: visible; // IE Fix

        &.active {
            background-color: var(--accent-color);
            color: var(--dark-background-font-color);
            border-color: var(--accent-color);
        }

        &:disabled {
            cursor: default;
        }

        &:active:not(:disabled) {
            border-color: var(--accent-color-lighter);
            background: var(--accent-color-lighter);
            color: var(--dark-background-font-color);
            box-shadow: none;

            .sudoku-button-counter:not(.over-filled) {
                border-color: var(--accent-color-lighter);
            }

            /*
            .sudoku-button-counter:not(.counter-ok):not(.over-filled) {
                //background-color: var(--accent-color-lighter);
            }*/
        }
    }

    .sudoku-button-counter { // В firefox клик на выступающем за пределы кнопки элементе не работает, можно когда-нибудь поправить
        font-size: 0.4rem;
        font-weight: initial;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 0.1rem;

        &.counter-ok {
            font-size: 0.5rem;
            color: var(--success-color);
            padding-right: initial;
        }

        &.over-filled {
            //background-color: var(--danger-color);
            //border-color: var(--danger-color);
            //font-size: 0.35rem;
            color: var(--danger-color);
        }

        .svg-done {
            width: 1.1em;
            height: 1.1em;
            fill: currentColor;
        }
    }

    .number-first-svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0.3rem;
        height: 0.3rem;
        //color: var(--success-color);
        transform-origin: left bottom;
        animation-name: stretch;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    @keyframes stretch {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.4);
        }
    }

    @media #{$thesudokuapp-portrait-tablet}, #{$thesudokuapp-portrait-tablet-ipad} {
        .with-secondnumpad {
            .thesudokuapp-numpad {
                flex-wrap: wrap;
                width: 33%;
                height: calc(var(--board-size) / 3 * 1.1);
                align-items: center;
                margin-bottom: 0;

                .thesudokuapp-numpad-button {
                    width: 30%;
                    height: 29%;
                }
            }
        }
    }

    @media #{$thesudokuapp-landscape} {
        .thesudokuapp-numpad {
            width: 100%;
            height: calc(var(--numpad-width)*1.2);
            flex-wrap: wrap;
            margin-bottom: 0.5rem;
            margin-top: auto;

            .thesudokuapp-numpad-button {
                width: 29%;
                margin-left: 4.3%;
                height: 30%;
            }
        }

        .with-secondnumpad {
            .thesudokuapp-numpad {
                margin-top: 0;
                margin-bottom: 0;
                height: calc(var(--numpad-width)*1.04);
            }
        }
    }
</style>
