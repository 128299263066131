<template>
    <button id="rotation-lock-button" @click="uiStore.toggleRotationLock">
        <svg-icon v-if="uiStore.orientationLock === 'portrait'" id="rotation-locked" icon="rotationunlocked" class="thesudokuapp-svg" />
        <svg-icon v-else icon="rotationlocked" class="thesudokuapp-svg" id="rotation-unlocked" />
    </button>
</template>

<script>
import { uiStore } from '@/App/stores';
import SvgIcon from '@/App/components/SvgIcon.vue';

export default {
    components: {
        svgIcon: SvgIcon
    },
    computed: {
        uiStore() {
            return uiStore;
        }
    }
};
</script>

<style lang="scss">
    html:fullscreen {
        #thesudokuapp-rotatelock-container {
            display: block;
        }
    }

    #rotation-lock-button {
        width: 0.76rem;
        height: 0.76rem;
        margin-right: 0.25rem;
        line-height: 0;

        svg {
            width: 100%;
            height: 100%;
        }
    }
</style>
