<template>
    <div
        :id="'cell' + cellId"
        :data-id="'cell' + cellNumber"
        class="sudoku-board-cell"
        :class="{
            'selected-cell': isSelected,
            'highlighted-by-peer': highlightedByPeer,
            'selected-value-cell': selectedValue
        }"
    >
        <div :id="'cell' + cellId + 'c'" class="value-container" :class="{ 'highlighted-by-samevalue': highlightSameValues && currentValue && (predefinedValue || selectedValue) === currentValue && !showWrongValue }">
            <!--<transition name="fade-in" mode="out-in">-->
            <span v-if="predefinedValue" :id="'cell' + cellId + 'p'" :key="predefinedValue">{{ predefinedValue || '' }}</span>
            <!--</transition>-->
            <transition name="scale-in" mode="out-in">
                <span v-if="selectedValue && !showWrongValue" :id="'cell' + cellId + 's'" :key="selectedValue">{{ selectedValue }}</span>
            </transition>
            <transition name="scale-out" @after-enter="clearWrongValue">
                <span v-if="selectedValue && showWrongValue" class="wrong-value">{{ selectedValue }}</span>
            </transition>
        </div>

        <div
            v-if="!predefinedValue && !(checkNewValues && selectedValue) && !smartHintMark && (hintsRange.length || (showInCellValuePicker && isSelected))"
            class="cell-hints"
            :class="{
                'value-picker-on': showInCellValuePicker && isSelected,
                'highlighted-by-smart-hint': smartHintHighlighted
            }"
        >
            <span
                v-for="i in hintsRange"
                :key="i"
                class="hint-item"
                :class="{
                    'hint-item-1': i === 1,
                    'hint-item-2': i === 2,
                    'hint-item-3': i === 3,
                    'hint-item-4': i === 4,
                    'hint-item-5': i === 5,
                    'hint-item-6': i === 6,
                    'hint-item-7': i === 7,
                    'hint-item-8': i === 8,
                    'hint-item-9': i === 9,
                    'selected-hint': cellHints.includes(i),
                    'selected-hint-highlighted-by-value': highlightSameValues && currentValue && cellHints.includes(i) && currentValue === i
                }"
            >
                {{ i }}
            </span>
        </div>

        <div v-if="smartHintMark" class="smarthint-target" :class="{'smarthint-target-danger': smartHint.wrong}">
            <svg-icon :icon="smartHint.wrong ? 'xmark' : 'certificate'" class="smarthint-target-svg" />
            <div class="smarthint-target-value">{{ smartHint.hintId }}</div>
        </div>
    </div>
</template>

<script>
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { sudokuStore } from '@/App/stores';
    import { unitlist} from '@/services/thesudokuapp-peers.mjs';

    export default {
        components: {
            svgIcon: SvgIcon
        },
        props: {
            unitNumber: {
                type: Number,
                required: true
            },
            cellNumber: {
                type: Number,
                required: true
            },
            showInCellValuePicker: {
                type: Boolean,
                required: true
            },
            highlightSameValues: {
                type: Boolean,
                required: true
            },
            highlightPeerCells: {
                type: Boolean,
                required: true
            },
            checkNewValues: {
                type: Boolean,
                required: true
            },
            selectedCellPeers: {
                type: Set,
                required: true
            },
            wrongValueCells: {
                type: Array,
                required: true
            },
            gameover: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            // 9 units   each unit has 9 cells
            // 1 2 3     123
            // 4 5 6     456
            // 7 8 9     789
            // => unit 1, cell 1 = A1, unit 9, cell 9 = I9
            cellId() {
                return unitlist[this.unitNumber-1][this.cellNumber-1];
            },
            hintsRange() {
                if (this.showInCellValuePicker && this.isSelected) {
                    return [1, 2, 3, 4, 5, 6, 7, 8, 9];
                }

                return this.cellHints;
            },
            predefinedValue() {
                return sudokuStore.predefinedValues[this.cellId];
            },
            selectedValue() {
                return sudokuStore.selectedValues[this.cellId];
            },
            cellHints() {
                if (!sudokuStore.selectedHints[this.cellId]) {
                    return [];
                }

                return sudokuStore.selectedHints[this.cellId].map((v, i) => v ? i : 0).filter(Boolean);
            },
            isSelected() {
                return sudokuStore.selectedCellId === this.cellId;
            },
            highlightedByPeer() {
                return this.highlightPeerCells && this.selectedCellPeers.has(this.cellId);
            },
            currentValue() {
                return sudokuStore.currentValue;
            },
            smartHint() {
                return sudokuStore.smartHint;
            },
            smartHintMark() {
                return (this.smartHint.cellId === this.cellId) && this.smartHint.hintId && (this.smartHint.mark || this.smartHint.wrong);
            },
            smartHintHighlighted() {
                return this.smartHint.highlightedCells.includes(this.cellId);
            },
            showWrongValue() {
                if (this.checkNewValues && this.wrongValueCells.includes(this.cellId)) {
                    return true;
                }

                return !this.checkNewValues && this.gameover && this.wrongValueCells.includes(this.cellId);
            }
        },
        methods: {
            clearWrongValue() {
                sudokuStore.clearCell(this.cellId);
            }
        }
    };
</script>
