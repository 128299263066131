/*const reflowSenses = async ({ forced= false, senseType = undefined } = {}) => {

};*/

import { logger } from './thesudokuapp-logger';

const round = (number, decimals) => Number.isInteger(number) ? number : parseFloat(number.toFixed(decimals));

const debounce = (callback, timeout) => {
    let timeoutId = null;

    return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => callback(args), timeout);
    };
};

const loadSenseScript = url => {
    return new Promise((resolve, reject) => {
        const sense =  document.createElement('script');
        sense.addEventListener('load', resolve);
        sense.addEventListener('error', reject);
        sense.async = true;
        sense.crossOrigin = 'anonymous';
        sense.src = url;
        document.body.appendChild(sense);
    });
};

const senses = {
    one: {
        geometryOk: false,
        filled: false,
        isFilling: false,
        el: null,
        height: 0,
        width: 0,
        ins: '<ins class="adsbygoogle" style="display:block;height:{{height}}px;width:{{width}}px;" data-ad-client="ca-pub-2850831192568023" data-ad-slot="2896129606"></ins>',
        getIns() {
            return this.ins.replace('{{height}}', this.height).replace('{{width}}', this.width);
        }
    },
    two: {
        geometryOk: false,
        filled: false,
        isFilling: false,
        el: document.getElementById('sense-two'),
        height: 0,
        width: 0,
        ins: '<ins class="adsbygoogle" style="display:block;height:100%;width:100%;" data-ad-client="ca-pub-2850831192568023" data-ad-slot="9269966264" data-ad-format="auto" data-full-width-responsive="true"></ins>'/*,
        getIns() {
            return this.ins.replace('{{height}}', this.height).replace('{{width}}', this.width);
        }*/
    },
    three: {
        geometryOk: false,
        filled: false,
        isFilling: false,
        el: document.getElementById('sense-three'),
        height: 0,
        width: 0,/*
        ins: '<ins class="adsbygoogle" style="display:block;height:{{height}}px;width:{{width}}px;" data-ad-client="ca-pub-2850831192568023" data-ad-slot="6680264275"></ins>',
        getIns() {
            return this.ins.replace('{{height}}', this.height).replace('{{width}}', this.width);
        }*/
        ins: '<ins class="adsbygoogle" style="display:block;{{height}}px;width:{{width}}px;" data-ad-client="ca-pub-2850831192568023" data-ad-slot="6680264275"></ins>',
        getIns() {
            return this.ins.replace('{{height}}', this.height).replace('{{width}}', this.width);
        }
    },
    four: {
        geometryOk: false,
        filled: false,
        isFilling: false,
        height: 0,
        width: 0,
        el: document.getElementById('sense-four-1'),
        parentEl: document.getElementById('sense-four'),
        ins: '<ins class="adsbygoogle" style="display:inline-block;height:{{height}}px;width:{{width}}px;" data-ad-client="ca-pub-2850831192568023" data-ad-slot="3964171796"></ins>',
        getIns() {
            return this.ins.replace('{{height}}', this.height).replace('{{width}}', this.width);
        }
    },
};

const thesudokuappMainContent = document.getElementById('thesudokuapp-main-content');

let
    senseScriptLoaded = false,
    onResizeAdded = false;

const reflowSenses = async ({ forced= false, senseType = undefined } = {}) => {
    if (
        (window.thesudokuapp.appType && window.thesudokuapp.appType !== 'pwa')
        || window.thesudokuapp.user
        || window.thesudokuapp.daDisabled
    ) {
        return;
    }

    if (document.fullscreenElement) {
        return;
    }

    if (!senses['one'].el) {
        senses['one'].el = document.getElementById('sense-one');
    }

    if (senseType === 'one' && (senses['one'].filled || senses['one'].isFilling)) {
        return;
    }

    [ 'one', 'two', 'three', 'four' ].forEach(sense => {
        if (senses[sense].filled === false && senses[sense].isFilling === false) {
            senses[sense].geometryOk = false;
            senses[sense].el.style.visibility = '';
            senses[sense].el.innerHTML = '';
        }
    });

    if (forced) {
        [ 'two', 'three', 'four' ].forEach(sense => {
            senses[sense].filled = false;
            senses[sense].geometryOk = false;
            senses[sense].el.style.visibility = '';
            senses[sense].el.innerHTML = '';
        });
    }

    const
        portraitTablet = matchMedia('(max-aspect-ratio: 73/100) and (min-aspect-ratio: 6/10)').matches,
        portraitTabletIPad = matchMedia('(max-aspect-ratio: 90/108) and (min-aspect-ratio: 73/100)').matches,
        portrait = matchMedia('(max-aspect-ratio: 6/10)').matches,
        sensesToPush = [];

    if (senseType === 'one') {
        const senseOneDisplay = getComputedStyle(senses['one'].el).display;

        if (!senses['one'].geometryOk && senseOneDisplay === 'flex') {
            const { width: senseOneRectWidth, height: senseOneRectHeight } = senses['one'].el.getBoundingClientRect();

            senses['one'].height = Math.floor(senseOneRectHeight);
            senses['one'].width = Math.floor(senseOneRectWidth);

            senses['one'].geometryOk = true;
            senses['one'].el.style.visibility = 'visible';

            if (!senses['one'].el.innerHTML) {
                senses['one'].el.innerHTML = senses['one'].getIns();
                sensesToPush.push('one');
            }
        }
    } else if (portraitTablet || portraitTabletIPad || portrait) {
        if (!forced && senses['three'].el.innerHTML) {
            return;
        }

        /*
        let { height: thesudokuappMainContentHeight } = thesudokuappMainContent.getBoundingClientRect();

        let senseThreeRectHeight = Math.floor(window.innerHeight - thesudokuappMainContentHeight - 20);

        if (senseThreeRectHeight >= 50) {
            if (!senses['three'].geometryOk) {
                senses['three'].height = senseThreeRectHeight;
                senses['three'].width = window.innerWidth;
                senses['three'].geometryOk = true;
                senses['three'].el.style.visibility = 'visible';
            }

            if (!senses['three'].el.innerHTML) {
                senses['three'].el.innerHTML = senses['three'].getIns();
                sensesToPush.push('three');
            }
        } else if (senses['three'].geometryOk) {
            senses['three'].width = 0;
            senses['three'].height = 0;
            senses['three'].geometryOk = false;
            senses['three'].el.style.visibility = '';
        }*/

        const { height: senseThreeHeight, width: senseThreeWidth } = window.getComputedStyle(senses['three'].el);

        let { height: thesudokuappMainContentHeight } = thesudokuappMainContent.getBoundingClientRect();

        const
            thesudokuappMainContentAvailableHeight = Math.floor(parseFloat(thesudokuappMainContentHeight)),
            availableHeight = window.innerHeight - thesudokuappMainContentAvailableHeight - 30,
            //availableHeight = Math.floor(parseFloat(senseThreeHeight) - 30),
            availableWidth = Math.floor(parseFloat(senseThreeWidth));

        if (availableHeight >= 50) {
            senses['three'].geometryOk = true;
            senses['three'].width = availableWidth;
            senses['three'].height = availableHeight;
            senses['three'].el.style.visibility = 'visible';
            senses['three'].el.style.paddingBottom = '30px';
            senses['three'].el.style.height = (availableHeight + 30) + 'px';
            senses['three'].el.style.width = availableWidth + 'px';

            if (!senses['three'].el.innerHTML) {
                senses['three'].el.innerHTML = senses['three'].getIns();
                sensesToPush.push('three');
            }
        } else if (senses['three'].geometryOk) {
            senses['three'].width = 0;
            senses['three'].height = 0;
            senses['three'].geometryOk = false;
            senses['three'].el.style.visibility = '';
            senses['three'].el.style.paddingBottom = '';
            senses['three'].el.style.height = '';
            senses['three'].el.style.width = '';
        }

        senses['two'].geometryOk = false;
        senses['two'].el.style.visibility = '';
        senses['four'].geometryOk = false;
        senses['four'].el.style.visibility = '';
    } else {
        if (!forced && (senses['two'].el.innerHTML || senses['four'].el.innerHTML)) {
            return;
        }

        const {
            width: senseTwoWidth,
            height: senseTwoHeight,
            paddingTop: senseTwoPaddingTop,
            paddingLeft: senseTwoPaddingLeft,
            paddingRight: senseTwoPaddingRight
        } = window.getComputedStyle(senses['two'].el);

        const
            availableWidth = Math.floor(parseFloat(senseTwoWidth) - parseFloat(senseTwoPaddingLeft) - parseFloat(senseTwoPaddingRight)),
            availableHeight = Math.floor(parseFloat(senseTwoHeight) - parseFloat(senseTwoPaddingTop));

        if (availableWidth >= 120) { // padding 30px
            if (!senses['two'].geometryOk) {
                senses['two'].width = availableWidth;
                senses['two'].height = availableHeight;
                senses['two'].geometryOk = true;
                senses['two'].el.style.visibility = 'visible';
            }

            if (!senses['two'].el.innerHTML) {
                senses['two'].el.innerHTML = senses['two'].ins;//senses['two'].getIns();
                sensesToPush.push('two');
            }
        } else if (senses['two'].geometryOk) {
            senses['two'].width = 0;
            senses['two'].height = 0;

            senses['two'].geometryOk = false;
            senses['two'].el.style.visibility = '';
        }

        if (availableWidth >= 330) {
            senses['four'].geometryOk = true;
            senses['four'].parentEl.style.visibility = 'visible';
            senses['four'].el.style.visibility = 'visible';

            if (availableWidth >= 366) {
                senses['four'].width = 336;
                senses['four'].height = 280;
            } else {
                senses['four'].width = 300;
                senses['four'].height = 250;
            }

            if (!senses['four'].el.innerHTML) {
                senses['four'].el.innerHTML = senses['four'].getIns();
                sensesToPush.push('four');
            }
        } else if (senses['four'].geometryOk) {
            senses['four'].width = 0;
            senses['four'].height = 0;
            senses['four'].geometryOk = false;
            senses['four'].parentEl.style.visibility = '';
            senses['four'].el.style.visibility = '';
        }

        senses['three'].geometryOk = false;
        senses['three'].el.style.visibility = '';
        senses['three'].el.style.paddingBottom = '';
        senses['three'].el.style.height = '';
        senses['three'].el.style.width = '';
    }

    if (sensesToPush.length) {
        if (!senseScriptLoaded) {
            try {
                await loadSenseScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2850831192568023');
                senseScriptLoaded = true;
            } catch (err) {
                logger.trackEvent({
                    category: 'senses',
                    action: 'nosense'
                });
            }
        }

        if (senseScriptLoaded) {
            sensesToPush.forEach(senseType => {
                senses[senseType].isFilling = true;
                window.setTimeout(() => trackSensedEvent(senseType), 5000);

                (window.adsbygoogle || []).push({});
            });
        }
    } else {
        logger.trackEvent({
            category: 'senses',
            action: 'nospace',
            eventName: window.innerWidth + 'x' + window.innerHeight + ', ' + round(window.devicePixelRatio, 2)
        });
    }

    /*
    if (!onResizeAdded && senseScriptLoaded) {
        onResizeAdded = true;

        window.addEventListener('resize', debounce(() => {
            logger.trackEvent({
                category: 'senses',
                action: 'resize',
                eventName: window.innerWidth + 'x' + window.innerHeight + ', ' + round(window.devicePixelRatio, 2)
            });

            reflowSenses();
        }, 500));
    }*/
};

const trackSensedEvent = async senseType => {
    senses[senseType].isFilling = false;

    const ins = senses[senseType].el.getElementsByTagName('ins');

    if (ins.length !== 1) {
        return;
    }

    const filled = ins[0].dataset['adStatus'];

    if (!filled) {
        logger.trackEvent({
            category: 'senses',
            action: 'nostatus',
            eventName: senseType
        });

        return;
    }

    if (filled === 'filled') {
        logger.trackEvent({
            category: 'senses',
            action: 'sensed',
            eventName: senseType
        });

        senses[senseType].filled = true;

        if (window.thesudokuapp.gclid) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-10963732466/ML_PCKGBq48YEPKP9eso' });
        }
    } else {
        logger.trackEvent({
            category: 'senses',
            action: 'notfilled',
            eventName: senseType
        });
    }
};

export { reflowSenses };
