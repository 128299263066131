import { defineStore } from 'pinia';
import { cells, peers } from '@/services/thesudokuapp-peers';
import { gameplaySettingsStore, stopwatchStore, uiStore } from '@/App/stores/index';
import { playSound } from '@/services/thesudokuapp-sound';
import { reflowSenses } from '@/services/thesudokuapp-senses';
import { storageGetAndPatchStore, storageSet } from '@/services/thesudokuapp-storage';
import { animateCellsOnNewValue } from '@/services/thesudokuapp-animation';
import { utils } from '@/services/thesudokuapp-utils';
import { logger } from '@/services/thesudokuapp-logger';

const getEmptyState = () => ({
    grid: {
        grid: '',
        hashid: '',
        difficulty: '',
        solution: '',
        autofill: false,
        afterlose: false,
        simplehints: 0,
        smarthints: 0,
    },
    selectedHints: {},
    selectedValues: {},
    predefinedValues: {},
    solutionValues: {},

    valuesCount: new Array(10).fill(0),
    selectedCellId: '',
    hoveredCellId: '',
    lastSelectedHintId: 0,
    firstCellUpdate: true,
    history: {
        currentStep: 0,
        steps: []
    },
    wrongvaluesentered: 0,
    wrongvaluescount: 0,

    smartHint: {
        on: false,
        cellId: null,
        hintId: null,
        animeCallback: null,
        wrong: false,
        mark: false,
        highlightedCells: []
    }
});

export const useSudokuStore = defineStore('sudoku', {
    state: getEmptyState,
    getters: {
        currentValue: state => {
            return state.lastSelectedHintId || state.predefinedValues[state.selectedCellId] || state.selectedValues[state.selectedCellId];
        },
        canUndo: state => {
            return !!state.history.currentStep;
        },
        canRedo: state => {
            return state.history.steps.length > state.history.currentStep;
        },
        getWrongValuesCount: state => {
            return state.cellsWithWrongValues.length;
        },
        cellsWithWrongValues: state => {
            return cells.reduce(
                (acc, cellId) => {
                    if (state.selectedValues[cellId] && (state.selectedValues[cellId] !== state.solutionValues[cellId])) {
                        acc.push(cellId);
                    }
                    return acc;
                }, []);
        }
    },
    actions: {
        newGame({ grid, type, sudokuboot = false}) {
            this.initSudoku(grid);

            if (gameplaySettingsStore.sound) {
                void playSound('newgame');
            }

            if (!sudokuboot) {
                utils.updateLocationAndTitle({
                    gridid: this.grid.hashid,
                    difficulty: this.grid.difficulty
                });

                logger.trackNewGame(type);
            }
        },
        initSudoku({
            grid,
            hashid,
            difficulty,
            solution,
            autofill = false,
            afterlose = false,
            simplehints = 0,
            smarthints = 0,
            persistedHints = {},
            persistedSelectedValues = '',
            stopwatch = 0,
            startStopwatch = true
        }) {
            this.$state = getEmptyState();
            stopwatchStore.stopStopwatch();

            this.grid = {
                grid,
                hashid,
                difficulty,
                solution,
                autofill,
                afterlose,
                simplehints,
                smarthints,
            };

            cells.forEach((cell, index) => {
                const hints = new Array(10).fill(false);

                if (persistedHints && persistedHints[cell]) {
                    for (const hint of persistedHints[cell]) {
                        hints[hint] = true;
                    }
                }

                this.selectedHints[cell] = hints;

                this.selectedValues[cell] = persistedSelectedValues ? parseInt(persistedSelectedValues[index]) : 0;
                this.solutionValues[cell] = parseInt(solution[index]);
                this.predefinedValues[cell] = parseInt(grid[index]);
                this.valuesCount[parseInt(grid[index])]++;
                if (persistedSelectedValues && parseInt(persistedSelectedValues[index])) {
                    this.valuesCount[parseInt(persistedSelectedValues[index])]++;
                }
            });

            if (startStopwatch) {
                stopwatchStore.startStopwatch(stopwatch);
            } else if (stopwatch) {
                stopwatchStore.currentTimer = stopwatch;
            }

            void Promise.all([
                storageSet.grid(this.grid),
                storageSet.values(this.selectedValues),
                storageSet.hints(this.selectedHints),
                storageGetAndPatchStore.currentPuzzleStats(this.grid.hashid)
            ]);
        },
        selectCell(cellId = null) {
            this.lastSelectedHintId = 0;
            this.hoveredCellId = '';

            if (gameplaySettingsStore.sound) {
                if (!cellId && this.selectedCellId) {
                    void playSound('deselectcell');
                } else if (cellId) {
                    void playSound('selectcell');
                }
            }

            this.selectedCellId = cellId || '';
        },
        clearCell(cellId) {
            if (this.selectedValues[cellId]) {
                this.valuesCount[this.selectedValues[cellId]]--;
                this.selectedValues[cellId] = 0;
                void storageSet.values(this.selectedValues);
            } else if (this.selectedHints[cellId].filter(Boolean).length) {
                this.selectedHints[cellId] = new Array(10).fill(false);
                void storageSet.hints(this.selectedHints);
            }
        },
        updateCellHint({ cellId, hintId }) {
            if (this.firstCellUpdate) {
                this.firstCellUpdate = false;
                void reflowSenses();
            }

            if (this.history.steps.length !== this.history.currentStep-1) {
                this.history.steps.splice(this.history.currentStep, this.history.steps.length - this.history.currentStep);
            }

            this.history.currentStep++;

            this.history.steps.push({
                before: {
                    hints: {
                        [cellId]: [...this.selectedHints[cellId]]
                    },
                    value: {
                        [cellId]: this.selectedValues[cellId]
                    }
                }
            });

            /* Если мы поставили значение в Value mode, потом вернулись в Hint mode и подставили подсказку, то
             * значение нужно стереть */
            if (!gameplaySettingsStore.checkNewValues && this.selectedValues[cellId]) {
                this.valuesCount[this.selectedValues[cellId]]--;
                this.selectedValues[cellId] = 0;
                void storageSet.values(this.selectedValues);
            }

            const currentCellState = this.selectedHints[cellId];
            /* Удаляем старое значение и вставляем на его место новое */
            currentCellState.splice(hintId, 1, !currentCellState[hintId]);

            this.history.steps[this.history.currentStep-1]['after'] = {
                hints: {
                    [cellId]: [...this.selectedHints[cellId]]
                },
                value: {
                    [cellId]: this.selectedValues[cellId]
                }
            };

            /* Штука, чтобы подсвечивать все значения по нажатию на хинт
             * Upd 2019/11/16 Раньше, если мы убирали хинт, то и убирали подсветку
             * Теперь подсветку оставляем включенной
             */
            /*if (!currentCellState[payload.hintId]) {
                state.lastSelectedHintId = 0;
            } else {
                state.lastSelectedHintId = payload.hintId;
            }*/

            this.lastSelectedHintId = hintId;

            void storageSet.hints(this.selectedHints);
        },
        updateCellValue({ cellId, hintId }) {
            if (this.firstCellUpdate) {
                this.firstCellUpdate = false;
                void reflowSenses();
            }

            if (gameplaySettingsStore.checkNewValues
                && (hintId !== this.selectedValues[cellId])
                && (hintId !== this.solutionValues[cellId])
            ) {
                this.lastSelectedHintId = hintId;
                this.wrongvaluesentered++;
                void storageSet.grid(this.grid);
                this.selectedValues[cellId] = hintId;
                this.valuesCount[hintId]++;
                return; //После проигрывания анимации это значение уйдет автоматом
            }

            if (this.history.steps.length !== this.history.currentStep-1) {
                this.history.steps.splice(this.history.currentStep, this.history.steps.length - this.history.currentStep);
            }

            this.history.currentStep++;

            this.history.steps.push({
                before: {
                    value: {
                        [cellId]: this.selectedValues[cellId]
                    },
                    hints: {}
                },
                after: {
                    value: {},
                    hints: {}
                }
            });

            const hadHints = this.selectedHints[cellId].filter(Boolean).length;

            if (hadHints) {
                this.history.steps[this.history.currentStep-1]['before']['hints'][cellId] = [...this.selectedHints[cellId]];
                this.history.steps[this.history.currentStep-1]['after']['hints'][cellId] = new Array(10).fill(false);
            }

            if (this.selectedValues[cellId] === hintId || hintId === 0) {
                if (this.selectedValues[cellId]) {
                    this.valuesCount[this.selectedValues[cellId]]--;
                }

                this.selectedValues[cellId] = 0;
                this.history.steps[this.history.currentStep-1]['after']['value'] = { [cellId]: 0 };

                void storageSet.values(this.selectedValues);

                return;
            }

            this.lastSelectedHintId = hintId;

            if (hadHints) {
                this.selectedHints[cellId] = new Array(10).fill(false);
            }

            if (this.selectedValues[cellId]) {
                this.valuesCount[this.selectedValues[cellId]]--;
            }

            this.selectedValues[cellId] = hintId;
            this.valuesCount[hintId]++;

            this.history.steps[this.history.currentStep-1]['after']['value'] = { [cellId]: this.selectedValues[cellId] };

            if (gameplaySettingsStore.clearHintsOnNewValue) {
                const cellPeers = peers[cellId];

                for (const cell of cellPeers) {
                    if (this.selectedHints[cell][hintId]) {
                        this.history.steps[this.history.currentStep-1]['before']['hints'][cell] = [...this.selectedHints[cell]];
                        this.selectedHints[cell].splice(hintId, 1, false);
                        this.history.steps[this.history.currentStep-1]['after']['hints'][cell] = [...this.selectedHints[cell]];
                    }
                }
            }

            void storageSet.hints(this.selectedHints);
            void storageSet.values(this.selectedValues);

            if (cells.reduce((accumulator, cell) => accumulator + (!!this.predefinedValues[cell] || !!this.selectedValues[cell]), 0) === 81) {
                this.selectedCellId = '';
                this.lastSelectedHintId = '';
                this.hoveredCellId = '';
                this.wrongvaluescount = this.getWrongValuesCount;
                this.grid.afterlose = this.grid.afterlose || !!this.wrongvaluescount;

                stopwatchStore.pauseStopwatch();

                uiStore.gameover = true;

                void storageSet.grid(this.grid);
            } else {
                const unitfilled = animateCellsOnNewValue({cellId, hintId});

                if (gameplaySettingsStore.sound) {
                    void playSound(unitfilled ? 'unitfilled' : 'newvalue');
                }
            }
        },

        undo() {
            if (!this.history.currentStep) {
                return;
            }

            const step = this.history.steps[this.history.currentStep-1];
            const cellId = Object.keys(step.before.value)[0];
            const valueBefore = Object.values(step.before.value)[0];
            const valueAfter = Object.values(step.after.value)[0];

            if (valueBefore !== valueAfter) {
                if (valueAfter) {
                    this.valuesCount[valueAfter]--;
                }

                if (valueBefore) {
                    this.valuesCount[valueBefore]++;
                }

                this.selectedValues[cellId] = valueBefore;
                void storageSet.values(this.selectedValues);
            }

            if (step.before.hints) {
                for (const cell in step.before.hints) {
                    this.selectedHints[cell] = [...step.before.hints[cell]];
                }

                void storageSet.hints(this.selectedHints);
            }

            this.history.currentStep--;
        },
        redo() {
            if (this.history.steps.length <= this.history.currentStep) {
                return;
            }

            const step = this.history.steps[this.history.currentStep];
            const cellId = Object.keys(step.after.value)[0];
            const valueBefore = Object.values(step.before.value)[0];
            const valueAfter = Object.values(step.after.value)[0];

            if (valueBefore !== valueAfter) {
                if (valueAfter) {
                    this.valuesCount[valueAfter]++;
                }

                if (valueBefore) {
                    this.valuesCount[valueBefore]--;
                }

                this.selectedValues[cellId] = valueAfter;
                void storageSet.values(this.selectedValues);
            }

            if (step.after.hints) {
                for (const cell in step.after.hints) {
                    this.selectedHints[cell] = [...step.after.hints[cell]];
                }

                void storageSet.hints(this.selectedHints);
            }

            this.history.currentStep++;
        },
        resetHistory() {
            this.history = {
                currentStep: 0,
                steps: []
            };
        }
    }
});

