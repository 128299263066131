<template>
    <div v-click-outside="clickOutside" class="sudoku-board-container">
        <div
            class="sudoku-board"
            :class="{
                'highligted-mistakes-board': highlightMistakes
            }"
            @click="clickOnBoard"
        >
            <div v-for="i in 9" :id="'unit' + i" :key="i" class="sudoku-board-unit">
                <app-board-cell
                    v-for="j in 9"
                    :key="j"
                    :unit-number="i"
                    :cell-number="j"
                    :show-in-cell-value-picker="showInCellValuePicker"
                    :highlight-same-values="highlightSameValues"
                    :highlight-peer-cells="highlightPeerCells"
                    :check-new-values="checkNewValues"
                    :selected-cell-peers="selectedCellPeers"
                    :wrong-value-cells="cellsWithWrongValues"
                    :gameover="gameover"
                />
            </div>
        </div>
        <app-snackbar v-show="snackbar" />
    </div>
</template>

<script>
    import { peers } from '@/services/thesudokuapp-peers';
    import BoardCell from './BoardCell';
    import Snackbar from './Snackbar';
    import {gameplaySettingsStore, uiStore, snackbarStore, sudokuStore, stopwatchStore} from '@/App/stores';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appBoardCell: BoardCell,
            appSnackbar: Snackbar
        },
        computed: {
            showInCellValuePicker() {
                return gameplaySettingsStore.showInCellValuePicker;
            },
            highlightSameValues() {
                return gameplaySettingsStore.highlightSameValues;
            },
            highlightPeerCells() {
                return gameplaySettingsStore.highlightPeerCells;
            },
            checkNewValues() {
                return gameplaySettingsStore.checkNewValues;
            },
            numberFirst() {
                return gameplaySettingsStore.numberFirst;
            },
            highlightMistakes() {
                return gameplaySettingsStore.highlightMistakes;
            },
            selectedOrHoveredCellId() {
                return sudokuStore.selectedCellId || sudokuStore.hoveredCellId;
            },
            selectedCellPeers() {
                if (!this.selectedOrHoveredCellId || !this.highlightPeerCells) {
                    return new Set();
                }

                return peers[this.selectedOrHoveredCellId];
            },
            snackbar() {
                return uiStore.snackbar;
            },
            gameover() {
                return uiStore.gameover;
            },
            cellsWithWrongValues() {
                if (!gameplaySettingsStore.checkNewValues && !uiStore.gameover) {
                    return [];
                }

                return sudokuStore.cellsWithWrongValues;
            }
        },
        methods: {
            clickOnBoard(event) {
                uiStore.switchUIState();

                const cellId = event.target.closest('.sudoku-board-cell')?.id.substring(4);

                if (!cellId) {
                    return;
                }

                if (this.numberFirst) {
                    sudokuStore.hoveredCellId = cellId;

                    if (sudokuStore.predefinedValues[cellId]
                        || (gameplaySettingsStore.checkNewValues && sudokuStore.selectedValues[cellId])
                    ) {
                        return;
                    }

                    if (uiStore.selectedNumberFirst.hint) {
                        sudokuStore.updateCellHint({
                            cellId,
                            hintId: uiStore.selectedNumberFirst.hint
                        });
                    } else if (uiStore.selectedNumberFirst.value) {
                        sudokuStore.updateCellValue({
                            cellId,
                            hintId: uiStore.selectedNumberFirst.value
                        });
                    } else {
                        snackbarStore.showSnackbar({
                            primaryMessage: $t('snackbar.selectnumpadfirst[0]'),
                            secondaryMessage: $t('snackbar.selectnumpadfirst[1]', { modename: $t('settings.numberFirst') }),
                            timeout: 1750
                        });
                    }

                    return;
                }

                if (sudokuStore.selectedCellId !== cellId) {
                    sudokuStore.selectCell(cellId);
                    return;
                }

                if (this.showInCellValuePicker && event.target.classList.contains('hint-item')) {
                    sudokuStore.updateCellHint({
                        cellId,
                        hintId: parseInt(event.target.innerHTML)
                    });
                }
            },
            clickOutside(event) {
                if (!['html', 'body', 'header', 'ins'].includes(event.target.tagName.toLowerCase())
                    && !event.target.classList.contains('thesudokuapp')
                    && !event.target.classList.contains('sudoku-board-labels')
                    && !event.target.classList.contains('thesudokuapp-controls')
                    && !event.target.classList.contains('thesudokuapp-controls-buttons')
                    && !event.target.classList.contains('thesudokupp-sense')
                    && !event.target.classList.contains('thesudokupp-sense-container')
                ) {
                    return;
                }

                if (uiStore.waiting) {
                    return;
                }

                if (stopwatchStore.stopwatchOnAndPaused) {
                    stopwatchStore.resumeStopwatch();
                }

                uiStore.selectNumberFirst();
                sudokuStore.selectCell();
                uiStore.switchUIState();
            }
        }
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-buttons' as *;
    @use '~@/scss/thesudoku-animations' as *;

    .sudoku-board {
        .sudoku-board-cell {
            .smarthint-target-value {
                color: var(--smarthint-background);
            }

            .smarthint-target, .smarthint-target-value {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .smarthint-target-svg {
                width: 90%;
                height: 90%;

                &.smarthint-target-svg-danger {
                    color: var(--danger-color);
                }
            }

            .smarthint-target-danger {
                color: var(--danger-color);

                .smarthint-target-value {
                    color: var(--accent-color);
                }
            }
        }
    }

    .sudoku-board-container {
        position: relative;
        user-select: none;
        min-width: inherit;
        min-height: var(--min-height);
        height: var(--board-size);
        width: var(--board-size);
        //margin-bottom: 0rem;
        display: flex;
        justify-content: center;
    }

    .sudoku-board {
        position: relative;
        height: 100%;
        width: 100%;

        display: flex;
        flex-wrap: wrap;

        border: var(--border-width) solid;

        [data-id=cell2], [data-id=cell3], [data-id=cell5], [data-id=cell6], [data-id=cell8], [data-id=cell9] {
            border-left: 1px solid var(--thin-grid-line-color);
        }

        [data-id=cell4], [data-id=cell5], [data-id=cell6], [data-id=cell7], [data-id=cell8], [data-id=cell9] {
            border-top: 1px solid var(--thin-grid-line-color);
        }

        .sudoku-board-unit {
            width: 33.33333%;
            height: 33.33333%;
            display: flex;
            flex-wrap: wrap;
        }

        #unit1, #unit2, #unit4, #unit5, #unit7, #unit8 {
            border-right: var(--border-width) solid;
        }

        #unit1, #unit2, #unit3, #unit4, #unit5, #unit6 {
            border-bottom: var(--border-width) solid;
        }

        /* postcss-custom-properties не умеет подгружать :root из другого файла,
           поэтому весь css (если он использует css vars придется писать тут */
        .sudoku-board-cell {
            width: 33.33333%; // bug in IE with calc()
            height: 33.33333%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            background-color: var(--cell-background-color);
        }

        .selected-value-cell {
            color: var(--selected-value-color);
        }

        .selected-cell.sudoku-board-cell::after {
            content: '';
            position: absolute;
            left: var(--cell-border-offset);
            top: var(--cell-border-offset);
            bottom: var(--cell-border-offset);
            right: var(--cell-border-offset);
            border: var(--border-width) solid var(--accent-color-lighter);
            border-radius: var(--border-radius);
            z-index: 1;
        }

        .value-container {
            width: 75%;
            height: 75%;
            border-radius: var(--border-radius);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .value-container.highlighted-by-samevalue {
            color: var(--accent-color);
            background-color: var(--success-color);
        }

        .cell-hints {
            position: absolute;
            color: transparent;
            font-size: var(--hint-item-size);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        .hint-item {
            padding: 0;
            width: 33.33333%;
            height: 33.33333%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .hint-item-1 {
            position: absolute;
            top: 0;
            left: 0;
        }

        .hint-item-2 {
            position: absolute;
            top: 0;
            left: 33.33333%;
        }

        .hint-item-3 {
            position: absolute;
            top: 0;
            left: 66.66666%;
        }

        .hint-item-4 {
            position: absolute;
            top: 33.33333%;
            left: 0;
        }

        .hint-item-5 {
            position: absolute;
            top: 33.33333%;
            left: 33.33333%;
        }

        .hint-item-6 {
            position: absolute;
            top: 33.33333%;
            left: 66.66666%;
        }

        .hint-item-7 {
            position: absolute;
            top: 66.66666%;
            left: 0;
        }

        .hint-item-8 {
            position: absolute;
            top: 66.66666%;
            left: 33.33333%;
        }

        .hint-item-9 {
            position: absolute;
            top: 66.66666%;
            left: 66.66666%;
        }

        .selected-hint {
            color: var(--accent-color);
        }

        .selected-hint-highlighted-by-value {
            border-radius: 50%;
            font-weight: bold;
            background-color: var(--success-color);
        }

        .value-picker-on {
            color: var(--accent-color-pale);
        }

        .highlighted-by-peer {
            background-color: var(--highlighted-bypeer-background-color);
            //color: var(--accent-color);
        }

        &.highligted-mistakes-board .highlighted-by-peer .highlighted-by-samevalue {
            color: var(--danger-color);
        }
    }

    .wrong-value {
        color: var(--danger-color);
        opacity: 0;
        z-index: 2;
    }

    .sudoku-board .highlighted-by-smart-hint {
        background-image: url('/assets/tutorial/selection-light.svg');
    }

    [data-theme=default-dark] {
        .sudoku-board .highlighted-by-smart-hint {
            background-image: url('/assets/tutorial/selection.svg');
        }

    }

    @media (hover: hover) and (pointer: fine) {
        .sudoku-board-cell:not(.selected-cell):hover {
            background-color: var(--cell-hover-background-color);
        }

        .value-picker-on .hint-item:hover {
            font-weight: bold;
        }
    }
</style>
