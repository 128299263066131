<template>
    <transition name="thesudokuapp-slidein-transition">
        <div class="thesudokuapp-settings thesudokuapp-slidein-transition-right" @click.stop="close">
            <app-arrow-close-button label="Close settings" @closed="close" />
            <div class="sudoku-switchbuttons-group">
                <div class="sudoku-switchbuttons-description">{{ $t('settings.mistakes.mistakes') }}</div>
                <div class="sudoku-switchbuttons-content">
                    <button
                        class="sudoku-switchbutton"
                        :class="{ 'sudoku-switchbutton-active': gameplaySettingsStore.mistakesMode === 'allow'}"
                        @click.stop="() => gameplaySettingsStore.setMistakesMode('allow')"
                    >
                        {{ $t('settings.mistakes.allow') }}
                    </button>
                    <button
                        class="sudoku-switchbutton"
                        :class="{ 'sudoku-switchbutton-active': gameplaySettingsStore.mistakesMode === 'allowAndHighlight'}"
                        @click.stop="() => gameplaySettingsStore.setMistakesMode('allowAndHighlight')"
                    >
                        {{ $t('settings.mistakes.allowAndHighlight') }}
                    </button>
                    <button
                        class="sudoku-switchbutton"
                        :class="{ 'sudoku-switchbutton-active': gameplaySettingsStore.mistakesMode === 'disallow'}"
                        @click.stop="() => gameplaySettingsStore.setMistakesMode('disallow')"
                    >
                        {{ $t('settings.mistakes.disallow') }}
                    </button>
                    <div class="sudoku-switchbutton-active-background" />
                </div>
            </div>

            <div v-if="uiStore.orientationLockDisabled">
                Orientation Lock is not supported
            </div>
            <div v-else>
                Orientation Lock is supported!
            </div>

            <app-toggle :state="gameplaySettingsStore.showStopwatch" :action="gameplaySettingsStore.toggleStopwatch">
                <slot>{{ $t('settings.showStopwatch') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.autopause" :action="gameplaySettingsStore.toggleAutopause" :disabled="!gameplaySettingsStore.showStopwatch">
                <slot>{{ $t('settings.autopause') }}</slot>
            </app-toggle>
            <app-toggle v-if="!uiStore.touchMode" :state="gameplaySettingsStore.showNumpad" :action="gameplaySettingsStore.toggleNumpad">
                <slot>{{ $t('settings.showNumpad') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.showSecondNumpad" :action="gameplaySettingsStore.toggleSecondNumpad" :disabled="!gameplaySettingsStore.showNumpad">
                <slot>{{ $t('settings.showSecondNumpad') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.numberFirst" :action="gameplaySettingsStore.toggleNumberFirst" :disabled="!gameplaySettingsStore.showNumpad">
                <slot>{{ $t('settings.numberFirst') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.showValuesCounterOnNumpad" :action="gameplaySettingsStore.toggleValuesCounterOnNumpad" :disabled="!gameplaySettingsStore.showNumpad">
                <slot>{{ $t('settings.showValuesCounterOnNumpad') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.highlightSameValues" :action="gameplaySettingsStore.toggleHighlightSameValues">
                <slot>{{ $t('settings.highlightSameValues') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.highlightPeerCells" :action="gameplaySettingsStore.toggleHighlightPeerCells">
                <slot>{{ $t('settings.highlightPeerCells') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.clearHintsOnNewValue" :action="gameplaySettingsStore.toggleClearHintsOnNewValue">
                <slot>{{ $t('settings.clearHintsOnNewValue') }}</slot>
            </app-toggle>
            <app-toggle v-if="!uiStore.touchMode" :state="gameplaySettingsStore.showInCellValuePicker" :action="gameplaySettingsStore.toggleInCellValuePicker">
                <slot>{{ $t('settings.showInCellValuePicker') }}</slot>
            </app-toggle>
            <app-toggle :state="gameplaySettingsStore.showLabels" :action="gameplaySettingsStore.toggleLabels">
                <slot>{{ $t('settings.showLabels') }}</slot>
            </app-toggle>
            <app-toggle :state="uiStore.reducedMotion" :action="uiStore.switchReducedMotion">
                <slot>{{ $t('settings.reducedMotion') }}</slot>
            </app-toggle>
            <app-toggle v-if="!uiStore.soundDisabled" :state="gameplaySettingsStore.sound" :action="gameplaySettingsStore.toggleSound">
                <slot>{{ $t('settings.sound') }}</slot>
            </app-toggle>
            <app-toggle :state="uiStore.darkMode" :action="uiStore.switchTheme">
                <slot>{{ $t('settings.darkMode') }}</slot>
            </app-toggle>
        </div>
    </transition>
</template>

<script>
    import Toggle from './Toggle';
    import ArrowCloseButton from './ArrowCloseButton';
    import { gameplaySettingsStore, uiStore } from '@/App/stores';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appToggle: Toggle,
            appArrowCloseButton: ArrowCloseButton
        },
        computed: {
            gameplaySettingsStore() {
                return gameplaySettingsStore;
            },
            uiStore() {
                return uiStore;
            }
        },
        methods: {
            $t,
            close() {
                uiStore.switchUIState({ type: 'settings', newState: false });
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-settings {
        justify-content: space-around;

        .sudoku-switchbuttons-group {
            margin-bottom: 0.5rem;
        }
    }
</style>
