<template>
    <transition enter-active-class="flip-in-x">
        <div v-show="gameover" class="sudoku-finished-message">
            <svg-icon :icon="message.icon" class="svgbutton-trophy" />
            <div>
                <div class="sudoku-finished-description" v-html="message.text" />
                <div v-for="(secondaryText, index) in message.secondaryText" :key="index" class="sudoku-finished-description-secondary" v-html="secondaryText" />
                <button
                    v-show="wrongvaluescount"
                    class="thesudokuapp-actionbutton"
                    :disabled="waiting"
                    @click="continueGameAfterLose"
                >
                    {{ $t('finish.letmefix') }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
    import { utils } from '@/services/thesudokuapp-utils';
    import { playSound } from '@/services/thesudokuapp-sound';
    import { reflowSenses } from '@/services/thesudokuapp-senses';
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import {
        gameplaySettingsStore,
        stopwatchStore,
        uiStore,
        snackbarStore,
        profileStore,
        sudokuStore,
        miscStore
    } from '@/App/stores';
    import { storageSet } from '@/services/thesudokuapp-storage';
    import { pwaAction } from '@/services/thesudokuapp-pwa';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { api } from '@/services/thesudokuapp-api';
    import { logger } from '@/services/thesudokuapp-logger';

    export default {
        components: {
            svgIcon: SvgIcon
        },
        computed: {
            message() {
                let message = {
                    autofill: false,
                    smarthints: false,
                    simplehints: false,
                    icon: undefined,
                    emoji: undefined,
                    text: undefined,
                    eventAction: undefined,
                    secondaryText: []
                };

                message.text = $t('finish.status.solved');

                if (sudokuStore.grid.autofill) {
                    message.autofill = true;
                    message.icon = 'award';
                    message.emoji = '&#x1F3C5;'; // 🏅
                    message.secondaryText.push($t('finish.autofill'));
                }

                if (sudokuStore.grid.smarthints) {
                    message.smarthints = true;
                    message.icon = 'flagcheckered';
                    message.emoji = '&#x1F3C1;'; // 🏁
                    message.secondaryText.push($t('finish.smarthints', sudokuStore.grid.smarthints));
                }

                if (sudokuStore.grid.simplehints) {
                    message.simplehints = true;
                    message.icon = 'flagcheckered';
                    message.emoji = '&#x1F3C1;'; // 🏁
                    message.secondaryText.push($t('finish.simplehints', sudokuStore.grid.simplehints));
                }

                if (sudokuStore.wrongvaluesentered || sudokuStore.wrongvaluescount) {
                    message.secondaryText.push($t('finish.mistakes', (sudokuStore.wrongvaluesentered || sudokuStore.wrongvaluescount)));
                }

                if (sudokuStore.wrongvaluesentered) {
                    message.icon = message.icon || 'award';
                    message.emoji = message.emoji || '&#x1F3C5;'; //🏅
                    message.eventAction = 'solved with disabled mistakes';
                } else if (!sudokuStore.wrongvaluescount && sudokuStore.grid.afterlose) {
                    message.icon = message.icon || 'award';
                    message.emoji = message.emoji || '&#x1F3C5;'; //🏅
                    message.secondaryText.push($t('finish.fixed'));
                    message.eventAction = 'solved with fixing mistakes';
                } else if (!sudokuStore.wrongvaluescount) {
                    message.icon = message.icon || 'trophy';
                    message.emoji = message.emoji || '&#x1F3C6;'; //🏆
                    if (!message.autofill && !message.smarthints && !message.simplehints) {
                        message.text = $t('finish.status.flawlessly');
                    }
                    message.eventAction = 'solved flawlessly';
                } else {
                    message.icon = 'flagcheckered';
                    message.emoji = '&#x1F3C1;'; // 🏁
                    message.text = $t('finish.status.mistakes', sudokuStore.wrongvaluescount);
                    message.eventAction = 'mistakes';
                }

                return message;
            },
            gameover() { return uiStore.gameover; },
            waiting() { return uiStore.waiting; },
            checkNewValues() { return gameplaySettingsStore.checkNewValues; },
            wrongvaluescount() { return sudokuStore.wrongvaluescount; }
        },
        watch: {
            gameover(newState) {
                if (newState) {
                    this.onShow();
                }
            }
        },
        methods: {
            $t,
            async onShow() {
                const stats = {
                    hashid: sudokuStore.grid.hashid,
                    difficulty: sudokuStore.grid.difficulty,
                    synced: false,
                    u: window.thesudokuapp.user?.u,
                    m: window.thesudokuapp.user?.m,
                    recordtimestamp: Math.floor(Date.now() / 1000),
                    time: stopwatchStore.currentTimer,
                    autofill: this.message.autofill,
                    smarthints: this.message.smarthints,
                    simplehints: this.message.simplehints,
                    aftermistakes: (
                        (this.message.eventAction === 'solved with disabled mistakes')
                        || (this.message.eventAction === 'solved with fixing mistakes')
                    ),
                    mistakes: this.message.eventAction === 'mistakes'
                };

                utils.updateLocationAndTitle({
                    emoji: this.message.emoji,
                    difficulty: stats.difficulty,
                    gridid: stats.hashid
                });

                void profileStore.updatePuzzleStats(stats);
                const { new: difficultyNew } = await profileStore.updateDifficultyStats(stats);

                if (gameplaySettingsStore.showStopwatch && difficultyNew) {
                    let actionCaption, actionCallback;

                    if (!uiStore.shareDisabled) {
                        actionCaption = $t('snackbar.action.share');
                        actionCallback = () => {
                            utils.share({
                                difficulty: stats.difficulty,
                                hashid: stats.hashid,
                                time: utils.formatTime(difficultyNew)
                            });
                        };
                    }

                    snackbarStore.showSnackbar({
                        showLogo: true,
                        primaryMessage: $t('snackbar.newrecord.primary'),
                        secondaryMessage: [
                            $t('snackbar.newrecord.secondary[0]', { difficulty: $t(`difficulty['${stats.difficulty}']`) }),
                            $t('snackbar.newrecord.secondary[1]', { time: utils.formatTime(difficultyNew) }),
                        ],
                        actionCaption,
                        actionCallback
                    });
                }

                if (this.message.eventAction !== 'mistakes') {
                    miscStore.solvedInSession += 1;

                    if (!snackbarStore.notifications.pwaappinstall // иначе, если будет нажата Dontshow каждый следующий раз action будет выполняться автоматически
                        && (miscStore.solvedInSession >= 2)
                        && !(gameplaySettingsStore.showStopwatch && difficultyNew)
                        && (!!miscStore.beforeInstallPromptEvent)
                        && !snackbarStore.notifications.pwaappinstall) {
                        window.setTimeout(() => {
                            snackbarStore.showSnackbar({
                                showLogo: true,
                                showDontshow: true,
                                notificationId: 'pwaappinstall',
                                primaryMessage: $t('snackbar.pwa.primary', { 'action': $t(`action.pwa.${utils.pwaType()}`) } ),
                                secondaryMessage: $t('snackbar.pwa.secondary'),
                                actionCaption: $t('snackbar.pwa.action'),
                                actionCallback: pwaAction,
                                dismissCaption: $t('snackbar.pwa.notnow')
                            });
                        }, 300);
                    }
                }

                if (gameplaySettingsStore.sound) {
                    if (this.message.eventAction === 'mistakes') {
                        void playSound('mistakes');
                    } else {
                        void playSound('solved');
                    }
                }

                logger.trackEvent({
                    category: `gameover: ${(stats.difficulty || 'seeded').toLowerCase()}`,
                    action: this.message.eventAction + (this.message.autofill ? ' with autofill' : '') + (this.message.smarthints ? ' with smart hints' : '') + (this.message.simplehints ? ' with simple hints' : ''),
                    eventName: stats.hashid || 'seeded',
                    eventValue: stats.time
                });

                if (window.thesudokuapp.user) {
                    void this.syncPuzzleStats(stats);
                }

                void reflowSenses({ senseType: 'one' });
            },
            async syncPuzzleStats(data) {
                try {
                    await api.syncPut({
                        records: [ data ]
                    });

                    profileStore.current.synced = true;
                    await storageSet.puzzleStats(profileStore.current);
                } catch (error) {
                    logger.trackEvent({
                        category: 'exception:api',
                        action: 'syncPut',
                        eventName: error?.status
                    });
                }

            },
            continueGameAfterLose() {
                uiStore.gameover = false;
                sudokuStore.resetHistory();
                stopwatchStore.resumeStopwatch();
            }
        }
    };
</script>

<style lang="scss">
    .sudoku-finished-message {
        background-color: var(--dark-background-transparent);
        border-radius: 1vmin;
        padding: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        user-select: none;
        box-shadow: 0.02rem 0.08rem 0.7vmin 0 rgba(0, 0, 0, 0.7);
        color: var(--accent-color-tetrad3);
        font-size: 0.75rem;
        line-height: 0.8rem;

        .thesudokuapp-actionbutton {
            margin-top: 0.5rem;
            font-size: 0.5rem;
            text-transform: none;
            padding: 0.16rem 0.3rem;
        }

        .sudoku-finished-description {
            text-align: left;
        }

        .sudoku-finished-description-secondary {
            text-align: left;
            font-size: 0.5em;
            line-height: 1.2em;

            &:nth-of-type(2) {
                margin-top: 1em;
            }
        }

        .svgbutton-trophy {
            //width: 1em;
            height: 1em;
            margin-right: 0.5rem;
            font-size: 2rem;
            color: var(--accent-color-tetrad3);
        }

        .sudoku-finished-buttons {
            display: flex;
            justify-content: center;

            .sudoku-text-button:not(:last-child) {
                margin-right: 2vmin;
            }
        }
    }
</style>
