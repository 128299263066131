<template>
    <div class="thesudokuapp-bottompanel">
        <app-stop-watch v-show="gameplaySettingsStore.showStopwatch" class="thesudokuapp-stopwatch" />
        <span v-if="gameplaySettingsStore.showStopwatch && currentPuzzleStatsTime && !syncing" class="thesudokuapp-bottompanel-record">
            <svg-icon :icon="currentPuzzleIcon" />
            <span v-html="currentPuzzleStatsTimeFormatted" />
        </span>
        <span
            class="thesudokuapp-bottompanel-puzzleid"
            :class="{ 'clipboard-disabled': clipboardDisabled }"
            @dblclick.prevent="showSudokuSnackbar"
        >
            {{ puzzle }}
        </span>
    </div>
</template>

<script>
    import Stopwatch from './Stopwatch';
    import { utils } from '@/services/thesudokuapp-utils';
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { gameplaySettingsStore, uiStore, snackbarStore, profileStore, sudokuStore } from '@/App/stores';
    import { logger } from '@/services/thesudokuapp-logger';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appStopWatch: Stopwatch,
            svgIcon: SvgIcon
        },
        computed: {
            gameplaySettingsStore() {
                return gameplaySettingsStore;
            },
            syncing() {
                return uiStore.hideBottomPanelPuzzleStats;
            },
            clipboardDisabled() {
                return uiStore.clipboardDisabled;
            },
            puzzle() {
                const
                    hashId = sudokuStore.grid.hashid,
                    difficulty = sudokuStore.grid.difficulty;

                if (!hashId || !difficulty) {
                    return;
                }

                return $t(`difficulty['${difficulty}']`) + ' #' + hashId;
            },
            currentPuzzleStatsTime() {
                return profileStore.current.time;
            },
            currentPuzzleStatsTimeFormatted() {
                return profileStore.current.time ? utils.formatTime(profileStore.current.time) : '';
            },
            currentPuzzleIcon() {
                if (profileStore.current.time) {
                    if (!profileStore.current.autofill
                        && !profileStore.current.smarthints
                        && !profileStore.current.simplehints
                        && !profileStore.current.aftermistakes
                        && !profileStore.current.mistakes
                    ) {
                        return 'trophy';
                    }

                    if (profileStore.current.aftermistakes
                        && !profileStore.current.smarthints
                        && !profileStore.current.simplehints
                    ) {
                        return 'award';
                    }

                    return 'flagcheckered';
                }

                return '';
            }
        },
        methods: {
            showSudokuSnackbar() {
                if (this.clipboardDisabled) {
                    return;
                }

                logger.trackEvent({
                    category: 'interaction',
                    action: 'copy sudoku snackbar'
                });

                snackbarStore.showSnackbar({
                    primaryMessage: $t('snackbar.showsudoku.primary', { hashId: sudokuStore.grid.hashid }),
                    secondaryMessage: sudokuStore.grid.grid,
                    actionCaption: $t('snackbar.showsudoku.copysudoku'),
                    actionCallback: () => {
                        navigator.clipboard.writeText(sudokuStore.grid.grid);
                    },
                    secondaryActionCaption: $t('snackbar.showsudoku.copyid'),
                    secondaryActionCallback: () => {
                        navigator.clipboard.writeText('#' + sudokuStore.grid.hashid);
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-scssvars' as *;

    .thesudokuapp-bottompanel {
        display: flex;
        //justify-content: center;
        align-items: center;
        //user-select: none;

        font-size: 0.45rem;
        margin-bottom: var(--bottompanel-margin);
        height: var(--bottompanel-height);
        width: var(--board-size);
        //border-radius: var(--border-radius);
        //box-shadow: 0 0 2px 0 var(--accent-color-transparent-dark);
        //background-color: var(--cell-background-color);

        .thesudokuapp-bottompanel-record {
            display: flex;
            align-items: center;

            svg {
                display: inline-block;
                //width: 1em;
                height: 1em;
                margin-right: 0.3em;
                margin-top: 0.2em;

            }
        }
    }

    .thesudokuapp-bottompanel-puzzleid {
        margin-left: auto;
        user-select: none;
        cursor: pointer;
    }

    .clipboard-disabled {
        user-select: auto;
        cursor: auto;
    }

    @media #{$thesudokuapp-portrait} {
        .thesudokuapp-bottompanel {
            font-size: 0.46rem;
        }
    }
</style>
