import { logger } from './thesudokuapp-logger';
import { $t } from './thesudokuapp-i18n';

class Utils {
    static _titleTag;

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /*
    static isOffline() {
        return window.navigator.onLine === false;
    }

    static isPwa() {
        return matchMedia('(display-mode: standalone)').matches || (window.navigator.standalone === true);
    }*/

    static pwaType() {
        if ('thesudokuapp' in window && 'device' in window.thesudokuapp && window.thesudokuapp.device.isMobile === false) {
            return 'desktop';
        }

        return 'mobile';
    }

    /*static reducedMotion() {
        return matchMedia('(prefers-reduced-motion: reduce)').matches;
    }*/

    static updateLocationAndTitle({ emoji, gridid, difficulty }) {
        if (!Utils._titleTag) {
            Utils._titleTag = document.getElementsByTagName('title')[0];
        }

        if (!emoji) {
            window.history.pushState(null, null, '/' + difficulty + '-puzzle-' + gridid);
        }

        Utils._titleTag.innerHTML = (emoji ? emoji + ' ' : '') + $t(`difficulty.${difficulty}`) + ' ' + $t('misc.puzzle') +  ' #' + gridid + ' @ ' + window.location.hostname;
    }

    static share({ difficulty, hashid, time }) {
        const url = document.location.protocol + '//' + document.location.host + '/' +  difficulty + '-puzzle-' + hashid;
        let title;

        if (time) {
            title = $t('newrecordshare.difficulty', {
                difficulty: $t(`difficulty.${difficulty}`),
                time
            });
        }

        logger.trackEvent({
            category: 'interaction',
            action: 'share',
            eventName: url
        });

        return window.navigator.share({
            url,
            title
        }).catch();
    }

    static parseTime = time => {
        // All we love those magic numbers
        let days = 0;
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        const currentTimer = time;

        if (currentTimer < 60) {
            seconds = currentTimer;
        } else if (currentTimer < 3600) {
            minutes = Math.floor(currentTimer / 60);
            seconds = currentTimer - minutes*60;
        } else if (currentTimer < 86400) {
            hours = Math.floor(currentTimer / 3600);
            minutes = Math.floor((currentTimer - 3600 * hours) / 60);
            seconds = currentTimer - hours * 3600 - minutes * 60;
        } else {
            const tempDate = new Date(currentTimer * 1000);
            days = tempDate.getUTCDate() - 1;
            hours = tempDate.getUTCHours();
            minutes = tempDate.getUTCMinutes();
            seconds = tempDate.getUTCSeconds();
        }

        return {
            days,
            hours,
            minutes,
            seconds
        };
    };

    static formatTime = time => {
        const parsedTime = Utils.parseTime(time);

        let res = parsedTime.minutes.toString().padStart(2, '0') + ':' + parsedTime.seconds.toString().padStart(2, '0');

        if (parsedTime.hours) {
            res = parsedTime.hours + 'h ' + res;
        }

        if (parsedTime.days) {
            res = parsedTime.days + 'd ' + res;
        }

        return res;
    };

    static isObject = value => {
        const _toString = Object.prototype.toString;
        const res = _toString.call(value).slice(8, -1);

        return res === 'Object';
    };

    static isArray = value => {
        const _toString = Object.prototype.toString;
        const res = _toString.call(value).slice(8, -1);

        return res === 'Array';
    };

    static random = (min, max) => {
        // https://learn.javascript.ru/task/random-int-min-max
        let rand = min - 0.5 + Math.random() * (max - min + 1);
        return Math.round(rand);
    };

    static capitalize = ([firstLetter, ...restOfTheWord]) => firstLetter.toUpperCase() + restOfTheWord.join('');
}

export { Utils as utils };
