import { logger } from '@/services/thesudokuapp-logger';
import { miscStore } from '@/App/stores';
import { processAdClicks } from '@/services/thesudokuapp-adclickcount';

const pwaAction = async () => {
    /**
     * @type {BeforeInstallPromptEvent}
     */
    const event = miscStore.beforeInstallPromptEvent;

    event.prompt();

    const { outcome } = await event.userChoice;

    if (outcome === 'dismissed') {
        logger.trackEvent({
            category: 'interaction',
            action: 'appinstalled dismissed'
        });
    }
};

const initPwa = () => {
    /* https://developers.google.com/web/fundamentals/app-install-banners/ */
    window.addEventListener('appinstalled', () => {
        if (!(window.thesudokuapp.appType === 'msstore')) {
            logger.trackEvent({
                category: 'interaction',
                action: 'appinstalled',
                eventValue: 100
            });
        }

        /*if (window.thesudokuapp.gclid) {
            window.gtag('event', 'conversion', {'send_to': 'AW-10963732466/9hj7CMDy7YkYEPKP9eso'});
        }*/

        miscStore.beforeInstallPromptEvent = undefined;
    });

    window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault();
        miscStore.beforeInstallPromptEvent = event;
        void processAdClicks();
    });
};

export { initPwa, pwaAction };
