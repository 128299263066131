import { stopwatchStore, uiStore, sudokuStore, gameplaySettingsStore } from '@/App/stores';
import { parse, solve, hints } from '@/services/thesudokuapp-hints';

const keyboardMixin = {
    data() {
        return {
            cheatcode: '',
            shiftPressed: false
        };
    },
    mounted() {
        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('keydown', this.keyDown);
    },
    unmounted() {
        document.removeEventListener('keyup', this.keyUp);
        document.removeEventListener('keydown', this.keyDown);
    },
    methods: {
        keyDown(event) {
            if (!event.key) {
                return;
            }

            if (uiStore.waiting) {
                return;
            }

            if (event.key.toLowerCase() === 'shift') {
                uiStore.switchHintMode();
                this.shiftPressed = true;
            }
        },
        keyUp(event) {
            if (!event.key) {
                // Chrome90 вызывает событие, если в текстинпуте выбрать предложенный вариант из выпадающего списка
                return;
            }

            if (uiStore.waiting || Object.values(uiStore.components).some(Boolean)) {
                return;
            }

            uiStore.switchUIState();

            if (event.key.toLowerCase() === 'shift' && this.shiftPressed) {
                uiStore.switchHintMode();
                this.shiftPressed = false;
                return;
            }

            if (event.key.length === 1) {
                this.cheatcode = (this.cheatcode.length >= 5 ? this.cheatcode.substring(1, 5) : this.cheatcode) + event.key;
            }

            if (['p', 'P', 'з', 'З'].includes(event.key)) {
                stopwatchStore.toggleStopwatch();

                return;
            }

            if (/^(pars[0-9]|par[1-9][012])$/.test(this.cheatcode.toLowerCase())) {
                parse(parseInt((this.cheatcode.toLowerCase().match(/pars?([0-9][0123]?)$/) || [])[1]));
                this.cheatcode = '';
                return;
            } else if (['solve', 'ыщдму'].includes(this.cheatcode.toLowerCase())) {
                uiStore.hintMode = false;
                solve();
                this.cheatcode = '';
                return;
            } else if (/^(hint[0-9]|hin[1-9][012])$/.test(this.cheatcode.toLowerCase())) {
                hints(parseInt((this.cheatcode.toLowerCase().match(/hint?([0-9][0123]?)$/) || [])[1]));
                this.cheatcode = '';
                return;
            }

            if (uiStore.gameControlsDisabled) {
                return;
            }

            const selectedCellId = sudokuStore.selectedCellId;
            const currentKey = event.code.toLowerCase().replace('digit', '');

            if (
                ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(currentKey)
                || currentKey === 'backspace'
                || currentKey === 'delete'
            ) {
                const
                    selectedCellId = sudokuStore.selectedCellId,
                    hoveredCellId = document.querySelector('.sudoku-board-cell:hover')?.id.substring(4) || '';

                if (hoveredCellId && !selectedCellId) {
                    sudokuStore.hoveredCellId = hoveredCellId;
                }

                let cellId = selectedCellId || hoveredCellId;

                if (cellId && (sudokuStore.predefinedValues[cellId] || (gameplaySettingsStore.checkNewValues && sudokuStore.selectedValues[cellId]))) {
                    cellId = '';
                }

                if (currentKey === 'backspace' || currentKey === 'delete' || currentKey === '0') {
                    if (cellId) {
                        sudokuStore.clearCell(cellId);
                    } else {
                        sudokuStore.lastSelectedHintId = 0;
                    }

                    return;
                }

                const hintId = parseInt(currentKey);

                if (!cellId) {
                    sudokuStore.hoveredCellId = ''; // ввели хинт через ховер, убрали курсор за пределы поля, нажали 1-9 и если подсвечиваем неправильные значения и выделен пирселлс, может быть красное
                    sudokuStore.lastSelectedHintId = (sudokuStore.lastSelectedHintId === hintId) ? 0 : hintId;
                    return;
                }

                if (uiStore.hintModeOn) {
                    sudokuStore.updateCellHint({ cellId, hintId });
                } else {
                    sudokuStore.updateCellValue({ cellId, hintId });
                }

                return;
            }

            if (['s', 'S', 'ы', 'Ы', 'n', 'N', 'т', 'Т'].includes(event.key) || event.keyCode === 32) {
                // NumbersFirst bug: we update selectNumberFirst in switchHintMode
                // And after that click on number runs selectNumberFirst again
                if (event.keyCode === 32) {
                    event.preventDefault();
                }

                uiStore.switchHintMode();
                return;
            }

            if (event.keyCode === 27 && selectedCellId) { // esc
                sudokuStore.selectCell();
                return;
            }

            if ([37, 38, 39, 40].includes(event.keyCode)) {
                const
                    cols = '123456789123456789',
                    rows = 'ABCDEFGHIABCDEFGHI';

                if (!selectedCellId) {
                    sudokuStore.selectCell('A1');
                    return;
                }

                if (event.keyCode === 37) { // left
                    sudokuStore.selectCell(selectedCellId[0] + cols[cols.lastIndexOf(selectedCellId[1]) - 1]);
                } else if (event.keyCode === 38) { // up
                    sudokuStore.selectCell(rows[rows.lastIndexOf(selectedCellId[0]) - 1] + selectedCellId[1]);
                } else if (event.keyCode === 39) { // right
                    sudokuStore.selectCell(selectedCellId[0] + cols[cols.indexOf(selectedCellId[1]) + 1]);
                } else if (event.keyCode === 40) { // down
                    sudokuStore.selectCell(rows[rows.indexOf(selectedCellId[0]) + 1] + selectedCellId[1]);
                }
            }
        }
    }
};

export { keyboardMixin };
