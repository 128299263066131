<template>
    <div class="thesudokuapp-controls">
        <transition-group name="slide">
            <app-numpad
                v-if="showNumpad"
                :key="1001"
                :second-numpad="false"
                :show-second-numpad="showSecondNumpad"
                :show-values-counter-on-numpad="showValuesCounterOnNumpad"
                :number-first="numberFirst"
                :check-new-values="checkNewValues"
                :highlight-same-values="highlightSameValues"
                :game-controls-disabled="gameControlsDisabled || !initfinished"
                :hint-mode-on="hintModeOn"
            />
            <app-numpad
                v-if="showSecondNumpad"
                :key="1002"
                :second-numpad="true"
                :show-second-numpad="showSecondNumpad"
                :show-values-counter-on-numpad="showValuesCounterOnNumpad"
                :number-first="numberFirst"
                :check-new-values="checkNewValues"
                :highlight-same-values="highlightSameValues"
                :game-controls-disabled="gameControlsDisabled || !initfinished"
                :hint-mode-on="hintModeOn"
            />
            <div :key="1003" class="thesudokuapp-controls-buttons">
                <button class="svgbutton svgbutton-with-bottomtext" :disabled="gameControlsDisabled || !initfinished || !canUndo" @click.stop="undo">
                    <svg-icon icon="undoredo" class="thesudokuapp-svg" />
                    <span class="svgbutton-text">{{ $t('action.undo') }}</span>
                </button>
                <button class="svgbutton svgbutton-with-bottomtext svgbutton-last" :disabled="gameControlsDisabled || !initfinished || !canRedo" @click.stop="redo">
                    <svg-icon icon="undoredo" class="thesudokuapp-svg" style="transform: rotateY(180deg)" />
                    <span class="svgbutton-text">{{ $t('action.redo') }}</span>
                </button>
                <button
                    v-if="!showSecondNumpad"
                    class="svgbutton sudoku-pencil-button svgbutton-with-bottomtext"
                    :class="{ 'sudoku-pencil-button-on': hintModeOn }"
                    :disabled="gameControlsDisabled || !initfinished"
                    @click.stop="switchHintMode"
                >
                    <svg-icon icon="pencil" class="svgbutton-svg" />
                    <span class="svgbutton-text">{{ $t('action.notes') }}<span class="sudoku-pencil-button-indicator">{{ hintModeOn ? $t('misc.on') : $t('misc.off') }}</span></span>
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script>
    import Numpad from './Numpad.vue';
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { gameplaySettingsStore, miscStore, sudokuStore, uiStore } from '@/App/stores';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appNumpad: Numpad,
            svgIcon: SvgIcon
        },
        computed: {
            showNumpad() {
                return gameplaySettingsStore.showNumpad;
            },
            showSecondNumpad() {
                return gameplaySettingsStore.showSecondNumpad;
            },
            showValuesCounterOnNumpad() {
                return gameplaySettingsStore.showValuesCounterOnNumpad;
            },
            numberFirst() {
                return gameplaySettingsStore.numberFirst;
            },
            highlightSameValues() {
                return gameplaySettingsStore.highlightSameValues;
            },
            checkNewValues() {
                return gameplaySettingsStore.checkNewValues;
            },
            gameControlsDisabled() {
                return uiStore.gameControlsDisabled;
            },
            hintModeOn() {
                return uiStore.hintModeOn;
            },
            canUndo() {
                return sudokuStore.canUndo;
            },
            canRedo() {
                return sudokuStore.canRedo;
            },
            initfinished() { return miscStore.initfinished; }
        },
        methods: {
            $t,
            undo: sudokuStore.undo,
            redo: sudokuStore.redo,
            switchHintMode: uiStore.switchHintMode,
        },
    };
</script>

<style lang="scss">
    @use '~@/scss/thesudoku-scssvars' as *;

    .thesudokuapp-controls {
        // 1. Имеем transition group.
        // 2. Когда элемент исчезает, он анимируется правильно, но элемент под ним перемещается мгновенно, а не плавно
        // 3. Чтобы этого не было, вынуждены поставить элементу position: absolute (см. класс .slide-leave-active)
        // 4. Однако теперь исчезающий элемент позиционируется относительно ближайшего контейнера с relative/absolute
        // 5. Можно задать жестко width у элемента группы, а можно divу от vue-транзишн поставить relative и у дочерних
        //     элементов ставить position: relative, top: 0, bottom: 0 (см. класс .sudoku-numpad)
        position: relative;
        min-width: inherit;
        width: var(--board-size);

        .thesudokuapp-controls-buttons {
            display: flex;
            align-items: flex-end;

            button:disabled {
                cursor: default;
            }
        }
    }

    .with-secondnumpad {
        .thesudokuapp-controls-buttons {
            justify-content: center;
        }
    }

    @media #{$thesudokuapp-portrait} {
        .thesudokuapp-controls {
            margin-bottom: var(--bottompanel-margin);
        }
    }

    @media #{$thesudokuapp-portrait-tablet}, #{$thesudokuapp-portrait-tablet-ipad} {
        .thesudokuapp-controls {
            margin-bottom: var(--bottompanel-margin);
        }

        .with-secondnumpad {
            .thesudokuapp-controls {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }

            .thesudokuapp-numpad {
                order: 1;

                &.thesudokuapp-second-numpad {
                    order: 3;
                }
            }

            .thesudokuapp-controls-buttons {
                order: 2;
            }
        }
    }

    @media #{$thesudokuapp-landscape} {
        .with-numpad {
            .thesudokuapp-controls {
                position: absolute;
                min-width: initial;
                top: 0;
                right: 0;
                width: var(--numpad-width);
                height: var(--board-size);
                display: flex;
                flex-direction: column;

                .thesudokuapp-controls-buttons {
                    //margin-top: auto;
                    padding-left: 3.3%;
                    width: 100%;
                }
            }

            &.with-gridlabels .thesudokuapp-controls {
                top: var(--gridlabels-size);
            }
        }

        .with-secondnumpad {
            .thesudokuapp-controls {
                justify-content: space-between;
            }
        }
    }

</style>
