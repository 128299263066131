// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-container-with-scrim {
  position: absolute;
  top: 0;
  min-width: inherit;
  min-height: var(--min-height);
  width: var(--board-size);
  height: var(--board-size);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thesudokuapp-container-scrim {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color-transparent);
  will-change: opacity;
  opacity: 0.4;
}
.thesudokuapp-container-pause {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--dark-background-font-color);
  background-color: var(--accent-color-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-width) solid var(--accent-color);
}
.thesudokuapp-svgbutton-paused-svg {
  height: 1em;
}
@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
.thesudokuapp-container-pause {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-color: var(--accent-color-transparent);
}
}`, "",{"version":3,"sources":["webpack://./App/components/ContainerWithScrim.vue"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,MAAA;EACA,kBAAA;EACA,6BAAA;EACA,wBAAA;EACA,yBAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AAII;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,oBAAA;EACA,YAAA;AADR;AAII;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,wCAAA;EACA,iDAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qDAAA;AADR;AAII;EACI,WAAA;AADR;AAII;AACI;IACI,kCAAA;YAAA,0BAAA;IACA,iDAAA;AADV;AACF","sourcesContent":["\n    .thesudokuapp-container-with-scrim {\n        position: absolute;\n        top: 0;\n        min-width: inherit;\n        min-height: var(--min-height);\n        width: var(--board-size);\n        height: var(--board-size);\n        z-index: 2;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        //border: var(--border-width) solid transparent;\n    }\n\n    .thesudokuapp-container-scrim {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        background-color: var(--accent-color-transparent);\n        will-change: opacity;\n        opacity: 0.4;\n    }\n\n    .thesudokuapp-container-pause {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        color: var(--dark-background-font-color);\n        background-color: var(--accent-color-transparent);\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border: var(--border-width) solid var(--accent-color);\n    }\n\n    .thesudokuapp-svgbutton-paused-svg {\n        height: 1em;\n    }\n\n    @supports (backdrop-filter: blur(5px)) {\n        .thesudokuapp-container-pause {\n            backdrop-filter: blur(5px);\n            background-color: var(--accent-color-transparent);\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
