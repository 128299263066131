<template>
    <div class="feedback-form-container">
        <app-arrow-close-button label="Close feedback form" @closed="close" />
        <div>
            <header class="feedback-header">{{ $t('feedback.header.title') }}</header>

            <p class="feedback-customer-message">{{ $t('feedback.header.message') }}</p>

            <form class="sudoku-form" @submit.prevent="submit">
                <div class="input-wrapper">
                    <label for="feedback-form-name" class="sudoku-form-label">{{ $t('feedback.message.name') }}</label>
                    <input
                        id="feedback-form-name"
                        v-model="feedback.name"
                        class="sudoku-form-control" type="text" name="Name"
                        :placeholder="$t('feedback.optionalValue')"
                        :disabled="waiting || signedIn || success"
                    >
                </div>

                <div class="input-wrapper">
                    <label for="feedback-form-email" class="sudoku-form-label">{{ $t('feedback.message.email') }}</label>
                    <input
                        id="feedback-form-email"
                        v-model="feedback.email"
                        class="sudoku-form-control"
                        type="email"
                        name="Email"
                        :placeholder="$t('feedback.optionalValue')"
                        :disabled="waiting || signedIn || success"
                    >
                </div>

                <div class="input-wrapper">
                    <label for="feedback-form-message" class="sudoku-form-label">{{ $t('feedback.message.feedback') }}</label>
                    <textarea
                        id="feedback-form-message"
                        ref="textareaRef"
                        v-model="feedback.message"
                        required
                        class="sudoku-form-control"
                        name="Message"
                        minlength="5"
                        :disabled="waiting || success"
                    />
                </div>

                <button
                    id="feedback-form__submit"
                    class="sudoku-text-button invert thesudokuapp-submit"
                    type="submit"
                    :disabled="waiting || success"
                >
                    <span v-show="!waiting">{{ $t('feedback.message.submit') }}</span>
                    <app-spinner v-show="waiting" />
                </button>
            </form>
        </div>
    </div>
</template>

<script>
    import ArrowCloseButton from './ArrowCloseButton';
    import Spinner from './Spinner';
    import { uiStore, snackbarStore, miscStore } from '@/App/stores';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { api } from '@/services/thesudokuapp-api';
    import { storageGet, storageSet } from '@/services/thesudokuapp-storage';

    export default {
        components: {
            appArrowCloseButton: ArrowCloseButton,
            appSpinner: Spinner
        },
        data() {
            return {
                feedback: {
                    name: '',
                    email: '',
                    message: ''
                },
                success: false,
                signedIn: false
            };
        },
        computed: {
            waiting() { return uiStore.waiting; },
        },
        async mounted() {
            const feedback = await storageGet.feedback();

            if (miscStore.feedbackTemplate) {
                this.feedback.message = miscStore.feedbackTemplate;
                miscStore.feedbackTemplate = '';
            } else if (feedback?.message) {
                this.feedback.message = feedback.message;
            }

            if (window.thesudokuapp.user?.name && window.thesudokuapp.user?.email) {
                this.signedIn = true;

                this.feedback.name = window.thesudokuapp.user.name;
                this.feedback.email = window.thesudokuapp.user.email;
            } else if (feedback) {
                if (feedback.email) {
                    this.feedback.email = feedback.email;
                }
                if (feedback.name) {
                    this.feedback.name = feedback.name;
                }
            }
        },
        beforeUnmount() {
            const data = {};

            if (!this.signedIn) {
                data.email = this.feedback.email ?? null;
                data.name = this.feedback.name ?? null;
            }

            if (!this.success) {
                data.message = this.feedback.message ?? null;
            }

            if (Object.keys(data).length) {
                void storageSet.feedback(data);
            } else {
                void storageSet.feedback();
            }
        },
        methods: {
            focus() { this.$refs.textareaRef.focus(); },
            $t,
            submit() {
                const data = {};
                if (this.feedback.name) {
                    data.Name = this.feedback.name;
                }
                if (this.feedback.email) {
                    data.Email = this.feedback.email;
                }
                data.Message = this.feedback.message;
                data.Type = 'feedback';

                void this.sendFeedbackToServer(data);
            },
            async sendFeedbackToServer(data) {
                try {
                    uiStore.toggleWaiting();
                    await api.feedback(data);
                    uiStore.toggleWaiting();

                    this.success = true;

                    await snackbarStore.showAsyncSnackbar({
                        primaryMessage: $t('feedback.success[0]'),
                        secondaryMessage: this.feedback.email ? $t('feedback.success[1]') : undefined
                    });

                    uiStore.switchUIState();
                } catch (error) {
                    uiStore.toggleWaiting();
                    const snackbarResolved = await api.apiAsyncCatch({ error, source: 'feedback' });

                    if (snackbarResolved === 'action') {
                        void this.sendFeedbackToServer(data);
                    }
                }
            },
            close() {
                uiStore.switchUIState();
            }
        }
    };
</script>

<style lang="scss">
    .feedback-form-container {
        align-items: center;
        justify-content: center;
    }

    .sudoku-form-control::placeholder {
        color: var(--predefined-value-color);
        opacity: initial;
    }

    .sudoku-form-label {
        display: block;
        width: 100%;
        text-align: left;
        margin-bottom: 0.15rem;
        color: var(--dark-background-font-color);
        text-transform: lowercase;
        font-weight: bold;
    }

    .input-wrapper:focus-within .sudoku-form-label {
        text-shadow: 0 0 0.3rem var(--success-color);
    }

    .sudoku-form-control {
        display: inline-block;
        margin-bottom: 0.4rem;
        font-size: 0.4rem;
        padding: 0.1rem;
        width: 100%;
        border: 2px solid transparent;
        border-radius: 0.1rem;
        background-color: var(--light-background-transparent);
        color: rgb(0, 0, 0);
        font-family: inherit;

        &:focus:not(:invalid) {
            border: 2px solid var(--success-color);
        }

        &:invalid {
            box-shadow: none;
            border: 2px solid #E25A76;
        }
    }

    #feedback-form-message {
        height: 3rem;
        overflow: auto; // IE Fix
    }

    .feedback-form-error {
        background-color: var(--dark-background-transparent);
        border-radius: var(--border-radius);
        color: var(--danger-color);
        //margin-bottom: 0.4rem; /* TODO: !падает из-за csso */
        padding: 0.3rem;
    }

    .feedback-form-success {
        background-color: var(--dark-background-transparent);
        border-radius: var(--border-radius);
        color: var(--success-color);
        padding: 0.4rem;
        font-size: 0.8rem;
    }

    .sudoku-spinner-dark-background {
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .feedback-customer-message {
        display: block;
        max-width: 75%;
        margin: auto auto 0.5rem;
        line-height: 1.2em;
    }

    .feedback-header {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .thesudokuapp-submit {
        display: flex;
        align-items: center;
        margin: auto;
        height: 1.4rem;
        min-width: 2.5rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        justify-content: center;

        .thesudokuapp-spinner {
            font-size: 0.35rem;
        }
    }
</style>
