import { utils } from './thesudokuapp-utils';
import { snackbarStore, gameplaySettingsStore, miscStore, sudokuStore } from '@/App/stores';
import { logger } from '@/services/thesudokuapp-logger';
import { $t } from '@/services/thesudokuapp-i18n';

class TheSudokuAppApi {
    #retryCount = 5;
    #retryDelay = 2000;
    #app;

    setApp(app) {
        this.#app = app;
    }

    feedback(data) {
        data.Metadata = this._getMetadata();
        return this._api('/feedback/', data, { method: 'PUT'});
    }

    telegram(message) {
        const data = {};

        data.Message = message;
        data.Metadata = this._getMetadata();
        data.Type = 'log';

        this.feedback(data).catch(() => {});
    }

    signout() {
        return this._api('/oauth_google_signout/', null, { method: 'POST' });
    }

    signInWithEmail(data) {
        return this._api('/email_signin/', data, { method: 'PUT'});
    }

    confirmCode(data) {
        return this._api('/email_signin/', data, { method: 'POST'});
    }

    syncPut(data) {
        return this._api('/sync/', data, { method: 'PUT'});
    }

    syncGet(date = null, bAll = false) {
        const queryParam = bAll ? '?all=1' : (date ? `?date=${date}` : '');

        return this._api('/sync/' + queryParam);
    }
    /*
    newuser(data) {
        return this._api('/user/', data, { method: 'PUT'});
    }

    updateuser(userid) {
        return this._api(`/user/${userid}`, undefined, { method: 'POST'});
    }*/

    /**
     * @param data
     * @returns {Promise<gridFromServer>}
     */
    rawgrid(data) {
        data.Metadata = this._getMetadata();
        return this._api('/rawgrid', data, { method: 'PUT'});
    }

    /**
     * @param data
     * @returns {Promise<gridFromServer>}
     */
    ocrgrid(data) {
        data.Metadata = this._getMetadata();
        return this._api('/ocrgrid', data, { method: 'PUT'});
    }

    lang(lang) {
        return this._api(`/lang/${lang}`);
    }

    getId(id) {
        return this._api(`/puzzle-${id}`);
    }

    getRandom(difficulty) {
        //return this._api(`/${difficulty}-random-puzzle`);
        return this._api(`/${difficulty}`);
    }

    /**
     * @param {ApiError} error
     * @param {string} source
     * @param {?string} additionalSecondaryMessage
     * @returns {Promise<'action'|'secondaryAction'|false>}
     */
    async apiAsyncCatch({ error, source, additionalSecondaryMessage }) {
        let
            primaryMessage,
            secondaryMessage = [],
            actionCaption = this.#app.$t('snackbar.action.retry');

        if (navigator.onLine === false) {
            primaryMessage = this.#app.$t('snackbar.offline[0]');
            secondaryMessage.push(this.#app.$t('snackbar.offline[1]'));
        } else if (/5\d\d$/.test(error.toString())) {
            primaryMessage = this.#app.$t('snackbar.maintenance[0]');
            secondaryMessage.push(this.#app.$t('snackbar.maintenance[1]'));
        } else {
            primaryMessage = this.#app.$t('snackbar.unknown[0]');
            secondaryMessage.push(this.#app.$t('snackbar.unknown[1]'));
        }

        if ([ 'syncRecordsFromServer', 'getUnsyncedRecordsAndPatchStorage', 'syncUnsyncedRecordsToServer' ].includes(source)) {
            if (navigator.onLine === false) {
                secondaryMessage.push(this.#app.$t('snackbar.signinsyncerror[2]'));
            } else {
                primaryMessage = this.#app.$t('snackbar.signinsyncerror[0]');
            }
        } else if (source === 'new random by xqr') {
            secondaryMessage.push(additionalSecondaryMessage);
        } else if (source === 'new id by xqr' && error.status === 404) {
            primaryMessage = this.#app.$t('snackbar.notfound');
            actionCaption = undefined;
            secondaryMessage = [];
        } else if (source === 'import_serverexception' && !/5\d\d$/.test(error.toString()) && !(error.json?.primary || error.json?.secondary)) {
            if (!error.json?.primary) {
                this.telegram('No primary field after failed import ' + error.toString() + ' response');
            }

            if (!error.json?.secondary) {
                this.telegram('No secondary field after failed import ' + error.toString() + ' response');
            }
        } else if (source === 'emailsignin' && error.status === 400) {
            primaryMessage = $t('snackbar.emailsigninwrongdata[0]');
            secondaryMessage = [$t('snackbar.emailsigninwrongdata[1]')];
        } else if (source === 'emailconfirm') {
            if (error.status === 403) {
                primaryMessage = $t('snackbar.emailconfirmwrongdata[1]');
                secondaryMessage = null;
            }

            if (error.status === 401) {
                primaryMessage = $t('snackbar.emailconfirmwrongdata[0]');
                secondaryMessage = null;
            }
        }

        logger.trackEvent({
            category: 'exception:api',
            action: source,
            eventName: error.status
        });

        const snackBarResolved = await snackbarStore.showAsyncSnackbar({
            primaryMessage,
            secondaryMessage,
            actionCaption,
            isWarning: true
        });

        snackbarStore.hideSnackbar();

        return snackBarResolved;
    };

    async _api_retry(url, config) {
        let error;

        for (let i = 0; i < this.#retryCount; i++) {
            try {
                if (i > 0) {
                    await utils.sleep(this.#retryDelay);
                }
                return await fetch(`${url}`, config);
            } catch (currentError) {
                error = currentError;
            }
        }

        throw error;
    }

    async _api(url, data = null,
        {
            method = 'GET',
            headers = {
                'Accept': 'application/json'
            }
        } = {}) {

        const config = {
            method,
            headers
        };

        if (data && (headers.method !== 'GET')) {
            config.body = JSON.stringify(data);
            headers['Content-Type'] = 'application/json;charset=utf-8';
        }

        const res = await this._api_retry(url, config);

        if (!res.ok) {
            let
                body = await res.text(),
                json = {};

            if (body) {
                try {
                    json = JSON.parse(body);

                    if (!json) {
                        json = {};
                    }
                } catch (error) {
                    json = {
                        body
                    };
                }
            }

            throw {
                name: 'ApiError',
                status: res.status,
                json,
                toString() {
                    return this.status.toString();
                }
            };
        }

        if (res.status === 204) {
            return true;
        }

        return await res.json();
    }

    _getMetadata() {
        return {
            grid: {
                Grid: sudokuStore.grid.grid,
                GridID: sudokuStore.grid.hashid,
                Difficulty: sudokuStore.grid.difficulty
            },
            settings: { ...gameplaySettingsStore.$state },
            locationHref: window.location.href,
            maVisitorId: miscStore.matomoId,
            userAgent: navigator.userAgent,
            screen: screen.width + ' x ' + screen.height,
            referrer: document.referrer
        };
    }
}

const api = new TheSudokuAppApi();

export { api };
//export default TheSudokuAppApi;
