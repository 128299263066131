import anime from 'animejs';
import { cells, units } from '@/services/thesudokuapp-peers.mjs';
import { gameplaySettingsStore, sudokuStore, uiStore } from '@/App/stores';
import { nextTick } from 'vue';

const closestCells = (cellId, level) => {
    const alpha = ' ABCDEFGHI';

    const cellIdX = parseInt(cellId[1]);
    const cellIdY = alpha.indexOf(cellId[0]);

    const res = [];

    if (cellIdX - level > 0) {
        for (let i = cellIdY-level; i <= cellIdY + level; i++ ) {
            if (i > 0 && i < 10) {
                res.push(alpha[i] + (cellIdX - level));
            }
        }
    }

    if (cellIdX + level < 10) {
        for (let i = cellIdY-level; i <= cellIdY + level; i++ ) {
            if (i > 0 && i < 10) {
                res.push(alpha[i] + (cellIdX + level));
            }
        }
    }


    if (cellIdY - level > 0) {
        for (let i = cellIdX - level + 1; i < cellIdX + level; i++ ) {
            if (i > 0 && i < 10) {
                res.push(alpha[cellIdY - level] + i);
            }
        }
    }


    if (cellIdY + level < 10) {
        for (let i = cellIdX - level + 1; i < cellIdX + level; i++ ) {
            if (i > 0 && i < 10) {
                res.push(alpha[cellIdY + level] + i);
            }
        }
    }

    return res; //document.querySelectorAll(res.join(','));
};

const groupsToAnimate = ({cellId, cellsToAnimate}) => {
    const alpha = ' ABCDEFGHI';
    const cellIdX = parseInt(cellId[1]);
    const cellIdY = alpha.indexOf(cellId[0]);
    const level = Math.max(cellIdX, cellIdY, 9-cellIdX, 9-cellIdY);

    const res = [];

    res.push(document.querySelectorAll('#cell' + cellId));

    for (let i = 1; i <= level; i++) {
        const levelCells = closestCells(cellId, i)
            .filter(cellIDToFilter => cellsToAnimate.has(cellIDToFilter))
            .map(cellIDToMap => '#cell' + cellIDToMap)
            .join();

        if (levelCells) {
            res.push(document.querySelectorAll(levelCells));
        }
    }

    return res;
};

/*
const animations = {
    bounceIn: {
        keyframes: [
            { scale: 0.8 },
            { scale: 1.2 },
            { scale: 0.95 },
            { scale: 1.03 },
            { scale: 0.97 },
            { scale: 1 }
        ],
    },
    shake: {
        keyframes: [
            { translateX: 0 },
            { translateX: -9 },
            { translateX: 9 },
            { translateX: -8 },
            { translateX: 8 },
            { translateX: -7 },
            { translateX: 7 },
            { translateX: -6 },
            { translateX: 6 },
            { translateX: -5 },
            { translateX: 0 }
        ],
        duration: 500,
        easing: 'easeOutCubic'
    }
};

const animate = (el, name) => {
    anime({
        ...animations[name],
        targets: el
    });
};*/

const animateFilledValue = el => {
    //anime.remove(el);
    anime({
        keyframes: [
            { scale: 0.8 },
            { scale: 1.20 },
            { scale: 0.95 },
            { scale: 1.03 },
            { scale: 0.97 },
            { scale: 1 }
        ],
        duration: 1200,
        easing: 'easeInCubic',
        targets: el
    });
};

const animateFilledUnit = (el, colors, duration = 850) => {
    const animation = {
        targets: el,
        keyframes: [
            { backgroundColor: colors['background'] },
            { backgroundColor: colors['highlight'] },
            { backgroundColor: colors['background'] }
        ],
        easing: 'linear',
        duration,
        // this won't work with spread polyfill
        //...((typeof el === 'object' && el.length>1) && { delay: anime.stagger(70, { easing: 'easeInCubic'}) }),
        complete: anim =>
            anim.animatables.forEach(el => {
                el.target.removeAttribute('style');
            })
    };

    if ((typeof el === 'object' && el.length>1)) {
        animation.delay = anime.stagger(70, { easing: 'linear'});
    }

    anime(animation);
};

const animateSmartHint = ({cellId, highlight, cellsToAnimate}) => {
    if (!cellId) {
        cellId = [...cellsToAnimate].sort()[0];
    }

    const cells = groupsToAnimate({cellId, cellsToAnimate});

    const myTimeline = anime.timeline({
        easing: 'linear',
        duration: 250
    });

    cells.forEach(cellsGroup =>
        myTimeline.add({
            targets: cellsGroup,
            backgroundColor: highlight,
        }, '-=175')
    );

    return myTimeline.finished.then(() => () => {
        cells.forEach(groupToAnimate => {
            groupToAnimate.forEach(elNode => elNode.removeAttribute('style'));
        });
    });
};

const animateCellsOnNewValue = ({ cellId, hintId }) => {
    const groupsToAnimate = {
        squareToAnimate: new Set(),
        cellsToTheLeftToAnimate: new Set(),
        cellsToTheRightToAnimate: new Set(),
        cellsToTheTopToAnimate: new Set(),
        cellsToTheBottomToAnimate: new Set()
    };

    let sameValueCellsToAnimate = (sudokuStore.valuesCount[hintId] === 9)
        ? cells.filter(cell => cell !== cellId && (sudokuStore.predefinedValues[cell] === hintId || sudokuStore.selectedValues[cell] === hintId)).map(cellId => '#cell' + cellId + 'c')
        : [];

    let animateUnits = false;

    // unitType: 0 - square, 1 - row, 2 - column
    units[cellId].forEach((unit, unitType) => {
        if (unit.reduce((accumulator, cell) => accumulator + (!!sudokuStore.predefinedValues[cell] || !!sudokuStore.selectedValues[cell]), 0) === 9) {
            animateUnits = true;

            if (unitType === 0) {
                unit.map(cell => {
                    if (cell !== cellId) {
                        groupsToAnimate.squareToAnimate.add('#cell' + cell);
                    }
                });
            } else {
                const cellIdIndex = unit.indexOf(cellId);

                for (let i = cellIdIndex - 1; i >= 0; i--) {
                    if (unitType === 1) {
                        groupsToAnimate.cellsToTheLeftToAnimate.add('#cell' + unit[i]);
                    }
                    if (unitType === 2) {
                        groupsToAnimate.cellsToTheTopToAnimate.add('#cell' + unit[i]);
                    }
                }
                for (let i = cellIdIndex + 1; i < 9; i++) {
                    if (unitType === 1) {
                        groupsToAnimate.cellsToTheRightToAnimate.add('#cell' + unit[i]);
                    }
                    if (unitType === 2) {
                        groupsToAnimate.cellsToTheBottomToAnimate.add('#cell' + unit[i]);
                    }
                }
            }
        }
    });

    const theme = uiStore.theme;
    const duration = uiStore.touchMode ? 850 : 650;

    if ((!uiStore.reducedMotion) && (sameValueCellsToAnimate.length || animateUnits) && (theme in window.thesudokuapp.themes)) {
        if (sameValueCellsToAnimate.length) {
            void nextTick(() => animateFilledValue(sameValueCellsToAnimate));
        }

        if (animateUnits) {
            Object.values(groupsToAnimate).forEach(group => {

                if (group.size) {
                    void nextTick(() => animateFilledUnit(
                        [...group],
                        {
                            background: (gameplaySettingsStore.highlightPeerCells ? window.thesudokuapp.themes[theme]['cssvariables']['highlighted-bypeer-background-color'] : window.thesudokuapp.themes[theme]['cssvariables']['cell-background-color']),
                            highlight: window.thesudokuapp.themes[theme]['cssvariables']['filled-unit']
                            //while anime.js doesn't support css vars
                            //background: (state.gameplaySettings.highlightPeerCells ? 'var(--highlighted-bypeer-background-color)' : 'var(--cell-background-color)'),
                            //highlight: 'var(--filled-unit)'
                        },
                        duration
                    ));
                }
            });
        }
    }

    return !!sameValueCellsToAnimate.length || animateUnits;
};

export { animateSmartHint, animateFilledUnit, animateFilledValue, animateCellsOnNewValue };
