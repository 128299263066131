<template>
    <transition name="thesudokuapp-slidein-transition" @after-enter="focus">
        <div class="thesudokuapp-newgamemenu thesudokuapp-slidein-transition-top">
            <input
                id="puzzleId"
                v-model="hashid"
                :placeholder="$t('misc.puzzle') + ' #'"
                required
                :disabled="waiting"
                @keyup.enter="submit"
            >
            <button class="thesudokuapp-actionbutton" :disabled="waiting" @click="submit">
                <span v-show="!waiting">{{ $t('action.find') }}</span>
                <app-spinner v-show="waiting" />
            </button>
        </div>
    </transition>
</template>
<script>

import Spinner from '@/App/components/Spinner.vue';
import { miscStore, snackbarStore, sudokuStore, uiStore } from '@/App/stores';
import { $t } from '@/services/thesudokuapp-i18n';
import { logger } from '@/services/thesudokuapp-logger';
import { api } from '@/services/thesudokuapp-api';

export default {
    components: {
        appSpinner: Spinner
    },
    data() {
        return {
            hashid: null,
            sudokuboot: false
        };
    },
    computed: {
        show() {
            return uiStore.components.findpuzzlebyidmenu;
        },
        waiting() {
            return uiStore.waiting;
        }
    },
    watch: {
        show(newState) {
            if (newState) {
                if (miscStore.newSudokuHashId) {
                    this.hashid = miscStore.newSudokuHashId;
                    miscStore.newSudokuHashId = '';
                    this.sudokuboot = true;
                    void this.getHashIdFromServer(this.hashid, true);
                } else {
                    this.hashid = null;
                    this.sudokuboot = false;
                }
            }
        }
    },
    methods: {
        focus() { document.getElementById('puzzleId').focus(); },
        $t,
        async submit() {
            if (!this.hashid) {
                snackbarStore.showSnackbar({
                    primaryMessage: $t('snackbar.enterpuzzleno'),
                    timeout: 1750
                });

                return;
            }

            const
                matches = this.hashid.trim().match(/^#?(?<puzzleId>\w{4,6})$/),
                puzzleId = matches?.groups?.puzzleId;

            if (!puzzleId) {
                snackbarStore.showSnackbar({
                    primaryMessage: $t('snackbar.incorrectpuzzleno'),
                    timeout: 2750
                });

                return;
            }

            logger.trackEvent({
                category: 'interaction',
                action: 'find puzzle by id'
            });

            return this.getHashIdFromServer(puzzleId.toLowerCase());
        },
        async getHashIdFromServer(hashid, sudokuboot) {
            try {
                uiStore.toggleWaiting();
                const grid = await api.getId(hashid);
                uiStore.toggleWaiting();

                uiStore.gameover = false;
                uiStore.components.findpuzzlebyidmenu = false;

                sudokuStore.newGame({
                    grid: {
                        hashid: grid.HashID,
                        difficulty: grid.Difficulty,
                        grid: grid.Grid,
                        solution: grid.Solution
                    },
                    type: 'new id by xqr' + (sudokuboot ? ' (sudokuboot)' : ''),
                    sudokuboot
                });
            } catch (error) {
                uiStore.toggleWaiting();

                const snackbarResolved = await api.apiAsyncCatch({
                    error,
                    source: 'new id by xqr'
                });

                if (snackbarResolved === 'action') {
                    return this.getHashIdFromServer(hashid);
                }

                // ветка init: article (grid from tutorial), какая-то проблема с сервером, и снэкбар скрылся
                if (!sudokuStore.grid.hashid) {
                    api.telegram('getHashIdFromServer: ' + hashid);
                }
            }
        }
    }
};
</script>
