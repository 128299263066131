<template>
    <div class="thesudokuapp-spinner"><div class="thesudokuapp-spinner-inner" /></div>
</template>

<style lang="scss">
    .thesudokuapp-spinner-inner, .thesudokuapp-spinner::before, .thesudokuapp-spinner::after {
        width: 1em;
        height: 1em;
        background-color: currentColor;
        border-radius: 100%;
        animation: thesudokuapp-bouncedelay 1.4s infinite ease-in-out both;
    }

    .thesudokuapp-spinner {
        width: 1em;
        height: 1em;
        margin-right: 1.2em;
        margin-left: 1.2em;
        position: relative;
        //line-height: 1em;

        .thesudokuapp-spinner-inner {
            animation-delay: -0.16s;
        }

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
        }

        &::before {
            left: -1.2em;
            animation-delay: -0.32s;
        }

        &::after {
            right: -1.2em;
        }
    }

    @keyframes thesudokuapp-bouncedelay {
        0%, 80%, 100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }
</style>