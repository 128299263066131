// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-svgspinner-container {
  color: var(--accent-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-color-transparent-dark);
  font-size: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./App/components/SvgSpinner.vue"],"names":[],"mappings":"AACI;EACI,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sDAAA;EACA,iBAAA;AAAR","sourcesContent":["\n    .thesudokuapp-svgspinner-container {\n        color: var(--accent-color);\n        border-radius: 50%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: var(--accent-color-transparent-dark);\n        font-size: 0.4rem;\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
