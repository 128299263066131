import { defineStore } from 'pinia';
import { storageSet } from '@/services/thesudokuapp-storage';
import { logger } from '@/services/thesudokuapp-logger';
import { sudokuStore } from '@/App/stores/index';

export const useGameplaySettingsStore = defineStore('gameplaySettings', {
    state: () => ({
        showLabels: false,
        showStopwatch: true,
        autopause: true,
        highlightSameValues: true,
        highlightPeerCells: true,
        clearHintsOnNewValue: true,
        checkNewValues: false,    // не будем переименовывать в allowMistakes, так как у дохуя народа собьются настройки
        highlightMistakes: false, // не будем делать allowAndHighlightMistakes, так как в офердохуя местах проверка только checkNewValues (может быть когда-нибудь)
        showNumpad: true,
        showSecondNumpad: false,
        showValuesCounterOnNumpad: true,
        showInCellValuePicker: false,
        sound: true,
        numberFirst: false
    }),
    getters: {
        mistakesMode: state => {
            if (state.checkNewValues) {
                return 'disallow';
            }

            if (state.highlightMistakes) {
                return 'allowAndHighlight';
            }

            return 'allow';
        }
    },
    actions: {
        toggleStopwatch() {
            this.showStopwatch = !this.showStopwatch;
            this.updateStorageAndLog('stopwatch', this.showStopwatch);
        },
        toggleAutopause() {
            this.autopause = !this.autopause;
            this.updateStorageAndLog('autopause', this.autopause);
        },
        toggleNumpad() {
            this.showNumpad = !this.showNumpad;

            if (this.showNumpad) {
                document.documentElement.classList.add('with-numpad');
                window.thesudokuapp.removeCookie('thesudokuapp_withoutnumpad');
            } else {
                this.showSecondNumpad = false;
                document.documentElement.classList.remove('with-numpad');
                document.documentElement.classList.remove('with-secondnumpad');
                window.thesudokuapp.setCookie('thesudokuapp_withoutnumpad', 1);
                window.thesudokuapp.removeCookie('thesudokuapp_withsecondnumpad');
            }

            this.updateStorageAndLog('numpad', this.showNumpad);
        },
        toggleSecondNumpad({ overflow } = {}) {
            this.showSecondNumpad = !this.showSecondNumpad;

            if (this.showSecondNumpad) {
                document.documentElement.classList.add('with-secondnumpad');
                window.thesudokuapp.setCookie('thesudokuapp_withsecondnumpad', 1);
            } else {
                document.documentElement.classList.remove('with-secondnumpad');
                window.thesudokuapp.removeCookie('thesudokuapp_withsecondnumpad');
            }

            this.updateStorageAndLog('second numpad', this.showSecondNumpad, overflow);
        },
        toggleValuesCounterOnNumpad() {
            this.showValuesCounterOnNumpad = !this.showValuesCounterOnNumpad;
            this.updateStorageAndLog('values counter', this.showValuesCounterOnNumpad);
        },
        toggleLabels() {
            this.showLabels = !this.showLabels;

            const html = document.getElementsByTagName('html')[0];

            if (this.showLabels) {
                html.classList.add('with-gridlabels');
                window.thesudokuapp.setCookie('thesudokuapp_withgridlabels', 1);
            } else {
                html.classList.remove('with-gridlabels');
                window.thesudokuapp.removeCookie('thesudokuapp_withgridlabels');
            }

            this.updateStorageAndLog('labels', this.showLabels);
        },
        toggleInCellValuePicker() {
            this.showInCellValuePicker = !this.showInCellValuePicker;
            this.updateStorageAndLog('in-cell value picker', this.showInCellValuePicker);
        },
        toggleSound({ overflow } = {}) {
            this.sound = !this.sound;
            this.updateStorageAndLog('sound', this.sound, overflow);
        },
        toggleNumberFirst({ overflow } = {}) {
            this.numberFirst = !this.numberFirst;

            if (this.numberFirst && sudokuStore.selectedCellId) {
                sudokuStore.selectCell();
            }

            this.updateStorageAndLog('number first', this.numberFirst, overflow);
        },
        toggleHighlightSameValues() {
            this.highlightSameValues = !this.highlightSameValues;
            this.updateStorageAndLog('highlight same values', this.highlightSameValues);
        },
        toggleHighlightPeerCells() {
            this.highlightPeerCells = !this.highlightPeerCells;
            this.updateStorageAndLog('highlight peer cells', this.highlightPeerCells);
        },
        toggleClearHintsOnNewValue() {
            this.clearHintsOnNewValue = !this.clearHintsOnNewValue;
            this.updateStorageAndLog('clear hints', this.clearHintsOnNewValue);
        },
        setMistakesMode(type) {
            let action = '';

            if (type === 'allow') {
                this.checkNewValues = false;
                this.highlightMistakes = false;
                action = 'allow mistakes';

                if (sudokuStore.wrongvaluesentered) {
                    sudokuStore.grid.afterlose = true;
                    sudokuStore.wrongvaluesentered = 0;
                    void storageSet.grid(sudokuStore.grid);
                }
            } else if (type === 'allowAndHighlight') {
                this.checkNewValues = false;
                this.highlightMistakes = true;
                action = 'allow and_highlight mistakes';

                if (sudokuStore.wrongvaluesentered) {
                    sudokuStore.grid.afterlose = true;
                    sudokuStore.wrongvaluesentered = 0;
                    void storageSet.grid(sudokuStore.grid);
                }
            } else if (type === 'disallow') {
                sudokuStore.wrongvaluesentered = sudokuStore.getWrongValuesCount || (sudokuStore.grid.afterlose ? 1 : 0);

                if (sudokuStore.grid.afterlose) {
                    sudokuStore.grid.afterlose = false;
                    void storageSet.grid(sudokuStore.grid);
                }

                this.checkNewValues = true;
                this.highlightMistakes = false;
                action = 'disallow mistakes';
            }

            this.updateStorageAndLog(action);
        },
        updateStorageAndLog(key, newValue, overflow) {
            void storageSet.gameplaySettings(this.$state);

            logger.trackEvent({
                category: 'settings' + (overflow ? ': overflow' : ''),
                action: key + (newValue !== undefined ? ' ' + (newValue ? 'on' : 'off') : '')
            });
        },
        fixGameSettingsInconsistencies() {
            let settingsChanged = false;
            const html = document.documentElement;

            if (html.classList.contains('with-gridlabels') && !this.showLabels) {
                this.showLabels = true;
                settingsChanged = true;
            }

            if (html.classList.contains('with-numpad') && !this.showNumpad) {
                this.showNumpad = true;
                settingsChanged = true;
            }

            if (html.classList.contains('with-secondnumpad') && !this.showSecondNumpad) {
                this.showSecondNumpad = true;
                settingsChanged = true;
            }

            if (!html.classList.contains('with-gridlabels') && this.showLabels === true) {
                this.showLabels = false;
                settingsChanged = true;
            }

            if (!html.classList.contains('with-numpad') && this.showNumpad === true) {
                this.showNumpad = false;
                settingsChanged = true;
            }

            if (!html.classList.contains('with-secondnumpad') && this.showSecondNumpad) {
                this.showSecondNumpad = false;
                settingsChanged = true;
            }

            if (settingsChanged) {
                void storageSet.gameplaySettings(this.$state);
            }
        }

    }
});
