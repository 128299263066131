import { profileStore, uiStore } from '@/App/stores';
import { $statsStorage } from '@/services/thesudokuapp-storage-helper';
import { storageGetAndPatchStore, storageSet } from '@/services/thesudokuapp-storage';
import { utils } from '@/services/thesudokuapp-utils';
import { api } from '@/services/thesudokuapp-api';

const getUnsyncedRecordsAndPatchStorage = async ({ u, m }) => {
    const updatedRecords = {};
    const unsyncedRecords = {};

    await $statsStorage.iterate((value, key) => {
        if (!utils.isObject(value) || [ 'easy', 'medium', 'hard', 'expert' ].includes(key)) {
            return;
        }

        let recordUpdated = false, recordUnsynced = false;

        if (value.version === 2) {
            if (!value.synced) {
                recordUnsynced = true;
            }
        } else {
            value.version = 2;
            recordUpdated = true;

            if (value.puzzleId) {
                value.hashid = value.puzzleId;
                delete value.puzzleId;
            }

            if (value.hashId) {
                value.hashid = value.hashId;
                delete value.hashId;
            }

            if (value.date) {
                value.recordtimestamp = value.date;
                delete value.date;
            }

            if (!(value.m || value.synced)) {
                recordUnsynced = true;
            }

            if (!value.synced && value.m) {
                value.synced = true;
            }

            if (!value.u) {
                value.u = u;
            }

            if (!value.m) {
                value.m = m;
            }

            if (!value.difficulty) {
                recordUpdated = true;
            }
        }

        if (recordUpdated) {
            updatedRecords[key] = value;
        }

        if (recordUnsynced) {
            unsyncedRecords[key] = value;
        }
    });

    const updateRecord = async record => {
        if (!record.difficulty) {
            const { Difficulty: difficulty } = await api.getId(record.hashid);
            record.difficulty = difficulty;
        }

        if (unsyncedRecords[record.hashid]) {
            unsyncedRecords[record.hashid] = record;
        }

        await storageSet.puzzleStats(record);
    };

    if (Object.keys(updatedRecords).length > 0) {
        try {
            await Promise
                .all(Object.values(updatedRecords).map(record => updateRecord(record)));
        } catch (error) {
            const snackBarResolved = await api.apiAsyncCatch({
                error,
                source: 'getUnsyncedRecordsAndPatchStorage'
            });

            if (!snackBarResolved || snackBarResolved === 'secondaryAction') {
                return false;
            }

            return getUnsyncedRecordsAndPatchStorage({u, m});
        }
    }

    return Object.values(unsyncedRecords);
};

const storePuzzlesStatsFromServer = async records => {
    const currentStats = { ...profileStore.current };

    for (const stats of records) {
        await storageGetAndPatchStore.currentPuzzleStats(stats.hashid);

        stats.synced = true;
        stats.u = window.thesudokuapp.user.u;

        await Promise.all([
            profileStore.updatePuzzleStats(stats),
            profileStore.updateDifficultyStats(stats)
        ]);
    }

    profileStore.current = currentStats;
};

const syncRecordsFromServer = async () => {
    // 1. no unsyncedRecords and lastsync undefined -> all
    // 2. no unsyncedRecords and lastsync -> >lastsync
    // 3. unsyncedRecords and lastsync undefined ->all и потом sync unsyncedRecords
    // 4. unsyncedRecords and lastsync -> >lastsync  и потом sync unsyncedRecords

    const
        lastsynced = profileStore.lastsynced,
        now = Math.floor(Date.now() / 1000);

    let res;

    try {
        res = await api.syncGet(lastsynced, !lastsynced);
    } catch (error) {
        const snackBarResolved = await api.apiAsyncCatch({ error, source: 'syncRecordsFromServer' });

        if (!snackBarResolved || snackBarResolved === 'secondaryAction') {
            return false;
        }

        return syncRecordsFromServer();
    }

    if (utils.isArray(res) && res.length) {
        uiStore.hideBottomPanelPuzzleStats = true;
        await storePuzzlesStatsFromServer(res);
        uiStore.hideBottomPanelPuzzleStats = false;
    }

    profileStore.lastsynced = now;
    await storageSet.lastsynced(now);

    return true;
};

const syncUnsyncedRecordsToServer = async records => {
    try {
        await api.syncPut({ records });
        records.forEach(puzzleStats => {
            puzzleStats.synced = true;
            storageSet.puzzleStats(puzzleStats);
        });

        return true;
    } catch (error) {
        const snackBarResolved = await api.apiAsyncCatch({ error, source: 'syncUnsyncedRecordsToServer' });

        if (!snackBarResolved || snackBarResolved === 'secondaryAction') {
            return false;
        }

        return syncUnsyncedRecordsToServer();
    }
};

const syncRecords = async () =>  {
    if (!window.thesudokuapp.user) {
        return;
    }

    uiStore.syncing = true;
    uiStore.toggleWaiting();

    //const t0 = performance.now();
    const unsyncedRecords = await getUnsyncedRecordsAndPatchStorage({
        u: window.thesudokuapp.user.u,
        m: window.thesudokuapp.user.m
    });
    //const t1 = performance.now();

    if (unsyncedRecords === false) {
        uiStore.syncing = false;
        uiStore.toggleWaiting();
        return;
    }

    const syncRecordsFromServerOk = await syncRecordsFromServer();

    if (syncRecordsFromServerOk === false) {
        uiStore.syncing = false;
        uiStore.toggleWaiting();
        return;
    }

    if (syncRecordsFromServerOk && unsyncedRecords.length) {
        await syncUnsyncedRecordsToServer(unsyncedRecords);
    }

    uiStore.syncing = false;
    uiStore.toggleWaiting();
};

export { syncRecords };

