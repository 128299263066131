import { ref } from 'vue';

let
    streamStarted = ref(false),
    loadingOpenCV = ref(false);

let onOrientationChange, orientationChangeListener = false, stream, streamWidth, streamHeight;
let cvImage, cvImageDst, approximatedContour, offscreenCanvas, ctx, running = false, gridParams, image;

const loadOpenCV = () => {
    return new Promise(resolve => {
        const script = document.createElement('script');
        script.onload = () => {
            window.cv['onRuntimeInitialized'] = () => {
                resolve();
            };
        };
        script.async = true;
        script.src = '/assets/opencv470.js';
        document.body.appendChild(script);
    });
};

const indexOfMaxValue = (a, t = i => i) => a.reduce((iMax, x, i, arr) => (t(x) > t(arr[iMax]) ? i : iMax), 0);

const arrayRotate = (arr, count) => {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
};

const rotateBottomLeftFirst = coords => {
    const pairs = [];
    for (let i = 0; i < 4; i++) {
        pairs[i] = [coords[i * 2], coords[i * 2 + 1]];
    }

    // calculates the Euclidean distance between the origin (0, 0) and a point represented by the coordinates (p[0], p[1]).
    const bottomRight = indexOfMaxValue(pairs, p => Math.sqrt(p[0] * p[0] + p[1] * p[1]));

    if (bottomRight !== 1) {
        const shift = -(1 - bottomRight);
        return arrayRotate(coords, shift * 2);
    } else {
        return coords;
    }
};

const findGrid = canvas => {
    if (running) {
        return;
    }

    running = true;

    const contours = new window.cv.MatVector();
    const hierarchy = new window.cv.Mat();

    window.cv.cvtColor(cvImage, cvImageDst, window.cv.COLOR_RGBA2GRAY, 0);
    window.cv.adaptiveThreshold(cvImageDst, cvImageDst, 255, window.cv.ADAPTIVE_THRESH_GAUSSIAN_C, window.cv.THRESH_BINARY_INV, 11, 1);
    //window.cv.imshow('thesudokuapp-ocr-canvas', cvImageDst)

    window.cv.findContours(
        cvImageDst,
        contours,
        hierarchy,
        window.cv.RETR_CCOMP,
        window.cv.CHAIN_APPROX_SIMPLE
    );

    const EPSILON = 0.02;

    let
        maxArea = 0,
        contourWithMaxArea;

    for (let i = 0; i < contours.size(); i++) {
        const contour = contours.get(i);

        window.cv.approxPolyDP(contour, approximatedContour, EPSILON * window.cv.arcLength(contour, true), true);

        const contourArea = window.cv.contourArea(approximatedContour);

        if (approximatedContour.size().height === 4 && contourArea > maxArea) {
            maxArea = contourArea;

            if (contourWithMaxArea) {
                contourWithMaxArea.delete();
            }

            contourWithMaxArea = approximatedContour.clone();
        }

        contour.delete();
    }

    //const dimension = Math.round(opencv.cv.arcLength(contours.get(rectangles[idx].contourId), true) / 4);
    gridParams = {
        dimension: Math.round(window.cv.arcLength(contourWithMaxArea, true) / 4),
        coords: rotateBottomLeftFirst([ ...contourWithMaxArea.data32S ]),
        streamWidth,
        streamHeight
    };

    ctx.beginPath();
    ctx.moveTo(gridParams.coords[0], gridParams.coords[1]);
    ctx.lineTo(gridParams.coords[2], gridParams.coords[3]);
    ctx.lineTo(gridParams.coords[4], gridParams.coords[5]);
    ctx.lineTo(gridParams.coords[6], gridParams.coords[7]);
    ctx.lineTo(gridParams.coords[0], gridParams.coords[1]);
    ctx.lineWidth = streamHeight > streamWidth ? Math.round(streamWidth * 0.01) : Math.round(streamHeight * 0.01);
    ctx.strokeStyle = 'rgba(0, 200, 0, 255)';
    ctx.stroke();
    ctx.closePath();
    image = canvas.toDataURL('image/jpeg');

    /*
    const newContours = new window.cv.MatVector();
    newContours.push_back(contourWithMaxArea);

    const color = new window.cv.Scalar(0, 200, 0, 255);

    window.cv.drawContours(
        cvImage,
        newContours,
        0,
        color,
        5,
        window.cv.LINE_8,
        hierarchy,
        0
    );

    newContours.get(0).delete();
    newContours.delete();
    */

    contourWithMaxArea.delete();
    contours.delete();
    hierarchy.delete();

    running = false;
};

const transformPerspective = canvas => {
    const
        size = gridParams.dimension,
        dstCoords = [ 0, size, size, size, size, 0, 0, 0 ],
        img_warped = window.cv.Mat.zeros(size, size, window.cv.CV_8UC3),
        srcVertices = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, gridParams.coords),
        dstVertices  = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, dstCoords),
        perspectiveTransform = window.cv.getPerspectiveTransform(srcVertices, dstVertices);

    window.cv.warpPerspective(
        cvImage,
        img_warped,
        perspectiveTransform,
        new window.cv.Size(size, size),
        window.cv.INTER_LINEAR,
        window.cv.BORDER_CONSTANT,
        new window.cv.Scalar()
    );

    const { width: gridWidth, height: gridHeight } = img_warped.size();

    canvas.width = gridWidth;
    canvas.height = gridHeight;
    canvas.style.width = 'auto';
    canvas.style.height = '100%';

    gridParams.gridWidth = gridWidth;
    gridParams.gridHeight = gridHeight;

    window.cv.imshow('thesudokuapp-ocr-canvas', img_warped);

    img_warped.delete();
    srcVertices.delete();
    dstVertices.delete();
    perspectiveTransform.delete();
    cvImage.delete();
};

const prepareForProcessing = ({ width, height, canvas }) => {
    /* Как же я ненавижу сафари
        const mediaTrackSettings = stream.getVideoTracks()[0].getSettings();

        streamWidth = mediaTrackSettings.width;
        streamHeight = mediaTrackSettings.height;
    */
    cvImage = new window.cv.Mat(height, width, window.cv.CV_8UC4);
    cvImageDst = new window.cv.Mat(height, height, window.cv.CV_8UC1);
    approximatedContour = new window.cv.Mat();

    if (!offscreenCanvas) {
        offscreenCanvas = new window.OffscreenCanvas(width, height);
        ctx = canvas.getContext('2d', { willReadFrequently: true });
    }

    canvas.style.width = '';
    canvas.style.height = '';
    canvas.width = width;
    canvas.height = height;

    if (height > width) {
        canvas.classList.add('portrait');
    } else {
        canvas.classList.remove('portrait');
    }
};

const processFrame = ({ width, height, video, canvas, logger }) => {
    if (!stream) {
        return;
    }

    ctx.drawImage(video, 0, 0, width, height);

    try {
        cvImage.data.set(ctx.getImageData(0, 0, width, height).data);
        findGrid(canvas);
        video.requestVideoFrameCallback(() => processFrame({ width, height, video, canvas, logger }));
    } catch (error) {
        logger.trackEvent({ category: 'exception:ocr', action: error.toString() });
        window.location.reload();
    }
};

const stopMediaStream = ({video, logger}) => {
    streamStarted.value = false;
    video.pause();

    stream.getTracks().forEach(track => track.stop());
    stream = undefined;

    cvImageDst.delete();
    approximatedContour.delete();

    logger.trackEvent({category: 'ocr', action: 'streamstopped'});
};

const startMediaStream = async ({ video, canvas, logger }) => {
    try {
        stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                frameRate: { ideal: 8 },
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: 'environment'
            },
        });
    } catch (error) {
        return {
            streamOk: false,
            error
        };
    }

    video.srcObject = stream;
    video.onloadedmetadata = () => {
        streamWidth = video.videoWidth;
        streamHeight = video.videoHeight;

        //video.width = streamWidth;
        //video.height = streamHeight
        prepareForProcessing({ width: video.videoWidth, height: video.videoHeight, canvas });
        logger.trackEvent({ category: 'ocr', action: 'streamstarted'});
        video.play();
        streamStarted.value = true;
        processFrame({ width: video.videoWidth, height: video.videoHeight, video, canvas, logger });
    };
};

const clearMediaStream = ({video, logger}) => {
    if (streamStarted.value) {
        stopMediaStream({video, logger});
    }

    stream = undefined;
    streamWidth = undefined;
    streamHeight = undefined;
    cvImage = undefined;
    cvImageDst = undefined;
    approximatedContour = undefined;
    offscreenCanvas = undefined;
    ctx = undefined;
    running = false;
    gridParams = undefined;
    image = undefined;

    if (onOrientationChange) {
        window.removeEventListener('orientationchange', onOrientationChange);
        onOrientationChange = undefined;
    }
};

const startOcrEngine = async ({ video, canvas, logger }) => {
    if (!window.cv) {
        loadingOpenCV.value = true;
        await loadOpenCV();
        loadingOpenCV.value = false;
    }

    const res = await startMediaStream({ video, canvas, logger });

    if (res) {
        return res;
    }

    onOrientationChange = () => {
        if (streamStarted.value === false) {
            return;
        }

        logger.trackEvent({ category: 'ocr', action: 'orienationchanged'});
        stopMediaStream({video, logger});
        void startMediaStream({ video, canvas, logger });
    };

    window.addEventListener('orientationchange', onOrientationChange);

    return {
        transformPerspective: () => transformPerspective(canvas),
        stopMediaStream: () => stopMediaStream({ video, logger }),
        startMediaStream: () => startMediaStream({ video, canvas, logger }),
        clearMediaStream: () => clearMediaStream({ video, logger }),
        getOcrData: () => ({ image, gridParams, gridImage: canvas.toDataURL('image/jpeg') })
    };
};

export { startOcrEngine, loadingOpenCV, streamStarted };
