import { defineStore } from 'pinia';
import { logger } from '@/services/thesudokuapp-logger';
import { stopwatchStore, snackbarStore, gameplaySettingsStore, sudokuStore } from '@/App/stores/index';
import { playSound } from '@/services/thesudokuapp-sound';
import { resetHint } from '@/services/thesudokuapp-hints';

export const useuiStore = defineStore('ui', {
    state: () => ({
        components: {
            settings: false,
            feedback: false,
            importsudoku: false,
            ocr: false,
            stats: false,
            findpuzzlebyidmenu: false,
            newgamemenu: false,
            appmenu: false,
            overflowmenu: false
        },
        syncing: false,
        hideBottomPanelPuzzleStats: false,
        gameover: false,
        waiting: false,
        snackbar: false,
        hintMode: false,
        selectedNumberFirst: {
            hint: 0,
            value: 0
        },
        theme: '',
        orientationLock: 'any', // any | portrait
        reduceMotion: '',
        printDisabled: false,
        soundDisabled: false,
        clipboardDisabled: false, // не работает по http
        shareDisabled: false,
        fullscreenDisabled: false,
        orientationLockDisabled: true,
        webkitFullscreenDisabled: true,
        touchMode: false,
        /**
         * @see initExternalButtons()
         */
        hamburgerEl: null,
        overflowEl: null,
        hamburgerSvgEl: null
    }),
    getters: {
        darkMode: state => state.theme === 'default-dark',
        reducedMotion: state => state.reduceMotion === 'reduce',
        showScrim: state => state.gameover || state.components.appmenu || state.components.newgamemenu || state.components.findpuzzlebyidmenu,
        gameControlsDisabled: state => state.gameover || state.waiting
            || state.components.settings || state.components.feedback || state.components.importsudoku || state.components.stats
            || state.components.findpuzzlebyidmenu || state.components.newgamemenu || state.components.appmenu
            || stopwatchStore.stopwatchOnAndPaused,
        hintModeOn: state => gameplaySettingsStore.showSecondNumpad || state.hintMode,
        boardIsHidden: state => state.components.settings || state.components.feedback || state.components.importsudoku || state.components.stats
    },
    actions: {
        switchTheme({ overflow } = {}) {
            const theme = (this.theme === 'default-dark') ? 'default-light' : 'default-dark';

            document.documentElement.dataset.theme = theme;
            window.thesudokuapp.setCookie('thesudokuapp_theme', theme);

            const themes = 'thesudokuapp' in window && 'themes' in window.thesudokuapp ? window.thesudokuapp.themes : null;

            if (themes) {
                const meta = document.querySelectorAll('meta[name="theme-color"]');

                meta[0].content = themes[theme]['themeColor'];

                if (meta.length === 2) {
                    meta[0].removeAttribute('media');
                    meta[1].remove();
                }
            }

            this.theme = theme;

            // Тут было this.$store.dispatch('smartHint'); но вроде в switchUIState это и так делается
            logger.trackEvent({
                category: 'ui' + (overflow ? ': overflow' : ''),
                action: 'darkMode ' + ((this.theme === 'default-dark') ? ' on' : 'off')
            });
        },
        switchReducedMotion() {
            const reduceMotion = (this.reduceMotion === 'reduce') ? 'no-preference' : 'reduce';

            window.thesudokuapp.setCookie('thesudokuapp_rm', reduceMotion);
            document.documentElement.dataset.reducedmotion = reduceMotion;

            this.reduceMotion = reduceMotion;

            logger.trackEvent({
                category: 'ui',
                action: 'reduced motion ' + ((this.reduceMotion === 'reduce') ? ' on' : 'off')
            });
        },
        async toggleRotationLock() {
            if (this.orientationLockDisabled) {
                return;
            }

            if (this.orientationLock === 'any') {
                await window.screen.orientation.lock('portrait');
                this.orientationLock = 'portrait';
            } else {
                window.screen.orientation.unlock();
                this.orientationLock = 'any';
            }
        },
        toggleWaiting() {
            this.waiting = !this.waiting;

            if (this.waiting) {
                if (this.hamburgerEl) {
                    this.hamburgerEl.setAttribute('disabled', 'disabled');
                }

                if (this.overflowEl) {
                    this.overflowEl.setAttribute('disabled', 'disabled');
                }
            } else {
                if (this.hamburgerEl) {
                    this.hamburgerEl.removeAttribute('disabled');
                }

                if (this.overflowEl) {
                    this.overflowEl.removeAttribute('disabled');
                }
            }
        },
        switchUIState({ type, newState } = {}) {
            if (type === 'snackbar') {
                this.snackbar = newState;
                return;
            }

            // Чтобы снэкбар не закрывался, когда показываем снэкбар
            if (type === 'overflowmenu' && newState === false) {
                this.components.overflowmenu = false;
                return;
            }

            // Board->clickOutside
            if (this.snackbar) {
                snackbarStore.hideSnackbar();
            }

            if (this.waiting) {
                return;
            }

            if (sudokuStore.smartHint.animeCallback) {
                void resetHint();
            }

            if (type) {
                /* Из window вызывается без newState */
                if ((type === 'appmenu' || type === 'overflowmenu') && newState === undefined) {
                    newState = (type === 'appmenu') ? !this.components.appmenu : !this.components.overflowmenu;

                    if (newState && stopwatchStore.stopwatchOnAndPaused) {
                        stopwatchStore.resumeStopwatch();
                    }
                }

                this.components[type] = newState;
            }

            /* Когда мы вызываем что-то из drawer или например overflowmenu, нам нужно поменять гамбургер */
            if ((type === 'appmenu') || this.components.appmenu) {
                if (this.hamburgerSvgEl) {
                    if (type === 'appmenu' && newState === true) {
                        this.hamburgerSvgEl.classList.add('thesudokuapp-hamburger-svg-active');
                    } else {
                        this.hamburgerSvgEl.classList.remove('thesudokuapp-hamburger-svg-active');
                    }
                }
            }

            Object.keys(this.components).filter(t => t !== type).forEach(t => this.components[t] = false);
        },
        switchHintMode() {
            this.hintMode = !this.hintMode;

            if (gameplaySettingsStore.numberFirst) {
                if (this.selectedNumberFirst.hint && !this.hintMode) {
                    this.selectNumberFirst({
                            value: this.selectedNumberFirst.hint,
                            hint: 0
                        });
                } else if (this.selectedNumberFirst.value && this.hintMode) {
                    this.selectNumberFirst({
                        value: 0,
                        hint: this.selectedNumberFirst.value
                    });
                }
            }
        },
        selectNumberFirst({ hint, value } = { hint: 0, value: 0 }) {
            if (!gameplaySettingsStore.numberFirst) {
                return;
            }

            const wasSet = this.selectedNumberFirst.value || this.selectedNumberFirst.hint;

            if (hint && this.selectedNumberFirst.value) {
                this.selectedNumberFirst.value = 0;
            }

            if (value && this.selectedNumberFirst.hint) {
                this.selectedNumberFirst.hint = 0;
            }

            if (hint && hint === this.selectedNumberFirst.hint) {
                this.selectedNumberFirst.hint = 0;
            } else {
                this.selectedNumberFirst.hint = hint;
            }

            if (value && value === this.selectedNumberFirst.value) {
                this.selectedNumberFirst.value = 0;
            } else {
                this.selectedNumberFirst.value = value;
            }

            sudokuStore.lastSelectedHintId = this.selectedNumberFirst.hint || this.selectedNumberFirst.value;

            if (gameplaySettingsStore.sound) {
                if (this.selectedNumberFirst.value || this.selectedNumberFirst.hint) {
                    void playSound('selectcell');
                } else if (wasSet) {
                    void playSound('deselectcell');
                }
            }
        },


    }
});
