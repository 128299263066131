<template>
    <button class="svgbutton thesudokuapp-svg-button-arrowclose" :disabled="waiting" :aria-label="label" @click.stop="close">
        <svg class="thesudokuapp-hamburger-svg-active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 380">
            <rect x="20" y="70" width="360" height="40" rx="5" class="thesudokuapp-hamburger-svg-top" fill="currentColor" />
            <rect x="20" y="170" width="360" height="40" rx="5" class="thesudokuapp-hamburger-svg-middle" fill="currentColor" />
            <rect x="20" y="270" width="360" height="40" rx="5" class="thesudokuapp-hamburger-svg-bottom" fill="currentColor" />
        </svg>
    </button>
</template>

<script>
    import {uiStore} from '@/App/stores';

    export default {
        props: {
            label: {
                type: String,
                required: false,
                default: null
            }
        },
        computed: {
            waiting() { return uiStore.waiting; }
        },
        methods: {
            close() {
                this.$emit('closed');
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-svg-button-arrowclose {
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(180deg);
        // так как перевернутая
        padding: 0.4rem 0.4rem 0.2rem;
        z-index: 1;

        .thesudokuapp-hamburger-svg-active {
            width: 0.8rem;
            height: 0.8rem;
        }
    }
</style>
