// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-svg-button-arrowclose {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  padding: 0.4rem 0.4rem 0.2rem;
  z-index: 1;
}
.thesudokuapp-svg-button-arrowclose .thesudokuapp-hamburger-svg-active {
  width: 0.8rem;
  height: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./App/components/ArrowCloseButton.vue"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,yBAAA;EAEA,6BAAA;EACA,UAAA;AADR;AAGQ;EACI,aAAA;EACA,cAAA;AADZ","sourcesContent":["\n    .thesudokuapp-svg-button-arrowclose {\n        position: absolute;\n        right: 0;\n        top: 0;\n        transform: rotate(180deg);\n        // так как перевернутая\n        padding: 0.4rem 0.4rem 0.2rem;\n        z-index: 1;\n\n        .thesudokuapp-hamburger-svg-active {\n            width: 0.8rem;\n            height: 0.8rem;\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
