<template>
    <div
        class="thesudokuapp-checkbox"
        :class="{ 'thesudokuapp-checkbox-right': right }"
        @click.stop=""
    >
        <input
            :id="id"
            class="thesudokuapp-checkbox-input"
            type="checkbox"
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
        >
        <label
            class="thesudokuapp-checkbox-label"
            :for="id"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            modelValue: Boolean,
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            right: Boolean,
            left: Boolean
        },
        emits: [ 'update:modelValue' ]
    };
</script>
