// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-spinner-inner, .thesudokuapp-spinner::before, .thesudokuapp-spinner::after {
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 100%;
  animation: thesudokuapp-bouncedelay 1.4s infinite ease-in-out both;
}
.thesudokuapp-spinner {
  width: 1em;
  height: 1em;
  margin-right: 1.2em;
  margin-left: 1.2em;
  position: relative;
}
.thesudokuapp-spinner .thesudokuapp-spinner-inner {
  animation-delay: -0.16s;
}
.thesudokuapp-spinner::before, .thesudokuapp-spinner::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
}
.thesudokuapp-spinner::before {
  left: -1.2em;
  animation-delay: -0.32s;
}
.thesudokuapp-spinner::after {
  right: -1.2em;
}
@keyframes thesudokuapp-bouncedelay {
0%, 80%, 100% {
    transform: scale(0);
}
40% {
    transform: scale(1);
}
}`, "",{"version":3,"sources":["webpack://./App/components/Spinner.vue"],"names":[],"mappings":"AACI;EACI,UAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,kEAAA;AAAR;AAGI;EACI,UAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAAR;AAGQ;EACI,uBAAA;AADZ;AAIQ;EACI,WAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;AAFZ;AAKQ;EACI,YAAA;EACA,uBAAA;AAHZ;AAMQ;EACI,aAAA;AAJZ;AAQI;AACI;IACI,mBAAA;AALV;AAQM;IACI,mBAAA;AANV;AACF","sourcesContent":["\n    .thesudokuapp-spinner-inner, .thesudokuapp-spinner::before, .thesudokuapp-spinner::after {\n        width: 1em;\n        height: 1em;\n        background-color: currentColor;\n        border-radius: 100%;\n        animation: thesudokuapp-bouncedelay 1.4s infinite ease-in-out both;\n    }\n\n    .thesudokuapp-spinner {\n        width: 1em;\n        height: 1em;\n        margin-right: 1.2em;\n        margin-left: 1.2em;\n        position: relative;\n        //line-height: 1em;\n\n        .thesudokuapp-spinner-inner {\n            animation-delay: -0.16s;\n        }\n\n        &::before, &::after {\n            content: '';\n            display: block;\n            position: absolute;\n            top: 0;\n        }\n\n        &::before {\n            left: -1.2em;\n            animation-delay: -0.32s;\n        }\n\n        &::after {\n            right: -1.2em;\n        }\n    }\n\n    @keyframes thesudokuapp-bouncedelay {\n        0%, 80%, 100% {\n            transform: scale(0);\n        }\n\n        40% {\n            transform: scale(1);\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
