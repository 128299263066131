// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thesudokuapp-settings {
  justify-content: space-around;
}
.thesudokuapp-settings .sudoku-switchbuttons-group {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./App/components/Settings.vue"],"names":[],"mappings":"AACI;EACI,6BAAA;AAAR;AAEQ;EACI,qBAAA;AAAZ","sourcesContent":["\n    .thesudokuapp-settings {\n        justify-content: space-around;\n\n        .sudoku-switchbuttons-group {\n            margin-bottom: 0.5rem;\n        }\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
