import { createPinia, defineStore } from 'pinia';
import { useStopwatchStore } from '@/App/stores/stopwatch';
import { useGameplaySettingsStore } from '@/App/stores/gameplaySettings';
import { useuiStore } from '@/App/stores/ui';
import { useSnackbarStore } from '@/App/stores/snackbar';
import { useProfileStore } from '@/App/stores/profile';
import { useSudokuStore } from '@/App/stores/sudoku';


const
    pinia = createPinia(),
    stopwatchStore = useStopwatchStore(pinia),
    gameplaySettingsStore = useGameplaySettingsStore(pinia),
    uiStore = useuiStore(pinia),
    snackbarStore = useSnackbarStore(pinia),
    profileStore = useProfileStore(pinia),
    sudokuStore = useSudokuStore(pinia),
    miscStore = defineStore('misc', {
        state: () => ({
            feedbackTemplate: '',
            solvedInSession: 0,
            newSudokuDifficulty: '',
            newSudokuHashId: '',
            beforeInstallPromptEvent: null,
            matomoId: '',
            initfinished: false
        })
    })(pinia);

export { pinia, stopwatchStore, gameplaySettingsStore, uiStore, snackbarStore, miscStore, profileStore, sudokuStore };
