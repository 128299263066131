<template>
    <div>
        <app-arrow-close-button label="Close import sudoku form" @closed="close" />
        <form class="sudoku-form thesudokuapp-importform" @submit.prevent="submit">
            <div class="input-wrapper">
                <label for="customsudoku-sudoku" class="sudoku-form-label">{{ $t('importsudoku.label') }}</label>
                <textarea
                    id="customsudoku-sudoku"
                    v-model="customsudoku.sudoku"
                    :disabled="waiting"
                    class="thesudokuapp-customsudoku sudoku-form-control"
                    rows="17"
                    required
                    minlength="81"
                    placeholder="1234_____4______

1234.....4......

1234000004000000

+-------+
| 9 4 . |
| 5 . . |
| . . . |
+-------+
"
                />
                <div class="input-wrapper">
                    <label for="customsudoku-comment" class="sudoku-form-label">{{ $t('importsudoku.comment') }}</label>
                    <input
                        id="customsudoku-comment"
                        v-model="customsudoku.comment"
                        class="sudoku-form-control"
                        :placeholder="$t('feedback.optionalValue')"
                        :disabled="waiting"
                    >
                </div>
                <button
                    class="sudoku-text-button invert thesudokuapp-submit"
                    type="submit"
                    :disabled="waiting"
                >
                    <span v-show="!waiting">{{ $t('importsudoku.submit') }}</span>
                    <app-spinner v-show="waiting" />
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import ArrowCloseButton from './ArrowCloseButton';
    import Spinner from './Spinner';
    import { solveSudoku } from '@/services/thesudokuapp-solver';
    import { uiStore, snackbarStore, miscStore, sudokuStore } from '@/App/stores';
    import { logger } from '@/services/thesudokuapp-logger';
    import { api } from '@/services/thesudokuapp-api';
    import { $t } from '@/services/thesudokuapp-i18n';

    export default {
        components: {
            appArrowCloseButton: ArrowCloseButton,
            appSpinner: Spinner
        },
        data() {
            return {
                customsudoku: {
                    sudoku: null,
                    comment: null
                },
                grid: null
            };
        },
        computed: {
            waiting() { return uiStore.waiting; }
        },
        methods: {
            focus() { document.getElementById('customsudoku-sudoku').focus(); },
            $t,
            close() {
                if (this.waiting) {
                    return;
                }

                this.customsudoku.sudoku = null;
                uiStore.switchUIState({ type: 'importsudoku', newState: false });
            },
            submit() {
                const parsedSudoku = this.parseSudoku();

                const numbersCount = parsedSudoku ? parsedSudoku.replace(/0/g, '').length : 0;

                let hasSolution = true;

                if (parsedSudoku && (numbersCount > 16)) {
                    const parsedGrid = solveSudoku(parsedSudoku);

                    if (!parsedGrid) {
                        hasSolution = false;
                    }
                }

                // multiple solution 100000580800001006060020000000590000000070000093002000010000804000907050600200710
                if (!parsedSudoku || numbersCount < 17 || !hasSolution) {
                    snackbarStore.showSnackbar({
                        primaryMessage: !parsedSudoku ? $t('importsudoku.parseerror') : $t('importsudoku.incorrectsudoku'),
                        secondaryMessage: $t('importsudoku.importfeedback'),
                        actionCaption: $t('action.feedback'),
                        actionCallback: () => {
                            miscStore.feedbackTemplate = $t('importsudoku.message') + '\n\n' + this.customsudoku.sudoku;
                            uiStore.switchUIState({ type: 'feedback', newState: true });
                        }
                    });

                    api.telegram('import sudoku fails:\n\n' + this.customsudoku.sudoku + '\n\nparsed:\n\n' + parsedSudoku);

                    return;
                }

                this.importToServer({
                    parsedSudoku,
                    comment: this.customsudoku.comment,
                });
            },
            async importToServer({ parsedSudoku, comment }) {
                try {
                    uiStore.toggleWaiting();

                    const grid = await api.rawgrid({ grid: parsedSudoku, comment });

                    uiStore.toggleWaiting();

                    logger.trackEvent({
                        category: 'interaction',
                        action: 'importsudoku',
                        eventName: grid.HashID
                    });

                    this.close();

                    sudokuStore.newGame({
                        grid: {
                            hashid: grid.HashID,
                            difficulty: grid.Difficulty,
                            grid: grid.Grid,
                            solution: grid.Solution
                        },
                        type: 'imported'
                    });
                } catch (error) {
                    uiStore.toggleWaiting();

                    if (!error.json?.primary || !error.json?.secondary || !error.json?.grid) {
                        const snackbarResolved = await api.apiAsyncCatch({
                            error,
                            source: 'import_serverexception'
                        });

                        if (snackbarResolved === 'action') {
                            return this.importToServer({ parsedSudoku, comment });
                        }

                        return;
                    }

                    const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                        primaryMessage: error.json.primary,
                        secondaryMessage: error.json.secondary,
                        actionCaption: $t('snackbar.action.playanyway')
                    });

                    if (snackbarResolved === 'action') {
                        this.close();

                        sudokuStore.newGame({
                            grid: {
                                hashid: error.json.grid.HashID,
                                difficulty: error.json.grid.Difficulty,
                                grid: error.json.grid.Grid,
                                solution: error.json.grid.Solution
                            },
                            type: 'imported-anyway'
                        });
                    }
                }
            },
            parseSudoku() {
                let parsedSudoku = this.customsudoku.sudoku.replace(/[\n\r|+\-*,]/g, '');

                if (parsedSudoku.match(/^[0-9]{81}$/)) {
                    return parsedSudoku;
                } else if (parsedSudoku.match(/^0+$/) && parsedSudoku.match(/^[\s1-9]+$/)) {
                    parsedSudoku = parsedSudoku.replace(/\s/g, '');
                } else if (parsedSudoku.match(/^[\s1-9]+$/)) {
                    parsedSudoku = parsedSudoku.replace(/\s/g, '0');
                } else {
                    parsedSudoku = this.customsudoku.sudoku.replace(/[\s|+\-*,]/g, '').replace(/[._]/g, '0');
                }

                if (!parsedSudoku.match(/^[0-9]{81}$/)) {
                    return null;
                }

                return parsedSudoku;
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-importform {
        margin-top: auto;
        margin-bottom: auto;
    }

    .thesudokuapp-customsudoku {
        width: 100%;
        font-family: monospace;
    }
</style>
