import { createApp } from 'vue';
import App from '@/App/App.vue';
import OverflowMenu from '@/App/components/OverflowMenu';
import RotationLockButton from '@/App/components/RotationLockButton.vue';
import { api } from '@/services/thesudokuapp-api';
import { clickOutsideDirective, focusDirective } from '@/services/thesudokuapp-directives';
import { i18n } from '@/services/thesudokuapp-i18n';
import { pinia } from '@/App/stores';

const app = createApp(App)
    .use(i18n)
    .use(pinia)
    .directive('click-outside', clickOutsideDirective)
    .directive('focus', focusDirective);

api.setApp(app.config.globalProperties);

const overflowMenu = createApp(OverflowMenu)
    .use(pinia)
    .use(i18n);

const rotationLockButton = createApp(RotationLockButton)
    .use(pinia)
    .use(i18n);

if (process.env.NODE_ENV === 'production') {
    const vueErrorHandler = (err, vm, info) => {
        if ('thesudokuapp' in window && 'logError' in window.thesudokuapp) {
            window.thesudokuapp.logError(
                `${err.message} (${info}) [${vm.$store.state.matomoId}]`,
                document.location.href,
                undefined,
                undefined,
                err
            );
        }
    };

    app.config.errorHandler = vueErrorHandler;
    overflowMenu.config.errorHandler = vueErrorHandler;
}

app.mount('#thesudoku');
overflowMenu.mount('#thesudokuapp-overflowmenu-container');
rotationLockButton.mount('#thesudokuapp-rotatelock-container');

