import { defineStore } from 'pinia';
import { utils } from '@/services/thesudokuapp-utils';
import { storageSet } from '@/services/thesudokuapp-storage';
import { gameplaySettingsStore, uiStore } from '@/App/stores';

export const useStopwatchStore = defineStore('stopwatch', {
    state: () => ({
        buttonsDisabled: false,
        paused: false,
        started: false,
        currentTimer: 0,
        interval: null
    }),
    getters: {
        stopwatchOnAndPaused: state => gameplaySettingsStore.showStopwatch && state.paused,
        stopwatchOnAndStarted: state => gameplaySettingsStore.showStopwatch && state.started,
        stopWatchStarted: state => state.started && !state.paused && (state.currentTimer > 0),
        stopWatchState: state => utils.parseTime(state.currentTimer)
    },
    actions: {
        toggleStopwatch() {
            if (this.stopwatchOnAndPaused) {
                this.resumeStopwatch();
            } else if (this.stopwatchOnAndStarted) {
                this.pauseStopwatch();
            }
        },
        startStopwatch(initialValue) {
            this.started = true;
            this.currentTimer = initialValue || 0;
            this.interval = setInterval(async () => {
                if (this.started && !this.paused && !uiStore.boardIsHidden) {
                    this.currentTimer++;
                    await storageSet.stopwatch(this.currentTimer);
                }
            }, 1000);
        },
        stopStopwatch() {
            this.currentTimer = 0;
            this.started = false;
            this.paused = false;

            clearInterval(this.interval);
            this.interval = null;
        },
        pauseStopwatch() {
            if (this.started === true) {
                this.paused = true;
            }
        },
        resumeStopwatch() {
            if (this.started === true) {
                this.paused = false;
            }
        }
    }
});

