<template>
    <transition name="snackbar-animation">
        <div
            :key="snackbar.id"
            class="sudoku-snackbar"
            :class="{ 'sudoku-snackbar-with-twobuttons': snackbar.actionCaption || snackbar.secondaryActionCaption || snackbar.showDontshow }"
            @click.stop="hideSnackbar"
        >
            <div class="sudoku-snackbar-content">
                <img v-show="snackbar.showLogo" class="sudoku-snackbar-logo" src="/assets/thesudokuapp192.png" alt="thesudoku.app logo" loading="lazy" height="192" width="192">
                <div class="sudoku-snackbar-message">
                    <div class="sudoku-snackbar-primarymessage" :class="{ 'sudoku-snackbar-warning-message': snackbar.isWarning }" v-html="snackbar.primaryMessage" />
                    <div v-for="(message, index) in snackbar.secondaryMessage" :key="index" class="sudoku-snackbar-secondarymessage" v-html="message" />
                </div>
            </div>
            <div class="sudoku-snackbar-button-group">
                <app-checkbox v-if="snackbar.showDontshow" :id="snackbar.notificationId" v-model="snackbar.currentDontShowStatus" :label="snackbar.dontshowCaption" />
                <button v-if="snackbar.showDismiss" class="sudoku-snackbar-button sudoku-link-button" @click="dismiss">{{ snackbar.dismissCaption }}</button>
                <button v-if="snackbar.secondaryActionCaption" class="sudoku-snackbar-button sudoku-link-button" @click="secondaryAction">{{ snackbar.secondaryActionCaption }}</button>
                <button v-if="snackbar.actionCaption" class="sudoku-snackbar-button sudoku-link-button" @click="action">{{ snackbar.actionCaption }}</button>
            </div>
        </div>
    </transition>
</template>

<script>
    import Checkbox from './Checkbox';
    import { snackbarStore } from '@/App/stores';

    export default {
        components: {
            appCheckbox: Checkbox,
        },
        computed: {
            snackbar() {
                return snackbarStore.snackbar;
            }
        },
        methods: {
            hideSnackbar: snackbarStore.hideSnackbar,
            action(event) {
                if (this.snackbar.preventHideOnAction) {
                    event.stopPropagation();
                }

                this.snackbar.actionCallback();
            },
            secondaryAction(event) {
                if (this.snackbar.preventHideOnAction) {
                    event.stopPropagation();
                }

                this.snackbar.secondaryActionCallback();
            },
            dismiss(event) {
                if (this.snackbar.preventHideOnAction) {
                    event.stopPropagation();
                }

                if (this.snackbar.dismissCallback) {
                    this.snackbar.dismissCallback();
                }
            }
        }
    };
</script>

<style lang="scss">
    .sudoku-snackbar {
        position: absolute;
        bottom: 0.5rem;
        z-index: 6;
        max-width: calc(var(--board-size) - 1rem);
        color: var(--dark-background-font-color);
        background: var(--accent-color-transparent-dark);
        display: flex;
        box-shadow: 0 5px 10px -1px var(--accent-color-transparent-dark);
        padding: 0.3rem 0.5rem;
        font-size: 0.5rem;
        border-radius: 0.1rem;

        .sudoku-snackbar-warning-message {
            color: var(--danger-color);
        }
    }

    .sudoku-snackbar-with-twobuttons {
        &.sudoku-snackbar {
            flex-direction: column;
            align-items: flex-end;
        }

        .sudoku-snackbar-content {
            margin-bottom: 0.4rem;
        }

        .sudoku-snackbar-button {
            margin-left: 0.1rem;
            padding: 0.3rem 0 0.3rem 0.3rem;

            &:first-of-type {
                margin-left: auto;
            }
        }

        .sudoku-snackbar-button-group {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    .sudoku-snackbar-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .sudoku-snackbar-logo {
        max-width: 2rem;
        max-height: 2rem;
        margin-right: 0.3rem;
        margin-left: auto;
    }

    .sudoku-snackbar-message {
        text-align: left;
        width: 100%;

        .sudoku-snackbar-primarymessage {
            font-size: 0.6rem;
            line-height: 0.6rem;
        }

        .sudoku-snackbar-secondarymessage {
            font-size: 0.4rem;
            margin-bottom: 0.15rem;
            word-wrap: break-word;

            &:nth-child(2) {
                margin-top: 0.4rem;
            }
        }
    }

    .sudoku-snackbar-button {
        color: hsl(32, 56%, 58%);
        font-weight: bold;
        height: 100%;
        padding-left: 0.8rem;
    }
</style>
