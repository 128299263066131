<template>
    <div class="thesudokuapp-accountandstats" @click.stop="close">
        <app-arrow-close-button label="Close profile" @closed="close" />
        <section v-if="matomoId" class="thesudokuapp-profile">
            <section v-if="!user && !isEmailSigningInStoreData">
                <span class="thesudokuapp-profile-signin-message">
                    {{ $t('stats.signinmessage[0]') }}<br>{{ $t('stats.signinmessage[1]') }}<br>{{ $t('stats.signinmessage[2]') }}<br>{{ $t('stats.signinmessage[3]') }}
                </span>
                <button
                    v-if="!allowGoogleSignin"
                    class="thesudokuapp-profile-button email-signin-button"
                    :disabled="emailSignInForm"
                    @click.stop="showSignInForm"
                >
                    <svg-icon class="thesudokuapp-profile-signin-logo" icon="envelope" />
                    <span>{{ $t('stats.emailsignin') }}</span>
                </button>
                <button
                    v-else
                    class="thesudokuapp-profile-button signin-type-button"
                    :disabled="emailSignInForm || signInTypeSelection"
                    @click.stop="() => signInTypeSelection = true"
                >
                    <svg-icon class="thesudokuapp-profile-signin-logo" icon="idcard" />
                    <span>{{ $t('stats.signin') }}</span>
                </button>
            </section>
            <section v-else class="thesudokuapp-profile-usercard">
                <img v-if="user && user.picture" class="thesudokuapp-profile-usercard-picture" :src="user.picture" alt="profile picture">
                <span class="thesudokuapp-profile-usercard-userinfo">
                    <span class="thesudokuapp-profile-usercard-name">{{ user ? user.name : emailSigningInStoreData.name }}</span>
                    <span>{{ user ? user.email : emailSigningInStoreData.email }}</span>
                </span>
                <button v-if="user" class="thesudokuapp-profile-button signout-button" :disabled="waiting" @click.stop="signout">{{ $t('stats.signout') }}</button>
                <button v-if="isEmailSigningInStoreData" class="thesudokuapp-profile-button reset-button" :disabled="emailSignInForm" @click.stop="reset">{{ $t('stats.reset') }}</button>
                <button v-if="isEmailSigningInStoreData" class="thesudokuapp-profile-button confirm-button" :disabled="emailSignInForm" @click.stop="showSignInForm">{{ $t('stats.enterCode') }}</button>
            </section>
        </section>
        <section class="thesudokuapp-stats-container">
            <div v-if="difficultiesFiltered.length === 0" class="thesudokuapp-stats-empty">
                {{ $t('stats.solveone') }}
            </div>
            <table
                v-for="difficulty in difficultiesFiltered"
                :key="difficulty"
                class="thesudokuapp-stats-table"
                :data-difficulty="$t(`difficulty['${difficulty}']`)"
                @click.stop=""
            >
                <thead>
                    <tr>
                        <th class="thesudokuapp-stats-table-difficulty" />
                        <th>{{ $t('stats.header.games') }}</th>
                        <th>{{ $t('stats.header.average') }}</th>
                        <th class="thesudokuapp-stats-table-best">{{ $t('stats.header.best') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="statistics[difficulty].games['flawless']" class="thesudokuapp-stats-table-row thesudokuapp-stats-table-flawlessly">
                        <td>{{ $t('stats.flawlessly') }}</td>
                        <td>{{ statistics[difficulty].games['flawless'] }}</td>
                        <td>{{ formatTime(statistics[difficulty].averagetime['flawless']) }}</td>
                        <td class="thesudokuapp-stats-table-best">
                            <span class="thesudokuapp-stats-table-best-time">{{ formatTime(statistics[difficulty].besttime.besttime) }}</span>
                            <br>
                            <span class="thesudokuapp-stats-table-remark">#{{ statistics[difficulty].besttime.puzzleId }}</span><br>
                            <time class="thesudokuapp-stats-table-remark" :datetime="formatDateUTC(statistics[difficulty].besttime.date)">{{ formatDate(statistics[difficulty].besttime.date) }}</time>
                        </td>
                    </tr>
                    <tr v-if="statistics[difficulty].games['solved']" class="thesudokuapp-stats-table-row">
                        <td>{{ $t('stats.solved') }}<br><span class="thesudokuapp-stats-table-remark">{{ $t('stats.including') }}</span></td>
                        <td>{{ statistics[difficulty].games['solved'] }}</td>
                        <td />
                        <td />
                    </tr>
                    <tr v-if="statistics[difficulty].games['autofill']" class="thesudokuapp-stats-table-row">
                        <td>{{ $t('finish.autofill') }}</td>
                        <td>{{ statistics[difficulty].games['autofill'] }}</td>
                        <td>{{ formatTime(statistics[difficulty].averagetime['autofill']) }}</td>
                        <td />
                    </tr>
                    <tr v-if="statistics[difficulty].games['smarthints']" class="thesudokuapp-stats-table-row">
                        <td>{{ $t('finish.smarthints', 1) }}</td>
                        <td>{{ statistics[difficulty].games['smarthints'] }}</td>
                        <td>{{ formatTime(statistics[difficulty].averagetime['smarthints']) }}</td>
                        <td />
                    </tr>
                    <tr v-if="statistics[difficulty].games['simplehints']" class="thesudokuapp-stats-table-row">
                        <td>{{ $t('finish.simplehints', 1) }}</td>
                        <td>{{ statistics[difficulty].games['simplehints'] }}</td>
                        <td>{{ formatTime(statistics[difficulty].averagetime['simplehints']) }}</td>
                        <td />
                    </tr>
                    <tr v-if="statistics[difficulty].games['aftermistakes']" class="thesudokuapp-stats-table-row">
                        <td>{{ $t('stats.after') }}</td>
                        <td>{{ statistics[difficulty].games['aftermistakes'] }}</td>
                        <td>{{ formatTime(statistics[difficulty].averagetime['aftermistakes']) }}</td>
                        <td />
                    </tr>
                </tbody>
            </table>
        </section>
        <app-svg-spinner v-if="syncing" :reducedmessage="$t('svgspinnger.syncing')" />
        <section v-show="signInTypeSelection" class="thesudokuapp-profile-component-container" @click.stop="() => signInTypeSelection = false">
            <transition enter-active-class="flip-in-x">
                <section v-show="signInTypeSelection" class="signin-type-selection">
                    <button class="thesudokuapp-profile-button google-signin-button" @click="googleSignin">
                        <svg class="thesudokuapp-profile-signin-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                            <path fill="#4285f4" d="M17.64,9.2a11,11,0,0,0-.16-1.84H9v3.49h4.84a4.12,4.12,0,0,1-1.79,2.71v2.26H15A8.78,8.78,0,0,0,17.64,9.2Z" />
                            <path fill="#34a853" d="M9,18a8.62,8.62,0,0,0,6-2.18l-2.91-2.26A5.43,5.43,0,0,1,9,14.42a5.37,5.37,0,0,1-5-3.71H1V13a9,9,0,0,0,8,5Z" />
                            <path fill="#fbbc05" d="M4,10.71A5.36,5.36,0,0,1,4,7.29V5H1A9,9,0,0,0,1,13l3-2.33Z" />
                            <path fill="#ea4335" d="M9,3.58a4.83,4.83,0,0,1,3.44,1.35L15,2.34A8.65,8.65,0,0,0,9,0,9,9,0,0,0,1,5L4,7.29A5.37,5.37,0,0,1,9,3.58Z" />
                        </svg>
                        <span>{{ $t('stats.googlesignin') }}</span>
                    </button>
                    <button class="thesudokuapp-profile-button email-signin-button" @click.stop="showSignInForm">
                        <svg-icon class="thesudokuapp-profile-signin-logo" icon="envelope" />
                        <span>{{ $t('stats.emailsignin') }}</span>
                    </button>
                </section>
            </transition>
        </section>
        <section v-show="emailSignInForm" class="thesudokuapp-profile-component-container" @click.stop="() => { if (!waiting) { emailSignInForm = false; hideSnackbar(); } }">
            <app-profile-email-form :show="emailSignInForm" @codeconfirmed="codeconfirmed" />
        </section>
    </div>
</template>

<script>
    import ArrowCloseButton from './ArrowCloseButton';
    import SvgSpinner from '@/App/components/SvgSpinner.vue';
    import ProfileEmailForm from '@/App/components/ProfileEmailForm.vue';
    import { utils } from '@/services/thesudokuapp-utils';
    import SvgIcon from '@/App/components/SvgIcon.vue';
    import { uiStore, snackbarStore, profileStore, miscStore } from '@/App/stores';
    import { syncRecords } from '@/services/thesudokuapp-sync';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { logger } from '@/services/thesudokuapp-logger';
    import { api } from '@/services/thesudokuapp-api';
    import { storageSet } from '@/services/thesudokuapp-storage';

    export default {
        components: {
            appArrowCloseButton: ArrowCloseButton,
            appSvgSpinner: SvgSpinner,
            svgIcon: SvgIcon,
            appProfileEmailForm: ProfileEmailForm
        },
        data() {
            return {
                signInTypeSelection: false,
                emailSignInForm: false,
                confirmForm: false,
                googleSignInOAuthUrl: '',
                user: null
            };
        },
        computed: {
            syncing() {
                return uiStore.syncing;
            },
            waiting() {
                return uiStore.waiting;
            },
            statistics() {
                return profileStore.$state;
            },
            isEmailSigningInStoreData() {
                return profileStore.emailSigningInData?.name && profileStore.emailSigningInData?.email;
            },
            emailSigningInStoreData() {
                return profileStore.emailSigningInData;
            },
            allowGoogleSignin() {
                return this.googleSignInOAuthUrl && !(window.thesudokuapp.appType === 'msstore') && !(window.thesudokuapp.appType === 'twa');
            },
            matomoId() {
                return miscStore.matomoId;
            },
            difficultiesFiltered() {
                return [ 'expert', 'hard', 'medium', 'easy' ].filter(difficulty => this.statistics[difficulty] && Object.values(this.statistics[difficulty].games).reduce((a,b) => a + b, 0));
            },
            show() { return uiStore.components.stats; },
        },
        mounted() {
            if (window.thesudokuapp.googleOAuthUrl) {
                this.googleSignInOAuthUrl = window.thesudokuapp.googleOAuthUrl;
            }

            if (window.thesudokuapp.user) {
                this.user = window.thesudokuapp.user;
            }

            if (window.thesudokuapp.googleSignedIn) {
                logger.trackEvent({category: 'profile', action: 'google signed-in'});
                window.thesudokuapp.googleSignedIn = false;

                snackbarStore.showSnackbar({
                    primaryMessage: $t('stats.emailSigninOk[0]'),
                    secondaryMessage: $t('stats.emailSigninOk[1]'),
                    timeout: 3750
                });
            }

            if (this.user && !this.syncing) {
                syncRecords();
            }
        },
        methods: {
            hideSnackbar() {
                snackbarStore.hideSnackbar();
            },
            $t,
            googleSignin() {
                logger.trackEvent({category: 'profile', action: 'google sign-in'});
                api.telegram('google sign-in');
                window.location.href = this.googleSignInOAuthUrl;
            },
            reset() {
                logger.trackEvent({category: 'profile', action: 'email sign-in reset'});

                snackbarStore.showSnackbar({
                    primaryMessage: $t('stats.resetWarning'),
                    actionCaption: $t('stats.reset'),
                    actionCallback: () => {
                        this.confirmForm = false;
                        profileStore.updateEmailSigningInData();
                    }
                });
            },
            showSignInForm() {
                this.signInTypeSelection = false;
                this.emailSignInForm = true;
            },
            codeconfirmed() {
                this.emailSignInForm = false;
                this.user = window.thesudokuapp.user;

                syncRecords();
            },
            close() {
                uiStore.switchUIState({type: 'stats', newState: false});
            },
            formatTime(time) {
                return utils.formatTime(time);
            },
            formatDate(timestamp) {
                if (!timestamp) {
                    return '';
                }

                // noinspection JSCheckFunctionSignatures - и снова тупой typeScript
                return new Date(timestamp * 1000).toLocaleDateString([], { dateStyle: 'medium' });
            },
            formatDateUTC(timestamp) {
                if (!timestamp) {
                    return '';
                }

                return new Date(timestamp * 1000).toUTCString();
            },
            async signout() {
                const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                    primaryMessage: $t('stats.signoutWarning[0]'),
                    secondaryMessage: $t('stats.signoutWarning[1]'),
                    actionCaption: $t('stats.signout')
                });

                if (snackbarResolved === 'action') {
                    void this.sendSignoutRequestToServer();
                }
            },
            async sendSignoutRequestToServer() {
                try {
                    uiStore.toggleWaiting();
                    uiStore.syncing = true;
                    const res = await api.signout();
                    uiStore.syncing = false;
                    uiStore.toggleWaiting();

                    this.user = undefined;
                    this.googleSignInOAuthUrl = res.googleOAuthUrl;
                    window.thesudokuapp.user = undefined;
                    window.thesudokuapp.googleOAuthUrl = res.googleOAuthUrl;

                    void profileStore.clear();
                    void storageSet.lastsynced();

                    logger.trackEvent({category: 'profile', action: 'signout'});
                } catch (error) {
                    uiStore.toggleWaiting();
                    uiStore.syncing = false;

                    const snackbarResolved = await api.apiAsyncCatch({
                        error,
                        source: 'signout'
                    });

                    if (snackbarResolved === 'action') {
                        return this.sendSignoutRequestToServer();
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
    .thesudokuapp-profile {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: var(--accent-color-transparent-dark);
        box-shadow: 0 4px 3px -3px var(--accent-color-transparent-dark);
        padding: 0.4rem 1.6rem 0.4rem 0.4rem;
        height: 1.8rem;

        & > section {
            display: flex;
            align-items: center;
        }

        .thesudokuapp-profile-signin-message {
            display: inline-flex;
            align-items: center;
            font-size: 0.28rem;
            text-align: left;
        }

        .thesudokuapp-profile-usercard {
            display: flex;
            align-items: center;
            font-size: 0.4rem;

            .thesudokuapp-profile-usercard-picture {
                border-radius: 100%;
                width: 1rem;
                height: 1rem;
                margin-right: 0.4rem;
            }

            .thesudokuapp-profile-usercard-userinfo {
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 0.4rem;
            }

            .thesudokuapp-profile-usercard-name {
                font-weight: bold;
                margin-bottom: 0.05rem;
            }
        }
    }

    .thesudokuapp-accountandstats {
        position: relative;
        justify-content: center;

        .thesudokuapp-svg-button-arrowclose {
            padding-bottom: 0.25rem;
        }

        &.thesudokuapp-container-inner-content {
            padding-top: 2rem;
        }

        .thesudokuapp-stats-container {
            width: 100%;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            //justify-content: safe center; // don't work in chrome
        }

        .thesudokuapp-stats-empty {
            margin: auto;
            font-size: 2em;
        }

        .thesudokuapp-stats-table {
            position: relative;
            margin-left: auto;
            margin-right: auto;
            background-color: var(--dark-background-transparent);
            padding: 0.2rem;
            border-radius: 0.2rem;
            width: 93%;
            text-align: right;

            &::before {
                content: attr(data-difficulty);
                position: absolute;
                left: 0;
                top: 0;
                background-color: hsl(var(--accent-hue), var(--accent-saturation), 17%);
                border-top-left-radius: 0.2rem;
                border-bottom-right-radius: 0.2rem;
                padding: 0.2rem;
                width: 2.2rem;
                text-align: center;
            }

            &:not(:last-child) {
                margin-bottom: 0.4rem;
            }

            &:first-of-type {
                margin-top: auto;
            }

            &:last-of-type {
                margin-bottom: auto;
            }

            th {
                font-weight: normal;
                padding-bottom: 0.3rem;
            }

            .thesudokuapp-stats-table-remark {
                font-size: 0.28rem;
            }

            .thesudokuapp-stats-table-best {
                text-align: center;
            }

            .thesudokuapp-stats-table-best-time {
                font-weight: bold;
                display: inline-block;
                padding-bottom: 0.1rem;
                color: var(--success-color);
            }

            .thesudokuapp-stats-table-row {
                vertical-align: top;
            }

            .thesudokuapp-stats-table-difficulty {
                text-align: left;
                font-weight: bold;
            }
        }
    }

    .thesudokuapp-accountandstats .thesudokuapp-svgspinner-container {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        width: 1.8rem;
        height: 1.8rem;
        box-shadow: 0 4px 3px -3px var(--accent-color-transparent-dark);

        .thesudokuappp-svgspinner {
            width: 65%;
            height: 65%;
        }
    }

    .thesudokuapp-profile-button {
        background-color: var(--dark-background-font-color);
        color: #000;
        text-decoration: none;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        font-weight: bold;
        min-height: 0.8rem;
        min-width: 2rem;

        &:active:not(:disabled) {
            color: var(--dark-background-transparent);
            background-color: var(--accent-color-lighter);
        }

        &:disabled {
            opacity: 0.5;
            cursor: progress;
        }
    }

    .signout-button {
        justify-content: center;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    .reset-button, .confirm-button {
        justify-content: center;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        min-width: 2.8rem;
    }

    .reset-button {
        margin-left: auto;
        margin-right: 0.4rem;
    }

    .confirm-button {
        margin-right: auto;
    }

    .email-signin-button, .google-signin-button {
        padding: 8px 12px;
        font-size: 14px;

        .thesudokuapp-profile-signin-logo {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    .signin-type-button {
        padding: 0.15rem 0.5rem;
        margin-left: auto;
        margin-right: auto;

        .thesudokuapp-profile-signin-logo {
            margin-right: 0.15rem;
            height: 1em;
        }

        span {
            padding-bottom: 0.13em;
        }
    }

    .google-signin-button {
        margin-bottom: 0.5rem;

        span {
            padding-bottom: 0.14em;
        }
    }

    .email-signin-button {
        margin-left: auto;
        margin-right: auto;
        color: var(--success-color);

        span {
            padding-bottom: 0.11em;
        }
    }

    .thesudokuapp-profile-component-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        padding-top: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .signin-type-selection {
            background-color: var(--dark-background-transparent);
            border-radius: 1vmin;
            padding: 0.5rem;
            box-shadow: 0.02rem 0.08rem 0.7vmin 0 rgba(0, 0, 0, 0.7);

            .email-signin-button, .google-signin-button {
                width: 100%;
                line-height: 1; // почему то неотнаследовался, хотя в девтулсах было показано, что отнаследован
            }
        }
    }
</style>
