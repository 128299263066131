<template>
    <transition enter-active-class="flip-in-x" @after-enter="focus">
        <section v-show="show" class="signin-type-selection" @click.stop="hideSnackbar">
            <form class="thesudokuapp-form-v2" @submit.prevent="submit">
                <div class="thesudokuapp-form-v2-control signin-name">
                    <input
                        id="profile_name"
                        v-model="user.name"
                        name="Name"
                        type="text"
                        class="thesudokuapp-form-v2-textinput"
                        placeholder=" "
                        minlength="2"
                        :disabled="(waiting || confirmForm)"
                    >
                    <label for="profile_name" class="thesudokuapp-form-v2-label">{{ $t('stats.name') }}</label>
                </div>

                <div class="thesudokuapp-form-v2-control signin-email">
                    <input
                        id="profile_email"
                        v-model="user.email"
                        name="Email"
                        type="email"
                        class="thesudokuapp-form-v2-textinput"
                        placeholder=" "
                        minlength="5"
                        :disabled="(waiting || confirmForm)"
                    >
                    <label for="profile_email" class="thesudokuapp-form-v2-label">{{ $t('stats.email') }}</label>
                </div>

                <div v-if="confirmForm" class="thesudokuapp-form-v2-control confirm-code">
                    <input
                        id="profile_code"
                        v-model="user.code"
                        name="Code"
                        type="text"
                        class="thesudokuapp-form-v2-textinput"
                        placeholder=" "
                        minlength="4"
                        maxlength="4"
                        :disabled="waiting"
                    >
                    <label for="profile_code" class="thesudokuapp-form-v2-label">{{ $t('stats.verificationCode') }}</label>
                </div>
                <div v-else class="thesudokuapp-form-v2-labellike">{{ $t('stats.verificationCodeWillBeSend') }}</div>

                <button
                    class="thesudokuapp-profile-button signin-type-button"
                    type="submit"
                    :disabled="waiting"
                >
                    <span v-show="!waiting">{{ confirmForm ? 'Confirm' : $t('stats.signin') }}</span>
                    <app-spinner v-show="waiting" />
                </button>
            </form>
        </section>
    </transition>
</template>

<script>
    import Spinner from '@/App/components/Spinner.vue';
    import { uiStore, profileStore, snackbarStore } from '@/App/stores';
    import { $t } from '@/services/thesudokuapp-i18n';
    import { api } from '@/services/thesudokuapp-api';
    import { logger } from '@/services/thesudokuapp-logger';
    import {nextTick} from 'vue';

    export default {
        components: {
            appSpinner: Spinner
        },
        props: {
            show: {
                type: Boolean,
                required: true,
                default: false
            }
        },
        data() {
            return {
                user: {
                    code: '',
                    name: '',
                    email: ''
                }
            };
        },
        computed: {
            confirmForm() {
                return !!(profileStore.emailSigningInData?.name && profileStore.emailSigningInData?.email);
            },
            waiting() {
                return uiStore.waiting;
            }
        },
        mounted() {
            this.user.name = profileStore.emailSigningInData.name;
            this.user.email = profileStore.emailSigningInData.email;
        },
        methods: {
            hideSnackbar() {
                snackbarStore.hideSnackbar();
            },
            focus() {
                if (this.confirmForm) {
                    document.getElementById('profile_code').focus();
                } else {
                    document.getElementById('profile_name').focus();
                }
            },
            $t,
            submit() {
                if (this.confirmForm) {
                    this.submitCodeConfirmation();
                } else {
                    this.submitEmailSignin();
                }
            },
            async submitEmailSignin() {
                if (!this.user.name || !this.user.email) {
                    return;
                }

                try {
                    uiStore.toggleWaiting();

                    await api.signInWithEmail({
                        Name: this.user.name,
                        Email: this.user.email,
                    });

                    uiStore.toggleWaiting();

                    profileStore.updateEmailSigningInData({
                        name: this.user.name,
                        email: this.user.email
                    });

                    void nextTick(() => document.getElementById('profile_code').focus());

                    logger.trackEvent({category: 'profile', action: 'email sign-in'});

                    snackbarStore.showSnackbar({
                        primaryMessage: $t('stats.emailSent')
                    });
                } catch (error) {
                    uiStore.toggleWaiting();

                    const snackbarResolved = await api.apiAsyncCatch({error, source: 'emailsignin'});

                    if (snackbarResolved === 'action') {
                        return this.submitEmailSignin();
                    }
                }
            },
            async submitCodeConfirmation() {
                if (!this.user.code) {
                    return;
                }

                try {
                    uiStore.toggleWaiting();

                    const res = await api.confirmCode({
                        Authcode: this.user.code,
                        Email: this.user.email,
                    });

                    uiStore.toggleWaiting();

                    window.thesudokuapp.user = { ...res };

                    profileStore.updateEmailSigningInData();

                    this.user.name = '';
                    this.user.email = '';
                    this.user.code = '';

                    logger.trackEvent({category: 'profile', action: 'email confirmed'});

                    snackbarStore.showSnackbar({
                        primaryMessage: $t('stats.emailSigninOk[0]'),
                        secondaryMessage: $t('stats.emailSigninOk[1]'),
                        timeout: 3750
                    });

                    this.$emit('codeconfirmed');
                } catch (error) {
                    uiStore.toggleWaiting();

                    const snackbarResolved = await api.apiAsyncCatch({ error, source: 'emailconfirm' });

                    if (snackbarResolved === 'action') {
                        return this.submitCodeConfirmation();
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
    .thesudokuapp-form-v2 {
    font-size: 0.4rem;

    &-control {
        position: relative;
    }

    .signin-name {
        margin-top: 0.9rem;
        margin-bottom: 1.3rem;
    }

    .signin-email {
        margin-bottom: 0.2rem;
    }

    .confirm-code {
        margin-top: 1.3rem;
        margin-bottom: 1.4rem;
    }

    &-textinput {
        padding: 0 0 0.2rem;
        color: inherit;

        &:invalid {
            box-shadow: none;
        }

        &:not(:focus):not(:placeholder-shown):invalid {
            border-bottom: 1px solid var(--danger-color);
        }

        &:not(:focus):not(:placeholder-shown):invalid + label {
            color: var(--danger-color);
        }
    }

    &-label {
        //text-transform: uppercase;
        position: absolute;
        left: 0;
        top: 0.1rem;
        transition: transform 0.2s ease-in-out;
        cursor: text;
        font-weight: bold;
    }

    &-textinput {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #ccc;
        font: inherit;
        background-color: inherit;
        border-radius: 0; // fucking apple
    }

    &-textinput:focus + label, textarea:focus + label {
        transform: translateY(-0.7rem);
    }

    &-textinput:disabled + label {
        opacity: 0.7;
        cursor: default;
    }

    // we need placeholder=" " on input
    &-textinput:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label {
        transform: translateY(-0.7rem);
    }

    &-labellike {
        font-size: 0.28rem;
        text-align: center;
        margin-bottom: 1.4rem;
    }
}
</style>
