import { storageGet, storageGetGameState, storageSet } from '@/services/thesudokuapp-storage';
import { miscStore, snackbarStore, stopwatchStore, sudokuStore, uiStore } from '@/App/stores';
import { utils } from '@/services/thesudokuapp-utils';
import { $t } from '@/services/thesudokuapp-i18n';

const persistedGameState = await storageGetGameState();

const
    playedGrid = persistedGameState && (persistedGameState.persistedHints || persistedGameState.persistedSelectedValues),
    playedGridSolved = persistedGameState?.persistedSelectedValues ? (persistedGameState.persistedSelectedValues.match(/0/g).length + persistedGameState.grid.match(/0/g).length === 81) : false,
    unfinishedGrid = playedGrid && !playedGridSolved;

const articleDifficulty = window.thesudokuapp.articleDifficulty;
const locationHashID = (window.location.pathname.match(/^\/(easy|medium|hard|expert)-puzzle-([abcdefghjkmnprstuvwxyzABCDEFGHJKMNPRSTUVWXYZ23456789]{4,6})\/?$/) || [])[2];
const init = {
    fromPersistedGrid: false,
    fromWindowGrid: false,
    fromWindowGridsDifficulty: false,
    fromPersistedDifficulty: false,
    updateLocation: false,
    type: ''
};

if (!locationHashID) {
    await storageSet.addNewGrids(window.thesudokuapp.grid);
}

const hideLoader = () => {
    document.getElementById('thesudoku-loader').style.display = 'none';
};

const initBoardFromSavedGameState = () => {
    persistedGameState.startStopwatch = false;
    sudokuStore.initSudoku(persistedGameState);
    hideLoader();
};

const rootInit = async () => {
    if (unfinishedGrid) {
        init.fromPersistedGrid = true;
        init.type = 'init: root (unfinished grid from storage)';
    } else if (persistedGameState?.difficulty) {
        if (playedGridSolved) {
            initBoardFromSavedGameState();

            const
                solvedGrid = await storageGet.puzzleStats(persistedGameState?.hashid),
                date = solvedGrid ? solvedGrid.date || solvedGrid.recordtimestamp : Math.floor(Date.now() / 1000);

            const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                primaryMessage: $t('snackbar.initsolved.primaryv2', { hashId: persistedGameState?.hashid, date: new Date(date * 1000).toLocaleDateString([], { dateStyle: 'full' })}),
                secondaryMessage: $t('snackbar.initsolved.secondary'),
                actionCaption: $t('snackbar.initsolved.startnew'),
                secondaryActionCaption: $t('snackbar.initsolved.solveagain'),
                showDismiss: false
            });

            if (snackbarResolved === 'secondaryAction') {
                init.type = 'init: root (played grid solved: solve again)';

                sudokuStore.initSudoku({
                    hashid: persistedGameState.hashid,
                    difficulty: persistedGameState.difficulty,
                    grid: persistedGameState.grid,
                    solution: persistedGameState.solution
                });

                miscStore.initfinished = true;
            } else {
                init.fromPersistedDifficulty = true;
                init.type = 'init: root (played grid solved: start new)';
            }
        } else {
            init.fromPersistedDifficulty = true;
            init.type = 'init: root (difficulty from storage)';
        }
    } else {
        init.fromWindowGridsDifficulty = true;
        init.type = 'init: root';
    }
};

const initByLocationHashId = async () => {
    if (unfinishedGrid && (locationHashID === persistedGameState.hashid)) {
        init.fromPersistedGrid = true;
        init.type = 'init: locationHashId (unfinished grid from storage)';
    } else if (unfinishedGrid && (locationHashID !== persistedGameState.hashid)) {
        initBoardFromSavedGameState();

        const snackbarResolved = await snackbarStore.showAsyncSnackbar({
            primaryMessage: $t('snackbar.initunfinished.primary', { hashId: persistedGameState.hashid, difficulty: $t('difficulty.' + persistedGameState.difficulty) }),
            secondaryMessage: $t('snackbar.initunfinished.secondary'),
            actionCaption: $t('snackbar.initunfinished.startnew'),
            secondaryActionCaption: $t('snackbar.initunfinished.continue'),
            showDismiss: false
        });

        if (!snackbarResolved || (snackbarResolved === 'secondaryAction')) {
            init.type = 'init: locationHashId (unfinished hashid mismatch: continue)';
            init.updateLocation = true;
            stopwatchStore.startStopwatch(persistedGameState.stopwatch);
            miscStore.initfinished = true;
        } else {
            init.fromWindowGrid = true;
            init.type = 'init: locationHashId (unfinished hashid mismatch: start new)';
        }
    } else {
        const solvedGrid = await storageGet.puzzleStats(locationHashID);

        if (solvedGrid) {
            if (playedGridSolved) {
                initBoardFromSavedGameState();
            } else {
//http://sudoku.local:8080/hard-puzzle-kgbge7
                sudokuStore.initSudoku({
                    hashid: window.thesudokuapp.grid.HashID,
                    difficulty: window.thesudokuapp.grid.Difficulty,
                    grid: window.thesudokuapp.grid.Grid,
                    solution: window.thesudokuapp.grid.Solution,
                    persistedSelectedValues: [...window.thesudokuapp.grid.Grid].map((hintId, index) => hintId === '0' ? window.thesudokuapp.grid.Solution[index] : '0').join(''),
                    stopwatch: solvedGrid.time,
                    startStopwatch: false
                });

                hideLoader();
            }

            const snackbarResolved = await snackbarStore.showAsyncSnackbar({
                primaryMessage: $t(
                    playedGridSolved ? 'snackbar.initsolved.primaryv2' : 'snackbar.initsolved.primary',
                    {
                        hashId: locationHashID,
                        date: new Date((solvedGrid.date || solvedGrid.recordtimestamp)*1000).toLocaleDateString([], { dateStyle: 'full' })
                    }
                ),
                secondaryMessage: $t('snackbar.initsolved.secondary'),
                actionCaption: $t('snackbar.initsolved.startnew'),
                secondaryActionCaption: $t('snackbar.initsolved.solveagain')
            });

            if (snackbarResolved === 'secondaryAction') {
                init.type = 'init: locationHashID (already solved: solve again)';
                init.fromWindowGrid = true;
            } else {
                init.fromPersistedDifficulty = true;
                init.type = 'init: locationHashID (already solved: start new)';
                init.updateLocation = true;
            }
        } else {
            init.fromWindowGrid = true;
            init.type = 'init: locationHashID';
        }
    }
};
const initByArticleDifficulty = async () => {
    if (window.thesudokuapp.tutorialHashId) {
        miscStore.newSudokuHashId = window.thesudokuapp.tutorialHashId;
        uiStore.switchUIState({ type: 'findpuzzlebyidmenu', newState: true });
        init.type = 'init: article (grid from tutorial)';
        miscStore.initfinished = true;
    } else if (unfinishedGrid && (articleDifficulty === persistedGameState.difficulty)) {
        init.fromPersistedGrid = true;
        init.type = 'init: article (unfinished grid from storage)';
        init.updateLocation = true;
    } else if (unfinishedGrid && (articleDifficulty !== persistedGameState.difficulty)) {
        initBoardFromSavedGameState();

        const snackbarResolved = await snackbarStore.showAsyncSnackbar({
            primaryMessage: $t('snackbar.initunfinished.primary', { hashId: persistedGameState.hashid, difficulty: $t('difficulty.' + persistedGameState.difficulty) }),
            secondaryMessage: $t('snackbar.initunfinished.secondaryv2', { newdifficulty: $t('difficulty.' + articleDifficulty) }),
            actionCaption: $t('snackbar.initunfinished.startnew'),
            secondaryActionCaption: $t('snackbar.initunfinished.continue'),
        });

        if (snackbarResolved === 'secondaryAction') {
            miscStore.initfinished = true;
            init.type = 'init: article (unfinished grid from storage: continue)';
            init.updateLocation = true;
            stopwatchStore.startStopwatch(persistedGameState.stopwatch);
        } else {
            init.fromWindowGridsDifficulty = true;
            init.type = 'init: article (unfinished grid from storage: start new)';
        }
    } else {
        init.fromWindowGridsDifficulty = true;
        init.type = 'init: article';
    }
};

const sudokuBoot = async () => {
    if (locationHashID) {
        await initByLocationHashId();
    } else if (window.thesudokuapp.vroute) {
        if (unfinishedGrid) {
            init.fromPersistedGrid = true;
            init.type = 'init: vroute (unfinished grid from storage)';
        } else {
            init.fromWindowGridsDifficulty = true;
            init.type = 'init: vroute';
        }
    } else if (articleDifficulty) {
        await initByArticleDifficulty();
    } else {
        await rootInit();
    }

    if (!miscStore.initfinished) {
        if (init.fromPersistedGrid) {
            sudokuStore.initSudoku(persistedGameState);
        } else if (init.fromWindowGrid) {
            void storageSet.removeGrid({
                hashid: window.thesudokuapp.grid.HashID,
                difficulty: window.thesudokuapp.grid.Difficulty
            });
            sudokuStore.initSudoku({
                hashid: window.thesudokuapp.grid.HashID,
                difficulty: window.thesudokuapp.grid.Difficulty,
                grid: window.thesudokuapp.grid.Grid,
                solution: window.thesudokuapp.grid.Solution
            });
        } else if (init.fromPersistedDifficulty || init.fromWindowGridsDifficulty) {
            const
                difficulty = init.fromWindowGridsDifficulty ? window.thesudokuapp.grid[0]['Difficulty'] : persistedGameState.difficulty,
                cachedGrid = await storageGet.randomGrid(difficulty);

            if (cachedGrid) {
                init.type += ' (storage cache)';
                sudokuStore.initSudoku(cachedGrid);
            } else {
                init.type += ' (xqr)';
                miscStore.newSudokuDifficulty = difficulty;
                uiStore.switchUIState({ type: 'newgamemenu', newState: true });
            }
        }
        miscStore.initfinished = true;
        hideLoader(); // hashid + solve again -> called twice and похер
    }

    if (init.updateLocation) {
        utils.updateLocationAndTitle({
            gridid: sudokuStore.grid.hashid,
            difficulty: sudokuStore.grid.difficulty
        });
    }

    return init.type;
};

export { sudokuBoot };
